import React, { FC } from 'react';
import styled from 'styled-components/macro';

const ListItemIndicator: FC<{
  me?: boolean;
  color: string;
}> = ({ me, color }) => {
  return (
    <ListItemIndicatorComponent
      style={{
        backgroundColor: color,
      }}
      className={me ? ' myItem' : ''}
    >
      {' '}
    </ListItemIndicatorComponent>
  );
};

const ListItemIndicatorComponent = styled.div`
  width: 6px;
  height: 6px;
  transform: rotate(-45deg);
  border-radius: 1px;
  z-index: 2;
  margin-left: 3px;
  &.myItem {
    position: absolute !important;
  }
`;

export default ListItemIndicator;
