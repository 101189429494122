import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import EntityList, { ListState } from './EntityList';

export type EntityListFC<Entity> = StyledFC<{
  list: Entity[];
  onScrollEnd?: () => void;
}>;
const createEntityList: <Entity>(
  ListItem: StyledFC<{
    listState: ListState;
    entity: Entity;
    index: number;
  }>
) => EntityListFC<Entity> = (ListItem) => {
  // TODO: Avoid syntax conflict: TS Generics vs JSX
  // @ts-ignore
  const List = ({ className, list, onScrollEnd }) => (
    <EntityList className={className} onScrollEnd={onScrollEnd}>
      {(listState) =>
        // @ts-ignore
        list.map((item, index) => (
          <ListItem
            key={index}
            listState={listState}
            entity={item}
            index={index}
          />
        ))
      }
    </EntityList>
  );

  return styled(List)`
    display: flex;
    > * {
      margin-bottom: 10px;
    }
  `;
};

export default createEntityList;
