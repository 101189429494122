import React, { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledFC } from '../../_shared/types';
import createEntityPage from '../_shared/page/createEntityPage';
import { getUser, updateUserPermissions } from '../../../_api/controller';
import { RoleName, User } from '../../../_types';
import { ReFetch } from '../../_shared/Api';
import styled from 'styled-components/macro';
import createUpdateForm from '../_shared/form/createUpdateForm';
import { isAdmin } from '../../auth/_actions';
import { FileExist } from '../_shared/files';
import EmptyAva from '../../../_sass/img/empty_ava.png';
import PageEdit from '../_shared/page/PageEdit';
import { fileUrl } from '../../../_api/controller/file';
import Checkbox from '../_shared/form/Checkbox';
import createRadio from '../_shared/form/Radio';
import { useMe } from '../../_shared';

/** Apply component factory statically (out of render scope) */
const UpdateForm = createUpdateForm(updateUserPermissions);

const RadioRoleName = createRadio<RoleName>();

const EditForm_: StyledFC<{
  entity: User;
  reFetchList: ReFetch;
  children: {};
}> = ({ className, entity, reFetchList }) => {
  const me = useMe();
  const isSelf = me.id === entity.id;
  const [blocked, setBlocked] = useState(entity.isBlocked);
  const [admin, setAdmin] = useState(isAdmin(entity));
  const [roleName, setRoleName] = useState<RoleName>(
    entity.roles.map((r) =>
      r.name === RoleName.ROLE_SUPER_ADMIN ? RoleName.ROLE_ADMIN : r.name
    )[0] || RoleName.ROLE_USER
  );
  useEffect(() => {
    console.log({ admin, roleName });
    if (admin && roleName === RoleName.ROLE_USER) {
      setRoleName(RoleName.ROLE_MANAGER);
    }
  }, [admin, roleName]);

  const isChanged = useCallback(
    () => () =>
      entity &&
      blocked !== entity.isBlocked &&
      String(entity.roles[0].name) !== roleName,
    [entity, blocked, roleName]
  );
  const validate = useCallback(
    () =>
      !isSelf && isChanged() && blocked != null
        ? {
            isBlocked: blocked,
            roleName: admin ? roleName : RoleName.ROLE_USER,
          }
        : undefined,
    [isSelf, isChanged, blocked, admin, roleName]
  );
  return (
    <PageEdit className={className} entityTypeName="пользователь">
      <UpdateForm
        className={className}
        entity={entity}
        validate={validate}
        reFetchList={reFetchList}
      >
        <FileExist src={fileUrl(entity.avatar) || EmptyAva} />
        <div className="user-name">{entity.name}</div>
        <div className="user-email">{entity.email}</div>
        <div className="user-points">{entity.points} очков</div>
        <div className="user-permissions">
          <Checkbox
            label="Заблокированный"
            id="status"
            state={blocked}
            setState={setBlocked}
            disabled={isSelf}
          />
          <Checkbox
            label="Администратор"
            id="role"
            state={admin}
            setState={setAdmin}
            disabled={isSelf}
          />
          <div className="admin-roles">
            <RadioRoleName
              label="Полные права"
              id={RoleName.ROLE_ADMIN}
              group="role"
              state={roleName}
              setState={setRoleName}
              disabled={!admin || isSelf}
            />
            <RadioRoleName
              label="Ограниченные права"
              id={RoleName.ROLE_MANAGER}
              group="role"
              state={roleName}
              setState={setRoleName}
              disabled={!admin || isSelf}
            />
          </div>
        </div>
      </UpdateForm>
    </PageEdit>
  );
};

const EditForm = styled(EditForm_)`
  > .scroll-container > .scroll {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
    grid-template-rows: 80px 24px 24px 24px auto 72px;
    grid-gap: 8px;
    align-items: start;

    font-family: Geometria, sans-serif;
    font-size: 14px;
    > .user-name {
      font-weight: bold;
    }
    > .user-permissions {
      margin: 16px 0;
      > * {
        margin-bottom: 8px;
      }
      > .admin-roles {
        margin: 0 24px;
      }
    }
  }
`;

/** Apply component factory statically (out of render scope) */
const EditWoChildren = createEntityPage(getUser, 'пользователь', EditForm);

const Edit: StyledFC<{
  reFetchList: ReFetch;
}> = (props) => <EditWoChildren {...props}>{}</EditWoChildren>;

export default Edit;
