import API from '../../../_api';
import { CancelToken } from 'axios';
import store from '../../../store';
import { authActions } from '../_actions';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const refreshAuthLogic = (failedRequest: any) => {
  const { refreshToken } = store.getState().auth;
  if (refreshToken) {
    return authActions.refreshToken(refreshToken)(store.dispatch);
  } else {
    return Promise.reject(failedRequest);
  }
};

API.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.accessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(API, refreshAuthLogic, {
  skipWhileRefreshing: false,
});

const signOut = (refreshToken: string) => {
  return API.post(`auth/logout`, { refreshToken });
};

const refreshToken = (refreshToken: string) => {
  return API.post(`auth/refresh-token`, { refreshToken });
};

const signIn = (email: string, password: string, token: CancelToken) => {
  return API.post(`auth/signin`, { email, password }, { cancelToken: token });
};

const signUp = (email: string, token: CancelToken) => {
  console.log('auth/signUp', email, token);
  return API.post(
    `auth/signup`,
    { email, isTermsAccepted: true },
    { cancelToken: token }
  );
};

const restore = (email: string, token: CancelToken) => {
  return API.post(
    `users/recover-password?email=${email}`,
    { email },
    { cancelToken: token }
  );
};

export default {
  signOut,
  refreshToken,
  signIn,
  signUp,
  restore,
};
