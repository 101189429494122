import { StyledFC } from '../_shared/types';
import styled from 'styled-components/macro';
import React from 'react';

const FooterContent_: StyledFC<{ screenID: number; onNext: () => void }> = ({
  className,
  screenID,
  onNext,
}) => (
  <div className={className}>
    <span className={['first', 'second', 'third'][screenID]}>•</span>
    <button onClick={onNext}>{screenID < 2 ? 'Далее' : 'Готово'}</button>
  </div>
);

const FooterContent = styled(FooterContent_)`
  span {
    display: flex;
    align-items: center;
    position: relative;
    font-size: xx-large;
    text-align: left;
    flex-grow: 1;
    color: #4f4f4f; // TODO: Use theme

    &::before {
      content: '•';
    }
    &::after {
      content: '•';
    }

    &.first {
      &::before {
        color: #f2994a; // TODO: Use theme
      }
    }
    &.second {
      &::before,
      &::after {
        color: #4f4f4f; // TODO: Use theme
      }
      color: #f2994a; // TODO: Use theme
    }
    &.third {
      &::after {
        color: #f2994a; // TODO: Use theme
      }
    }
  }

  button {
    display: inline;
    font-family: Geometria-Bold, sans-serif;
    font-size: 16px;
    line-height: 19.2px;
    white-space: nowrap;
    padding: 10px;
    min-width: 104px;
    cursor: pointer;
    color: #f2994a;
    background: none;
    border: 2px solid #f2994a; // TODO: Use theme
    border-radius: 12px;

    &[disabled] {
      color: #4f4f4f; // TODO: Use theme
      border-color: #4f4f4f; // TODO: Use theme
      pointer-events: none;
    }

    &:focus {
      outline: 0;
    }
  }
`;

export default FooterContent;
