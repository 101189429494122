import React, { FC } from 'react';
import './style.scss';
import './responsive.scss';
import '../../UserInfo/style.scss';
import { Achievement } from '../../../../_types';
import { getBadge } from '../util';

const BadgeItem: FC<{ achievement: Achievement }> = ({ achievement }) => {
  return (
    <div className="badge">
      <img src={getBadge(achievement)} alt={achievement.image} />
    </div>
  );
};
export default BadgeItem;
