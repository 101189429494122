import React from 'react';
import styled from 'styled-components/macro';
import BackHeader from './_shared/BackHeader';
import Api, { useApiWoParams } from '../_shared/Api';
import { getCommonRating, getTeamRating } from '../../_api/controller';
import ratingIcon from '../../_sass/icon/icon-cupx4.png';
import { getLevels } from '../../_api/controller';
import RatingList from './RatingList';
import { StyledFC } from '../_shared/types';
import Tabs from '../_shared/Tabs';
import MainBackground from '../../_sass/img/bg_default@3x.png';

const Rating_: StyledFC = ({ className }) => {
  const levelsState = useApiWoParams(getLevels);
  return (
    <div className={className}>
      <div className="mainScreen">
        <div className="wrapper">
          <div className="Backheader">
            <BackHeader />
          </div>
          <img className="headerIcon" src={ratingIcon} alt="rating icon" />

          <Api
            result={levelsState}
            renderData={(levels) => {
              const levelImages = levels.map((level) => level.image);
              const levelBoundaries = levels.map((level) => level.pointsCount);
              return (
                <Tabs default="common">
                  {{
                    common: {
                      title: 'Общий рейтинг',
                      render: (show) => (
                        <RatingList
                          key="common"
                          show={show}
                          api={getCommonRating}
                          levelImages={levelImages}
                          levelBoundaries={levelBoundaries}
                        />
                      ),
                    },
                    team: {
                      title: 'Моя команда',
                      render: (show) => (
                        <RatingList
                          show={show}
                          key="team"
                          api={getTeamRating}
                          levelImages={levelImages}
                          levelBoundaries={levelBoundaries}
                        />
                      ),
                    },
                  }}
                </Tabs>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Rating = styled(Rating_)`
  * {
    box-sizing: border-box;
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url(${MainBackground});

  .mainScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 360px;
    overflow: hidden;
    width: calc(100% - 16px);
    max-height: 737px;
    height: 90%;
    margin: 8px;
    background-color: white;
    border-radius: 24px;

    .wrapper {
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 0 8px;
    }

    .Backheader {
      min-height: 28px;
    }

    .tabs {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      min-height: 32px;
      width: 272px;
      font-family: 'Geometria', sans-serif;
      font-size: 12px;
    }

    .headerIcon {
      height: 128px;
      min-height: 128px;
    }
  }
`;

export default Rating;
