import React, { Dispatch } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import { FormInputString } from '../_shared/form/FormInput';

const StepsEditor_: StyledFC<{
  steps: string[];
  setSteps: Dispatch<string[]>;
  isAddRemoveEnabled: boolean;
}> = ({ className, steps, setSteps, isAddRemoveEnabled }) => {
  return (
    <div className={className}>
      <h2>Шаги для сценария</h2>
      <div className="inputs">
        {steps.map((description, index) => (
          <FormInputString
            key={index}
            id={'new-step-' + index}
            label={`${index + 1} шаг`}
            state={description}
            setState={(description) => {
              setSteps([
                ...steps.slice(0, index),
                description,
                ...steps.slice(index + 1),
              ]);
            }}
            onBlur={() => {
              if (isAddRemoveEnabled && description.trim().length === 0) {
                setSteps(steps.filter((_, i) => i !== index));
              }
            }}
          />
        ))}
      </div>
      <button
        className="add-step"
        onClick={() => {
          setSteps([...steps, '']);
        }}
        disabled={!isAddRemoveEnabled}
      >
        <div>+</div>
      </button>
    </div>
  );
};

const StepsEditor = styled(StepsEditor_)`
  display: grid;
  grid-auto-rows: 1.5em auto 70px;
  grid-gap: 1em;
  > h2 {
    font-family: Geometria, sans-serif;
    font-size: 14px;
  }
  > .inputs {
    display: grid;
    margin: 0;
    grid-template-columns: 1fr;
    grid-auto-rows: 50px;
    align-self: stretch;
    justify-self: stretch;
    align-items: stretch;
    justify-items: stretch;
  }
  > .add-step {
    text-align: center;
    vertical-align: middle;
    align-self: center;
    justify-self: stretch;
    font-size: 32px;
    background-color: white;
    color: ${({ theme }) => theme.colors.greySpan};
    border: solid ${({ theme }) => theme.colors.greySpan} 2px;
    border-radius: ${({ theme }) => theme.borderRadius}px;
    &:disabled {
      color: ${({ theme }) => theme.colors.lightGrey};
      border-color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export default StepsEditor;
