import React from 'react';
import { useMe } from '../../_shared';

import Image from '../_shared/congrats-images/Team Selection@2x.png';
import CircleImage from '../_shared/congrats-images/background-teaming.png';
import CongratsScreen from '../_shared/congrats-screen';

const Teaming = () => {
  const me = useMe();
  const message =
    me.team.id === 1 ? (
      <>
        Хм... Трудно. Очень трудно. Вижу, есть отвага. А также неплохой ум.
        Талант и жажда проявить себя. Так куда же тебя определить?
        <br />
        Пусть будет команда Добрейшины!
      </>
    ) : (
      <>
        Ничто в этой жизни не происходит случайно, мой друг! Я достаточно мудра
        и наблюдательна, чтобы сказать:
        <br />
        Твое место в команде Щедрейшины!
      </>
    );

  const data = {
    image: Image,
    circleImage: CircleImage,
    header: `Ты в команде ${me.team.name}!`,
    message: message,
    action: 'Огонь!',
  };

  return <CongratsScreen {...data} />;
};

export default Teaming;
