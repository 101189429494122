import { get } from './rest';
import { Int } from '../_types';
import { CancelToken } from 'axios';

export const getList = <Item>(path: string, map?: (list: Item[]) => Item[]) =>
  get<void, Item[]>(path, map)(undefined);

export const sortLevels = <T extends { pointsCount: number }>(list: T[]): T[] =>
  list.sort(compareLevels);
export const compareLevels = <T extends { pointsCount: number }>(a: T, b: T) =>
  a.pointsCount - b.pointsCount;

export type PageArg = {
  page?: Int;
  size?: Int;
  sort?: string[];
};
export type Page<T> = {
  items: T[];
  totalItems: Int;
  totalPages: Int;
  page: Int;
};
export type GetPage<Item> = (
  arg: PageArg
) => (token?: CancelToken) => Promise<Page<Item>>;
export const getPage = <Item>(path: string): GetPage<Item> => (
  arg: PageArg
) => (token?: CancelToken) =>
  get<PageArg | undefined, Page<Item>>(path)(arg)(token).then((res) => {
    return {
      items: res.items,
      totalItems: res.totalItems,
      totalPages: res.totalPages,
      page: arg.page || 0,
    };
  });

export type SearchPageArg = PageArg & { search?: string };
export type SearchPage<T> = Page<T> & {
  foundItems: Int;
};
export const getSearchPage = <Item>(path: string) => (arg: SearchPageArg) => (
  token?: CancelToken
) =>
  get<SearchPageArg | undefined, SearchPage<Item>>(path)(arg)(
    token
  ).then((res) => ({ ...res, page: arg.page }));
