import React, { FC } from 'react';
import './style.scss';
import QaIcon from '../../../_shared/QaIcon';

const BackHeader: FC<{ onClickUpdateName: () => void }> = ({
  onClickUpdateName,
}) => {
  return (
    <button className="back-button-edit" onClick={onClickUpdateName}>
      <QaIcon name="back" />
    </button>
  );
};

export default BackHeader;
