import React, {
  ChangeEventHandler,
  Dispatch,
  FC,
  FocusEventHandler,
} from 'react';
import { StyledFC } from '../../../_shared/types';
import styled from 'styled-components/macro';
import DatePicker from 'react-datepicker';
import QaIcon from '../../../_shared/QaIcon';
import detect from 'browser-detect';

const FormInput_: StyledFC<{
  label: string;
  id: string;
  type: string;
  placeHolder?: string;
  required?: boolean;
  minLength?: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  value: string | number | string[] | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}> = ({
  className,
  label,
  id,
  type,
  placeHolder,
  required,
  minLength,
  min,
  max,
  step,
  disabled,
  value,
  onChange,
  onBlur,
}) => (
  <div className={className}>
    <label htmlFor={id} className={disabled ? 'disabled' : ''}>
      {label}
    </label>
    <br />
    <input
      style={
        {
          // width: '-webkit-fill-available',
        }
      }
      id={id}
      type={type}
      placeholder={placeHolder}
      required={required}
      minLength={minLength}
      min={min}
      max={max}
      step={step}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  </div>
);

const FormInput = styled(FormInput_)`
  //display: compact;
  //align-self: stretch;
  //position: absolute;
  display: block;
  > label {
    position: relative;
    top: 1.5em;
    background: white;
    left: 1em;
    padding: 0 0.5em;
    color: ${({ theme }) => theme.colors.grey};
    z-index: 1;
  }
  > input {
    //@media screen and (min-width: 320px) {
    //  width: 200px;
    //}
    //
    //@media screen and (min-width: 375px) {
    //  width: 350px;
    //}
    //width: available;
    //position: fixed;
    //max-width: 270px;
    width: calc(100% - 48px);
    padding: 0 1em;
    color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.grey};
    &:focus {
      color: ${({ theme }) => theme.colors.yellow};
      border-color: ${({ theme }) => theme.colors.greySpan};
    }
    &:disabled {
      /** iOS */
      @supports (-webkit-touch-callout: none) {
        color: ${({ theme }) => theme.colors.blueGrey};
      }
      /** MacOS Safari  */
      @supports not (-webkit-touch-callout: none) {
        color: ${({ theme }) => theme.colors.grey};
      }
      /** Chrome */
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        color: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
`;

export default FormInput;

export const onChangeString = (
  setState: Dispatch<string>
): ChangeEventHandler<HTMLInputElement> => (e) => {
  setState(e.target.value);
};
export const FormInputString: FC<{
  id: string;
  label: string;
  state: string | undefined;
  setState: Dispatch<string>;
  type?: 'text' | 'email';
  disabled?: boolean;
  placeHolder?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}> = ({
  state,
  setState,
  id,
  label,
  type = 'text',
  disabled,
  placeHolder,
  onBlur,
}) => (
  <FormInput
    id={id}
    label={label}
    type={type}
    placeHolder={placeHolder}
    required
    disabled={disabled}
    value={state}
    onChange={onChangeString(setState)}
    onBlur={onBlur}
  />
);

export const onChangeNumber = (
  setState: Dispatch<number>
): ChangeEventHandler<HTMLInputElement> => (e) => {
  const parsed = parseInt(e.target.value);
  if (parsed != null && !isNaN(parsed)) setState(parsed);
};
export const FormInputNumber: FC<{
  id: string;
  label: string;
  state: number | undefined;
  setState: Dispatch<number>;
  disabled?: boolean;
  placeHolder?: string;
}> = ({ state, setState, id, label, disabled, placeHolder }) => (
  <FormInput
    id={id}
    label={label}
    type="number"
    placeHolder={placeHolder}
    required
    disabled={disabled}
    value={state}
    onChange={onChangeNumber(setState)}
  />
);

export const FormInputName: FC<{
  state: string | undefined;
  setState: Dispatch<string>;
  disabled?: boolean;
}> = ({ state, setState, disabled }) => (
  <FormInputString
    label={`Название`}
    id="name"
    state={state}
    setState={setState}
    disabled={disabled}
  />
);

export const FormInputEmail: FC<{
  state: string | undefined;
  setState: Dispatch<string>;
  disabled?: boolean;
  placeHolder?: string;
}> = ({ state, setState, disabled, placeHolder }) => (
  <FormInputString
    id="email"
    label={`Email`}
    type="email"
    placeHolder={placeHolder || 'Укажите email'}
    state={state}
    setState={setState}
    disabled={disabled}
  />
);

export const FormInputPointsCount: FC<{
  id?: string;
  label?: string;
  state: number | undefined;
  setState: Dispatch<number>;
  disabled?: boolean;
}> = ({ state, setState, id, label, disabled }) => (
  <FormInputNumber
    label={label || 'Пыльца доброты'}
    id={id || 'pointsCount'}
    state={state}
    setState={setState}
    disabled={disabled}
  />
);

const FormInputStartDate_: StyledFC<{
  startDate: Date | undefined;
  endDate: Date | undefined;
  setStartDate: Dispatch<Date>;
  disabled?: boolean;
}> = ({ className, startDate, endDate, setStartDate, disabled }) => {
  const { os, mobile, name } = detect();
  const isMobileSafari = os === 'OS X' && mobile === true && name === 'safari';

  return isMobileSafari ? (
    <div className={className}>
      <QaIcon name={'calendar'} />
      <label
        htmlFor="id-start-date"
        className={`label ${disabled ? 'disabled' : ''}`}
      >
        С
      </label>
      <input
        id="id-start-date"
        className={`ios-datetime ${startDate ? '' : 'invalid'}`}
        type="datetime-local"
        max={endDate?.toISOString().slice(0, -1)}
        onChange={(e) => {
          if (e.target.value != null) setStartDate(new Date(e.target.value));
        }}
        value={startDate?.toISOString().slice(0, -1)}
        disabled={disabled}
      />
    </div>
  ) : (
    <div className={className}>
      <QaIcon name={'calendar'} />
      <DatePicker
        className={`date ${startDate ? '' : 'invalid'}`}
        dateFormat="с dd.MM.yyyy в hh:mm"
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        onChange={(date) => {
          if (date != null) setStartDate(date);
        }}
        disabled={disabled}
        placeholderText="с"
        showTimeSelect
      />
    </div>
  );
};

export const FormInputStartDate = styled(FormInputStartDate_)`
  position: relative;
  & > span {
    position: absolute;
    left: 0.2rem;
    top: 0;
    font-size: 1.3rem;
    line-height: 38px;
    margin: 0.5rem;
  }
  .label {
    position: absolute;
    top: 0em;
    background: white;
    left: 1em;
    padding: 0 0.5em;
    color: ${({ theme }) => theme.colors.grey};
    z-index: 1;
  }
  .ios-datetime {
    padding: 0 1em 0 calc(1em + 1.5rem);
    border: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
    width: calc(100% - 48px - 1.5rem);
    line-height: 22.4px;
    height: 36px;
    color: ${({ theme }) => theme.colors.greySpan};
    &.invalid {
      border: solid red 2px;
    }
    &:invalid {
      border: none;
    }
    &:disabled {
      /** iOS */
      @supports (-webkit-touch-callout: none) {
        color: ${({ theme }) => theme.colors.blueGrey};
      }
      /** MacOS Safari  */
      @supports not (-webkit-touch-callout: none) {
        color: ${({ theme }) => theme.colors.grey};
      }
      /** Chrome */
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        color: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
  & > div {
    width: 100%;
    > .react-datepicker-popper {
      .react-datepicker__month-container {
        width: calc(100% - 76px);
      }
      .react-datepicker__time-container {
        width: 75px;
        .react-datepicker__time-box {
          width: 100%;
          .react-datepicker__time-list {
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
    & > div {
      width: 100%;
      > .date {
        padding: 0 1em 0 calc(1em + 1.5rem);
        border: none;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        //width: -webkit-fill-available;
        //width: -moz-min-content;
        width: calc(100% - 48px - 1.5rem);
        color: ${({ theme }) => theme.colors.greySpan};
        &:disabled {
          /** iOS */
          @supports (-webkit-touch-callout: none) {
            color: ${({ theme }) => theme.colors.blueGrey};
          }
          /** MacOS Safari  */
          @supports not (-webkit-touch-callout: none) {
            color: ${({ theme }) => theme.colors.grey};
          }
          /** Chrome */
          @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
            color: ${({ theme }) => theme.colors.lightGrey};
          }
        }
      }
      > .date.invalid {
        border: solid red 2px;
      }
      > .date:before {
        content: 'c ';
      }
    }
  }
`;

const FormInputEndDate_: StyledFC<{
  startDate: Date | undefined;
  endDate: Date | undefined;
  setEndDate: Dispatch<Date>;
  disabled?: boolean;
}> = ({ className, startDate, endDate, setEndDate, disabled }) => {
  const { os, mobile, name } = detect();
  const isMobileSafari = os === 'OS X' && mobile === true && name === 'safari';

  return isMobileSafari ? (
    <div className={className}>
      <QaIcon name={'calendar'} />
      <label
        htmlFor="id-end-date"
        className={`label ${disabled ? 'disabled' : ''}`}
      >
        До
      </label>
      <input
        id="id-end-date"
        className={`ios-datetime ${endDate ? '' : 'invalid'}`}
        type="datetime-local"
        min={endDate?.toISOString().slice(0, -1)}
        onChange={(e) => {
          if (e.target.value != null) setEndDate(new Date(e.target.value));
        }}
        value={endDate?.toISOString().slice(0, -1)}
        disabled={disabled}
      />
    </div>
  ) : (
    <div className={className}>
      <QaIcon name={'calendar'} />
      <DatePicker
        className={`date ${endDate ? '' : 'invalid'}`}
        dateFormat="до dd.MM.yyyy в hh:mm"
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        onChange={(date) => {
          if (date != null) setEndDate(date);
        }}
        disabled={disabled}
        placeholderText="до"
        showTimeSelect
      />
    </div>
  );
};

export const FormInputEndDate = styled(FormInputEndDate_)`
  position: relative;
  & > span {
    position: absolute;
    left: 0.2rem;
    top: 0;
    font-size: 1.3rem;
    line-height: 38px;
    margin: 0.5rem;
  }
  .label {
    position: absolute;
    top: 0em;
    background: white;
    left: 1em;
    padding: 0 0.5em;
    color: ${({ theme }) => theme.colors.grey};
    z-index: 1;
  }
  .ios-datetime {
    padding: 0 1em 0 calc(1em + 1.5rem);
    border: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
    width: calc(100% - 48px - 1.5rem);
    line-height: 22.4px;
    height: 36px;
    color: ${({ theme }) => theme.colors.greySpan};
    &.invalid {
      border: solid red 2px;
    }
    &:invalid {
      border: none;
    }
    &:disabled {
      /** iOS */
      @supports (-webkit-touch-callout: none) {
        color: ${({ theme }) => theme.colors.blueGrey};
      }
      /** MacOS Safari  */
      @supports not (-webkit-touch-callout: none) {
        color: ${({ theme }) => theme.colors.grey};
      }
      /** Chrome */
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        color: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
  & > div {
    width: 100%;
    > .react-datepicker-popper {
      .react-datepicker__month-container {
        width: calc(100% - 76px);
      }
      .react-datepicker__time-container {
        width: 75px;
        .react-datepicker__time-box {
          width: 100%;
          .react-datepicker__time-list {
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
    & > div {
      width: 100%;
      > .date {
        padding: 0 1em 0 calc(1em + 1.5rem);
        border: none;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        //width: -webkit-fill-available;
        //width: -moz-min-content;
        width: calc(100% - 48px - 1.5rem);
        color: ${({ theme }) => theme.colors.greySpan};
        &:disabled {
          /** iOS */
          @supports (-webkit-touch-callout: none) {
            color: ${({ theme }) => theme.colors.blueGrey};
          }
          /** MacOS Safari  */
          @supports not (-webkit-touch-callout: none) {
            color: ${({ theme }) => theme.colors.grey};
          }
          /** Chrome */
          @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
            color: ${({ theme }) => theme.colors.lightGrey};
          }
        }
      }
      > .date.invalid {
        border: solid red 2px;
      }
      > .date:before {
        content: 'до ';
      }
    }
  }
`;

// const StartDateInput = (props: { value: string, onClick: MouseEventHandler<HTMLButtonElement> }) => (
//   <button className="example-custom-input" onClick={onClick}>
//     с {value}
//   </button>
// );
//
// const StartDateInput: StyledFC<{
//   value?: Date;
//   onClick?: MouseEventHandler<HTMLButtonElement>;
// }> = ({ value, onClick }) => (
//   <button className="example-custom-input" onClick={onClick}>
//     с {value}
//   </button>
// );
