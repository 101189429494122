import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer from './components/auth/_reducers';
import { localStorageMiddleware } from './components/auth/_middleware';

const store = createStore(
  reducer,
  applyMiddleware(thunk, localStorageMiddleware, logger)
);
export default store;
