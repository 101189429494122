import React from 'react';
import styled from 'styled-components/macro';
import { getCurrentSeason } from '../../../../_api';
import Edit from './Edit';
import createEntityList from '../../_shared/createEntityList';
import { SeasonGoalLevel } from '../../../../_types';
import { Api, useApiWoParams } from '../../../_shared/Api';
import { RouteEdit } from '../../_shared/routes';
import ListItem from './ListItem';
import TabContent, { StyledTab } from '../../../_shared/Tabs/TabContent';

// const Delete: FC<{ reFetch?: ReFetch }> = ({ reFetch }) => (
//   <DeleteDialog
//     entityTypeName="уровень"
//     deleteApi={deleteSeasonGoalLevel}
//     reFetch={reFetch}
//   />
// );

/** Apply component factory statically (out of render scope) */
const BagItemList = createEntityList<SeasonGoalLevel>(ListItem);

const Bag_: StyledTab = ({ className, show }) => {
  const state = useApiWoParams(getCurrentSeason);
  return (
    <TabContent className={className} show={show}>
      <Api
        result={state}
        renderData={(season, { reFetch }) =>
          season ? (
            <>
              <BagItemList list={season.seasonGoalLevels} />
              {show && (
                <RouteEdit>
                  {() => (
                    <Edit reFetchList={reFetch}>{{ seasonId: season.id }}</Edit>
                  )}
                </RouteEdit>
              )}
            </>
          ) : (
            <div className="no-current-season">Текущий сезон не установлен</div>
          )
        }
      />
    </TabContent>
  );
};

const Bag = styled(Bag_)`
  display: grid;
  grid-template-rows: auto;
  align-self: center;
  height: 100%;
  margin: 0;
  &:first-child {
    align-self: center;
  }
  > * {
    margin: 0 24px;
  }
  > .no-current-season {
    padding: 3em 1em;
    text-align: center;
    font-family: Geometria, sans-serif;
    color: ${({ theme }) => theme.colors.greySpan};
  }
`;

export default Bag;
