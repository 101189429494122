import React, { useEffect, useState } from 'react';
import { getUsers } from '../../../_api';
import styled from 'styled-components/macro';
import { Api, useApi } from '../../_shared/Api';
import ListItem from './ListItem';

import { FooterNew } from '../_shared/FooterAction';
import createEntityList from '../_shared/createEntityList';
import { User } from '../../../_types';
import New from './New';
import { RouteEdit, RouteNew } from '../_shared/routes';
import Edit from './Edit';
import TabContent, { StyledTab } from '../../_shared/Tabs/TabContent';

/** Apply component factory statically (out of render scope) */
const UserList = createEntityList<User>(ListItem);

const Users_: StyledTab = ({ className, show }) => {
  // const [preSearch, setPreSearch] = useState('');
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const state = useApi(getUsers, { search, page, size: 15 });
  const items = state.data?.items;
  const pageLoaded = state.data?.page;
  // console.log({ page, pageLoaded, items });
  const [accList, setAccList] = useState<User[]>([]);
  useEffect(() => {
    // console.log('useEffect', pageLoaded, items, setAccList, accList);
    if (items !== undefined && pageLoaded !== undefined) {
      if (pageLoaded === 0) setAccList(items);
      if (pageLoaded > 0) setAccList((accList) => [...accList, ...items]);
    }
  }, [pageLoaded, items, setAccList]);

  return (
    <TabContent className={className} show={show}>
      <input
        className="user-search"
        style={{
          width: '-webkit-fill-available',
        }}
        placeholder={'Поиск'}
        defaultValue={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setPage(0);
        }}
      />
      <Api
        result={state}
        renderData={(data, { reFetch }) => (
          <>
            <UserList
              className="user-list"
              list={accList}
              onScrollEnd={() => {
                // console.log('onScrollEnd');
                if (page < data.totalPages - 1) {
                  setPage(page + 1);
                }
              }}
            />
            {show && (
              <>
                <FooterNew />
                <RouteEdit>{() => <Edit reFetchList={reFetch} />}</RouteEdit>
                <RouteNew>{() => <New reFetchList={reFetch} />}</RouteNew>
              </>
            )}
          </>
        )}
      />
    </TabContent>
  );
};

const Users = styled(Users_)`
  display: grid;
  grid-template-rows: 60px 1fr 72px 0;
  align-self: center;
  height: 100%;
  .user-list {
    .visible {
      > div {
        width: 38px;
        height: 38px;
      }
      right: 15px !important;
    }
  }
  > .user-search {
    align-self: center;
    margin: 0 24px;
    padding: 0 1em;
    max-height: 2.5em;
    font-family: Geometria, sans-serif;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.lightGrey};
    border-width: 1px;
    &:focus {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
  > * {
    margin: 0 24px;
  }
`;

export default Users;
