import React, { useState, Dispatch, ReactNode, useCallback } from 'react';
import { Id } from '../../../_types';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';

export type ListState = {
  selectedId: Id | undefined;
  setSelectedId: Dispatch<Id | undefined>;
};

export type ListItemState = {
  isSelected: boolean;
  setSelected: Dispatch<boolean>;
};

export const useListItem = (
  listState: ListState,
  itemId: Id
): ListItemState => {
  const isSelected = listState.selectedId === itemId;
  const setSelected = useCallback(
    (is: boolean) => {
      // listState.setSelectedId(is ? itemId : undefined);
      if (listState.selectedId === undefined) {
        /** (Un)select given element */
        listState.setSelectedId(is ? itemId : undefined);
      } else {
        /** Just unselect current element */
        listState.setSelectedId(undefined);
      }
    },
    [listState, itemId]
  );
  return { isSelected, setSelected };
};

const EntityList_: StyledFC<{
  children: (listState: ListState) => ReactNode;
  unselectOnMouseLeave?: boolean;
  onScrollEnd?: () => void;
}> = ({ className, children, unselectOnMouseLeave = true, onScrollEnd }) => {
  const [selectedId, setSelectedId] = useState<Id | undefined>();
  const listState: ListState = { selectedId, setSelectedId };
  return (
    <div
      className={className}
      onScroll={(e) => {
        e.preventDefault();
        const el = e.currentTarget;
        // console.log(
        //   `${el.scrollTop} + ${el.clientHeight} >= ${el.scrollHeight}`
        // );
        const isEnd =
          Math.ceil(el.scrollTop) + Math.ceil(el.clientHeight) >=
          Math.floor(el.scrollHeight);
        if (isEnd) {
          onScrollEnd?.();
        }
      }}
      onMouseLeave={() => {
        // console.log('out of list');
        if (unselectOnMouseLeave) listState.setSelectedId(undefined);
      }}
    >
      <div className="items">{children(listState)}</div>
      {/*{children(listState)}*/}
    </div>
  );
};

const EntityList = styled(EntityList_)<{ height?: string; minHeight?: string }>`
  display: flex;
  flex-direction: column;
  line-height: 16.8px;
  align-self: stretch;
  
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  > .items {
    position: relative;
    display: block;
    //background-color: #2471b1;
    max-height: 1vmin;
  }
  
  color: ${({ theme }) => theme.colors.grey}
  margin-top: 0;
  margin-right: 0 !important;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default EntityList;
