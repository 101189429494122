import React, { FC } from 'react';
import IconComment from './IconComment';
import styled from 'styled-components/macro';

const SendComment: FC<{
  commentClickHandler?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  isInsideFooter?: boolean;
  type?: string;
}> = ({ commentClickHandler, isInsideFooter }) => {
  return (
    <CommentComponent
      style={{
        marginTop: isInsideFooter ? 0 : 24,
      }}
    >
      {isInsideFooter === true ? (
        <>
          <span onClick={commentClickHandler}>Оставить комментарий</span>
          <span onClick={commentClickHandler}>
            {' '}
            <IconComment />
          </span>
        </>
      ) : (
        <>
          <span onClick={commentClickHandler}>
            {' '}
            <IconComment fill={'#27AE60'} />
          </span>
          <span onClick={commentClickHandler} style={{ color: '#27AE60' }}>
            {' '}
            Оставить комментарий
          </span>
        </>
      )}
    </CommentComponent>
  );
};

const CommentComponent = styled.div`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #bdbdbd;
  text-decoration: none;
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 8px;
  }
  button,
  span {
    cursor: pointer;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button:hover,
  button:active {
    background-color: #f2994a;
    color: #35323b !important;
  }
`;

export default SendComment;
