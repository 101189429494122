import React from 'react';
import { ListState } from '../_shared/EntityList';
import { ReFetch } from '../../_shared/Api';
import { Season } from '../../../_types';
import EditDeleteButtons from '../_shared/overlay-buttons/EditDeleteButtons';
import { StyledFC } from '../../_shared/types';
import styled from 'styled-components/macro';
import EntityListItem from '../_shared/EntityListItem';

const ListItem_: StyledFC<{
  listState: ListState;
  entity: Season;
  index: number;
  reFetch: ReFetch;
}> = ({ className, listState, entity }) => {
  return (
    <EntityListItem
      className={className}
      listState={listState}
      entityId={entity.id}
      renderButtons={(isSelected, setSelected, size) => (
        <EditDeleteButtons
          isVisible={isSelected}
          size={size}
          before={() => setSelected(false)}
          entityId={entity.id}
        />
      )}
    >
      <p className="header">{entity.name}</p>
      <p className="description">{entity.description}</p>
    </EntityListItem>
  );
};

const ListItem = styled(ListItem_)`
  position: relative;
  > .data {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    font-family: 'Geometria', sans-serif;
    > .header {
      font-weight: bold;
      font-size: 12px;
    }
    > .description {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export default ListItem;
