import React, { Dispatch, useEffect } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import { QuestType } from '../../../_types';
import Api, { useApiWoParams } from '../../_shared/Api';
import { getQuestTypes } from '../../../_api/controller';

const QuestTypeInput_: StyledFC<{
  state: QuestType | undefined;
  setState: Dispatch<QuestType>;
  disabled?: boolean;
}> = ({ className, state, setState, disabled }) => {
  const listState = useApiWoParams(getQuestTypes);
  const defaultState = listState.data?.[0];
  useEffect(() => {
    if (state === undefined && defaultState !== undefined)
      setState(defaultState);
  }, [state, defaultState, setState]);
  return (
    <div className={className}>
      <h2>Тип квеста</h2>
      <Api
        result={listState}
        renderData={(list) => (
          <>
            {list.map((questType, i) => (
              <div key={i}>
                <input
                  type="radio"
                  id={String(questType.id)}
                  name="questType"
                  defaultChecked={state && questType.id === state.id}
                  onChange={(e) => {
                    if (e.target.checked) setState(questType);
                  }}
                  disabled={disabled}
                />
                <label htmlFor={String(questType.id)}>{questType.name}</label>
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
};

const TypeInput = styled(QuestTypeInput_)`
  > h2 {
    font-family: Geometria, sans-serif;
    font-size: 14px;
  }
  > div > input[type='radio'] {
  }
`;

export default TypeInput;
