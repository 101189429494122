import React, {
  useRef,
  FC,
  FormEvent,
  MouseEvent,
  RefObject,
  ChangeEventHandler,
} from 'react';

import QaIcon from '../../../_shared/QaIcon';
import './style.scss';

const InputBar: FC<{
  // checkbox?: FC;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<any>;
  value?: string;
  sendAction: (
    event: FormEvent<HTMLFormElement> | MouseEvent,
    ref: RefObject<HTMLInputElement>
  ) => void;
}> = ({
  // checkbox,
  autoFocus = false,
  disabled = false,
  onChange,
  sendAction,
  value,
}) => {
  // const Checkbox = checkbox;
  const ref = useRef<HTMLInputElement>(null);
  return (
    <form className="input-bar" onSubmit={(e) => sendAction(e, ref)}>
      <input
        ref={ref}
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={onChange}
        value={value}
        autoCapitalize="none"
      />
      {/*{Checkbox && <Checkbox />}*/}
      <button type="submit" disabled={disabled}>
        <QaIcon name={'up'} />
      </button>
    </form>
  );
};

export default InputBar;
