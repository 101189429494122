import React from 'react';
import './style.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import QaIcon from '../../../_shared/QaIcon';

const BackHeader = () => {
  const match = useRouteMatch();
  const parentURL = match.path.split('/')[match.path.split('/').length - 2];
  return (
    <Link className="back-button" to={`/${parentURL}`}>
      <QaIcon name="back" />
    </Link>
  );
};

export default BackHeader;
