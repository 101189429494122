import React, { FC, ReactElement } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { Notification } from '../../../../_types';
import styled from 'styled-components/macro';

const LinkComponent = styled(Link)`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  & > * {
    margin: 0;
  }

  display: block;
  text-decoration: none;
`;

const RegularComponent = styled.div`
  .header {
    > * {
      position: relative;
      width: 100%;
    }

    font-family: 'Geometria-Bold';
    font-size: 12px;
    line-height: 16.8px;
    color: #333;
  }

  .brief {
    .content {
      align-items: flex-start;
      flex-grow: 1;
      font-size: 16px;
      line-height: 22.4px;
      max-height: calc(22.4px * 2);
      overflow: hidden;
    }

    .date {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      font-size: 12px;
      line-height: 16.8px;
      min-width: 5rem;
    }

    font-family: 'Geometria';
    display: flex;
    justify-content: space-between;
    color: #828282;
  }

  > * > * {
    margin: 0;
  }

  > *:not(:last-child) {
    margin-bottom: 8px;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  cursor: pointer;
`;

const PendingComponent = styled(RegularComponent)`
  .header {
    ::before {
      content: '•';
      position: absolute;
      left: -14px;
      font-size: 14px;
    }

    color: #f2994a;
  }
`;

const Wrap: FC<{
  pending?: boolean;
  children: ReactElement | ReactElement[];
}> = ({ pending, children }) =>
  pending ? (
    <PendingComponent>{children}</PendingComponent>
  ) : (
    <RegularComponent>{children}</RegularComponent>
  );

const ListItem = (data: Notification) => {
  const match = useRouteMatch();

  let header;
  let detailed;

  switch (data.eventType) {
    case 'START_NEW_QUEST':
      header = 'Начало нового квеста';
      detailed = 'Новый квест начался!';
      break;
    case 'RECEIVED_NEW_BADGE':
      header = 'Получен новый бейж';
      detailed = 'Вы получили новый бейдж! Поздравляем';
      break;
    case 'TRANSITION_NEW_LEVEL':
      header = 'Получен новый уровень';
      detailed = 'Поздравляем с получением нового уровня!';
      break;
    case 'CUSTOMER_APPROVED_BUG':
      header = 'Баг одобрен';
      detailed = 'Найденный баг одобрен!';
      break;
    case 'SET_SEASON_GOAL':
      header = 'Новая цель';
      detailed = 'Начался новый сезон!';
      break;
    case 'QUEST_COMPLETED_USER':
      header = 'Квест выполнен';
      detailed = 'Вы выполнили текущий квест. Поздравляем!';
      break;
    case 'QUEST_CONFIRMED_ADMIN':
      header = 'Квест подтвержден';
      detailed = 'Выполнение квеста подтверждено';
      break;
    case 'QUEST_REJECTED_ADMIN':
      header = 'Квест отклонен';
      detailed = 'Статус выполнения квеста: отклонен';
      break;
    case 'EXTRA_POINTS':
      header = 'Ежедневная награда';
      detailed = 'Получены очки за ежедневный вход!';
      break;

    default:
      break;
  }

  const { title, content, date } = {
    title: header,
    content: detailed,
    date: data.date,
  };

  return (
    <LinkComponent to={`${match.path}/${data.id}`}>
      <Wrap pending={!data.isReaded}>
        <div className="header">
          <p className="title">{title}</p>
        </div>
        <div className="brief">
          <p className="content">{content}</p>
          <p className="date">{date}</p>
        </div>
      </Wrap>
    </LinkComponent>
  );
};

export default ListItem;
