import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState, AuthState } from '../../../_types';

import Logo from '../_sass/hand_sky.png';
import MainBackground from '../../../_sass/img/bg_default@3x.png';
import styled from 'styled-components/macro';

const WelcomeScreenComponent = styled.div`
  p {
    display: initial;
    font-size: 20px;
  }

  img {
    width: 100%;
    height: auto;
    transform: scale(1.1);
    margin-left: 1.1rem;
  }

  .signin {
    background-color: #ffb016;
    font-size: 14px;
    border-radius: 12px;
    box-shadow: 0 4px 16px 0 rgba(255, 176, 22, 0.5);
    padding: 8px;
    width: 20%;
  }

  .signup {
    font-size: 12px;
    font-family: 'Geometria';
    color: #828282;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  & > * {
    margin: 0.5rem 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-family: 'Geometria-Medium';
  max-width: 360px;
  width: calc(100% - 16px);
  max-height: 737px;
  height: 90vh;
`;

const WelcomeScreen = () => (
  <WelcomeScreenComponent>
    <p>Сила Сбера</p>
    <img src={Logo} alt="" />
    <Link to="signin" className="signin">
      Войти
    </Link>
    <Link to="signup" className="signup">
      Регистрация
    </Link>
  </WelcomeScreenComponent>
);

const PageComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #35323b;
  background-position: center;
  background-size: cover;
`;

const WelcomePage = () => {
  const { accessToken } = useSelector<AppState, AuthState>(
    (state) => state.auth
  );
  const isAuth = !!accessToken;
  return isAuth ? (
    <Redirect to="/main" />
  ) : (
    <PageComponent style={{ backgroundImage: `url(${MainBackground})` }}>
      <WelcomeScreen />
    </PageComponent>
  );
};

export default WelcomePage;
