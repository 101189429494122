import { createGlobalStyle } from 'styled-components/macro';

import Geometria from '../_sass/_fonts/Geometria.woff';
import Geometria_Bold from '../_sass/_fonts/Geometria-Bold.woff';
import Geometria_ExtraBold from '../_sass/_fonts/Geometria-ExtraBold.woff';

// TODO: Add other fonts from '../_sass/_fonts'
export default createGlobalStyle`
    @font-face {        
      font-family: 'Geometria';
      font-style: normal;
      font-weight: normal;
      src: local('Geometria'), url(${Geometria}) format('woff');
    }
    @font-face {        
      font-family: 'Geometria-Bold';
      font-style: normal;
      font-weight: normal;
      src: local('Geometria-Bold'), url(${Geometria_Bold}) format('woff');
    }
    @font-face {        
      font-family: 'Geometria-ExtraBold';
      font-style: normal;
      font-weight: normal;
      src: local('Geometria-ExtraBold'), url(${Geometria_ExtraBold}) format('woff');
    }
`;
