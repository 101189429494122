import React, { Dispatch, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../_shared/types';
import { fileUpload, fileUrl } from '../../../../_api/controller/file';
import { useApiManual } from '../../../_shared/Api';
import { FileExist, FileNone } from '../files';

const Image_: StyledFC<{ fileName?: string }> = ({ className, fileName }) => {
  return (
    <div className={className}>
      {fileName ? (
        <>
          <FileExist src={fileUrl(fileName)} />
          <div>Изменить изображение</div>
        </>
      ) : (
        <>
          <FileNone>
            <div>+</div>
          </FileNone>
          <div>Загрузить изображение</div>
        </>
      )}
    </div>
  );
};

const Image = styled(Image_)`
  display: grid;
  grid-template-columns: 64px auto;
  align-items: center;
  > :nth-child(2) {
    padding-left: 1em;
    color: ${({ theme }) => theme.colors.grey};
  }
`;

// TODO: Show Loader on isLoading
// TODO: Restrict image file type
const ImageInput_: StyledFC<{
  required?: boolean;
  image?: string;
  setImage: Dispatch<string>;
}> = ({ className, required, image, setImage }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [upload, uploadState] = useApiManual(fileUpload);
  const fileName = uploadState.data?.fileName;
  useEffect(() => {
    if (fileName) {
      setImage(fileName);
    }
  }, [fileName, setImage]);

  return (
    <div className={className}>
      <label htmlFor="image">
        <Image fileName={image} />
      </label>
      <input
        required={required}
        id="image"
        style={{ display: 'none', border: 'solid white 1px' }}
        ref={fileInput}
        type="file"
        onInput={() => {
          const current = fileInput.current;
          if (current && current.files && current.files[0]) {
            const data = new FormData();
            data.append('file', current.files[0]);
            upload(data);
          }
        }}
      />
    </div>
  );
};

const FormInputImage = styled(ImageInput_)``;

export default FormInputImage;
