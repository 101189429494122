import { CancelToken } from 'axios';
import API from '../axios';
import { Id } from '../../_types';
import { download } from '../rest';

const path = 'reports';

export const exportReports = download(
  `${path}/export`,
  'report-quest-all.xlsx'
);

export const exportReportOfQuest = (id: Id) =>
  download(`${path}/export/quest/${id}`, `report-quest-${id}.xlsx`);

export const importReport = (data: FormData) => (token?: CancelToken) =>
  API.post<void>(`${path}/import`, data, {
    cancelToken: token,
  });
