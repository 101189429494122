export const getData = () => [
  'Ох уж эти приложения! На пару минут одних не оставишь, сразу баги- вредители налетают. Спасибо, что всех прогнал!',
  'Спасибо тебе, добрый оптимизатор! От этих багов одни неприятности. Хорошо, что ты вовремя подоспел!',
  'Высший класс! Не зря нам посоветовали обратиться именно к тебе. Сразу видно руку опытного охотника на багов!',
  'Когда ты рядом, нашим приложениям ничего не страшно! И как ты справляешься с этими назойливыми багами?',
  'Всех прогнал? Точно? Ребята, можете выходить, славный спаситель справился со всеми багами, что нависли над этим приложением!',
  'Эти ужасные баги взяли приложение в заложники. Мы уже потеряли надежду, но тут появился ты и спас его!',
  'Эх, завидуем твоей отважности! Один против всех этих багов. Признавайся, у тебя где-то припрятан элексир храбрости?',
  'Хватай его! Хватай! Фух, чуть не сбежал... Пронырливый баг попался. Спасибо за помощь, трудяга, побольше бы таких ответственных граждан!',
  'Сумасшедшая неделька, согласись? Эти баги совсем совесть потеряли. Как хорошо, что ты стоишь на защите наших приложений!',
  'Отличная работа! Приложение, которое ты спас от багов, не успело лично поблагодарить тебя, поэтому мы сделаем это за него. Спасибо за помощь!',
  'А говорят еще, что перевелись герои на земле русской. Так вот же один из них, прямо перед глазами!',
  'Как ты их, а? Сначала слева, а затем неожиданно справа! И добивающий удар! Пускай теперь эти баги только попробуют заявиться к нам!',
  'Говорят, на твоем счету ни одного проигрыша! Не поделишься, в чем секрет успешной борьбы с багами?',
  'Враг пойман! Не зря говорят, рано или поздно каждый получает по заслугам. Вот и на опасных багов нашлась расправа в твоем лице!',
  'Защитник, сзади! А тебя не напугать так легко, верно? Всем приложениям бы такого непоколебимого телохранителя!',
  'Блестящая работа! Послушай, а ты случайно не даешь индивидуальных уроков по борьбе с багами? Мы бы парочку точно посетили!',
  'Мы так благодарны тебе! Каждый пойманный баг помогает приложениям становиться лучше! Ну что, двигаемся дальше?',
  'Приложение спасено! У тебя вообще бывает свободная минутка? Как ни посмотрим, отчаянно ведешь борьбу со злыми багами!',
  'А тебя не так просто удивить! От такого смельчака, должно быть, баги бегут, как от огня. Хорошо, что ты в нашей команде!',
];
