export const getData = () => [
  'А ты знал, что существует всемирный индекс благотворительности? Ни на что не намекаем, но сегодня твой полный мешочек помог нам приблизиться к первой позиции!',
  'Ежегодно 13 апреля в России отмечается День благотворителя. Теперь это и твой праздник, ведь твоя пыльца доброты только что отправилась на помощь нуждающимся!',
  'Уф, тяжелый у тебя мешочек... Ну ничего, такое количество пыльцы даром не пропадет. Хотя постой-ка, как раз даром она и будет потрачена на реальную помощь!',
  'Мы тут узнали, что в буддизме достичь состояния нирваны без благотворительности просто невозможно. Меняем твой мешочек на шаг к блаженству! По рукам?',
  'Ура! Новый мешочек доброты отправляется в руки волонтеров! Признавайся, ты всегда был таким филантропом или тобой движет дух соперничества?',
  'Как сказал Пьер Буаст, первый шаг неблагодарности - это исследование побуждений благотворителя. Без лишних вопросов выражаем признательность за этот мешочек!',
  'Тише-тише, не просыпь пыльцу... Сейчас мы ее аккуратненько упакуем и направим в благотворительный фонд, а тебя щедро наградим бурными овациями!',
  'К людям, что помогли однажды нуждающимся животным, эти создания могут затем прийти во сне в знак благодарности. Уже готов к виртуальной прогулке с новым другом?',
  'Вот и собран полный мешочек! В благотворительности очень важно не попасться на уловки мошенников, но ты можешь не переживать - твоя пыльца в надежных руках!',
  'Пришло время отдать пыльцу из мешочка нуждающимся! В мире таких миллионы: начиная от тяжело больных и заканчивая бездомными животными. Не хочешь себе усатого?',
  'Ты когда-нибудь участвовал в благотворительной акции "Час Земли"? Дерзай! А если боишься темноты, то можно помочь планете иначе: мешочек доброты в помощь!',
  'Ого! И как ты все это время скрывал от нас такой огромный мешок доброты? Ребята, зовите сюда носильщиков, нам тут одним не справиться!',
  '"Золотой век" пожертвований в России был связан с деятельностью множественных благотворителей. Ха, да мы и сами своего рода меценаты с мешками доброты!',
  'Около 69% населения России делали пожертвования в той или иной форме. Сегодня ты присоединишься к 99,7 миллионам небезразличных благодаря накопленной пыльце!',
  'День добрый! Не везете с собой ничего запрещенного? А это что у Вас за спиной, не мешочек ли доброты случайно? Придется изъять на благотворительность!',
  'Спасибо за полный мешочек пыльцы, трудяга! Мы не можем помочь всем, но сделаем уж точно больше тех, кто даже не пытается. Обещаем!',
  'Молодец! Если ты посчитаешь время, затраченное на наполнение мешочка доброты, и сложишь его с нашим желанием помогать нуждающимся, то получишь формулу счастья!',
  'Доказано, что общий вес отходов, попадающих в океан, скоро превысит вес его обитателей. Возможно, мы направим именно твою пыльцу на помощь нашей планете, герой!',
  'До чего дошли технологии: теперь желающий помочь может стать виртуальным хозяином для котика из приюта. Признавайся, ты так быстро скопил мешочек для пушистика?',
  'Канадцы, выигравшие в 2010 году в лотерею 11 миллионов долларов, пожертвовали 97% выигрыша нуждающимся. А ты готов бескорыстно отдать все 100% своей пыльцы!',
];
