import React, { Dispatch } from 'react';
import { StyledFC } from '../../../_shared/types';
import styled from 'styled-components/macro';

const FormTextArea_: StyledFC<{
  label: string;
  id: string;
  placeHolder?: string;
  required?: boolean;
  minLength?: number;
  state: string | undefined;
  setState: Dispatch<string>;
}> = ({
  className,
  label,
  id,
  placeHolder,
  required,
  minLength,
  state,
  setState,
}) => (
  <div className={className}>
    <label htmlFor={id}>{label}</label>
    <br />
    <textarea
      style={
        {
          // width: '-webkit-fill-available',
        }
      }
      id={id}
      placeholder={placeHolder}
      required={required}
      minLength={minLength}
      defaultValue={state}
      onChange={(e) => setState(e.target.value)}
    />
  </div>
);

const FormTextArea = styled(FormTextArea_)`
  //align-self: stretch;
  display: block;
  > label {
    align-self: start;
    position: relative;
    top: 1em;
    background: white;
    left: 1em;
    padding: 0 0.5em;
    color: ${({ theme }) => theme.colors.grey};
  }
  > textarea {
    z-index: 1;
    align-self: stretch;
    justify-self: stretch;
    height: 4em;
    font-family: Geometria-Medium, sans-serif;
    font-size: 16px;
    padding: 1em 1em;
    color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.grey};
    border-radius: ${({ theme }) => theme.borderRadius}px;
    border-width: 2px;
    &:focus {
      color: ${({ theme }) => theme.colors.yellow};
      border-color: ${({ theme }) => theme.colors.greySpan};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`;

export default FormTextArea;
