import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Button: FC<{
  text: string;
  type: string;
  onClick?: () => void;
  addStyle?: {
    color: string;
    backgroundColor: string;
  };
  insideFeedback?: boolean;
}> = ({ text, type, onClick, addStyle, insideFeedback }) => (
  // Если есть дополнительные стили от родителя, то применяем их, если нет, то используем значение в зависимости от типа сценария
  <ButtonComponent
    onClick={onClick}
    className="scriptButton"
    style={{
      borderColor: type.toUpperCase() === 'BUGS' ? '#27AE60' : '#f2994a',
      color: addStyle
        ? addStyle.color
        : type.toUpperCase() === 'BUGS'
        ? '#27AE60'
        : '#f2994a',
      backgroundColor: addStyle ? addStyle.backgroundColor : '',
      padding: insideFeedback ? 0 : '0 18px',
    }}
  >
    {text}
  </ButtonComponent>
);

const ButtonComponent = styled.button`
  border: 2px solid;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  cursor: pointer;
  min-width: 57px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  &:focus {
    outline: 0;
  }
`;

export default Button;
