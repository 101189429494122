import React from 'react';
import styled from 'styled-components/macro';
import { getQuestTypes } from '../../../../_api';
import { Api, useApiWoParams } from '../../../_shared/Api';
import ListItem from './ListItem';
import Edit from './Edit';
import { RouteEdit } from '../../_shared/routes';
import createEntityList from '../../_shared/createEntityList';
import { QuestType } from '../../../../_types';
import TabContent, { StyledTab } from '../../../_shared/Tabs/TabContent';

/** Apply component factory statically (out of render scope) */
const BugList = createEntityList<QuestType>(ListItem);

const Bugs_: StyledTab = ({ className, show }) => {
  const state = useApiWoParams(getQuestTypes);
  return (
    <TabContent className={className} show={show}>
      <Api
        result={state}
        renderData={(list, { reFetch }) => (
          <>
            <BugList list={list} />
            {show && (
              <RouteEdit>{() => <Edit reFetchList={reFetch} />}</RouteEdit>
            )}
          </>
        )}
      />
    </TabContent>
  );
};

const Bugs = styled(Bugs_)`
  display: grid;
  grid-template-rows: auto 72px 0;
  align-self: center;
  height: 100%;
  margin: 0;
  &:first-child {
    align-self: center;
  }
  > * {
    margin: 0 24px;
  }
`;

export default Bugs;
