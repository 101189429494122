import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import './responsive.scss';
import BackHeader from './BackHeader';

import { Api, useApi, useApiManual } from '../../_shared/Api';
import { getUser, updateUser } from '../../../_api/controller';

import { useMe, useReFetchMe } from '../../_shared';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { fileUpload, fileUrl } from '../../../_api/controller/file';
import EmptyAva from '../_sass/empty_ava.png';
import EmailDialog from './EmailDialog';
import { emailRegexp } from '../../../_constants';
import CodeDialog from './CodeDialog';
import InputRow from '../../_shared/InputRow';

const EditUser = () => {
  const match = useRouteMatch();
  const me = useMe();
  const userId = me.id;
  const user = useApi(getUser, userId);
  /** AVATAR */
  const fileInput = useRef<HTMLInputElement>(null);

  const [upload, uploadState] = useApiManual(fileUpload);
  const [userUpdate, userUpdateState] = useApiManual(updateUser);
  const uploadStateFileNameData = uploadState.data?.fileName;

  useEffect(() => {
    if (uploadStateFileNameData) {
      userUpdate({
        id: userId,
        value: { avatar: uploadStateFileNameData },
      });
    }
  }, [userId, uploadStateFileNameData, userUpdate]);

  const userReFetch = user.reFetch;
  const userUpdateStateCountOfCompletions = userUpdateState.countOfCompletions;

  useEffect(() => {
    userReFetch();
  }, [userReFetch, userUpdateStateCountOfCompletions]);

  /** NAME EMAIL */

  const reFetchMe = useReFetchMe();
  const history = useHistory();

  const [newName, setNewName] = useState(me.name || '');

  const [oldEmail, setOldEmail] = useState(me.email || '');
  const [newEmail, setNewEmail] = useState(oldEmail);
  /** ONCLICK BACK */
  const [updateNameBack, updateStateNameBack] = useApiManual(updateUser);
  const onClickUpdateName = () => {
    //console.log('newEmail:', newEmail);
    if (me.name !== newName && newName !== '') {
      console.log('me:', me.name);
      console.log('newName:', newName);
      updateNameBack({
        id: userId,
        value: { name: newName },
      });
    } else if (
      oldEmail !== newEmail &&
      newEmail !== '' &&
      emailRegexp.test(newEmail)
    ) {
      history.push(`${match.url}/email`);
    } else {
      reFetchMe();
      history.push(`/profile`);
    }
    /*if (
      oldEmail !== newEmail &&
      newEmail !== '' &&
      emailRegexp.test(newEmail)
    ) {
      history.push(`${match.url}/email`);
    } else {
      reFetchMe();
      history.push(`/profile`);
    }*/
  };
  //console.log(updateStateNameBack);
  const updateNameDone =
    updateStateNameBack.error == null &&
    updateStateNameBack.countOfCompletions > 0;

  useEffect(() => {
    if (updateNameDone) {
      reFetchMe();
      history.push(`/profile`);
    }
  }, [updateNameDone, history, reFetchMe]);

  /** ONBLUR AND ONKEYPRESS*/
  /** name */
  const historyPush = history.push;
  const onBlurKeyUpdateName = () => {
    //console.log('me.name:', me.name);
    //console.log('newName:', newName);

    if (me.name !== newName && newName !== '') {
      updateBlurName({
        id: userId,
        value: { name: newName },
      });
    }
    setNameInputColor('black');
  };
  /** email */
  const onBlurKeyUpdateEmail = () => {
    //console.log('oldEmail:', oldEmail);
    //console.log('newEmail:', newEmail);
    if (
      oldEmail !== newEmail &&
      newEmail !== '' &&
      emailRegexp.test(newEmail)
    ) {
      historyPush(`${match.url}/email`);
    }
    setEmailInputColor('black');
  };

  const [updateBlurName, updateStateBlurName] = useApiManual(updateUser);
  const updateDoneBlurName =
    updateStateBlurName.error == null &&
    updateStateBlurName.countOfCompletions > 0;
  //console.log('2:', updateDoneBlurName);
  useEffect(() => {
    if (updateDoneBlurName) {
      reFetchMe();
    }
  }, [updateDoneBlurName, reFetchMe]);
  /** TEXT COLOR */
  const [nameInputColor, setNameInputColor] = useState('black');
  const [emailInputColor, setEmailInputColor] = useState('black');

  /** Email input border with validating */
  const [isValidEmail, setIsValidEmail] = useState('2px solid black');
  useEffect(() => {
    emailRegexp.test(newEmail)
      ? setIsValidEmail('2px solid black')
      : setIsValidEmail('2px solid red');
  }, [newEmail]);

  const [password, setPassword] = useState('');

  return (
    <>
      <Api
        result={user}
        renderData={(userData) => (
          <>
            <div className="edit-info">
              <BackHeader onClickUpdateName={onClickUpdateName} />
              <form onSubmit={() => false}>
                <label htmlFor="ava">
                  <div className="load-ava">
                    <div className="ava">
                      <img src={fileUrl(userData?.avatar) || EmptyAva} alt="" />
                    </div>
                    <div className="content">Загрузить новый аватар</div>
                    <input
                      className="hidden-ava-input"
                      ref={fileInput}
                      type="file"
                      onInput={() => {
                        if (fileInput.current?.files) {
                          const data = new FormData();
                          data.append('file', fileInput.current?.files[0]);
                          upload(data);
                        }
                      }}
                      id="ava"
                      name="ava"
                    />
                  </div>
                </label>
              </form>

              <div className="inputs">
                <div className="input">
                  <label>Имя</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                    onBlur={onBlurKeyUpdateName}
                    onKeyPress={onBlurKeyUpdateName}
                    value={newName}
                    style={{ color: nameInputColor }}
                    onFocus={() => setNameInputColor('#F2994A')}
                  />
                </div>
                <div className="input">
                  <label>E-mail</label>
                  <input
                    type="email"
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                    }}
                    onKeyPress={onBlurKeyUpdateEmail}
                    onBlur={onBlurKeyUpdateEmail}
                    value={newEmail}
                    style={{ color: emailInputColor, border: isValidEmail }}
                    onFocus={() => setEmailInputColor('#F2994A')}
                  />
                </div>
              </div>

              <InputRow
                sendAction={() => historyPush(`${match.url}/code`)}
                autoFocus={true}
                setPassword={(data: { password: string }) =>
                  setPassword(data.password)
                }
                password={password}
                modal
                type={'password'}
              >
                {
                  <Route
                    path={`${match.url}/code`}
                    render={() => (
                      <CodeDialog
                        callback={() => setPassword('')}
                        userId={userId}
                        password={password}
                      />
                    )}
                  />
                }
              </InputRow>

              <Route
                path={`${match.url}/email`}
                render={() => (
                  <EmailDialog
                    userId={userId}
                    setOldEmail={setOldEmail}
                    setNewEmail={setNewEmail}
                    newEmail={newEmail}
                  />
                )}
              />
            </div>
          </>
        )}
      />
    </>
  );
};

export default EditUser;
