import React from 'react';
import './style.scss';
import UserInfo from './UserInfo';
import Achievements from './UserInfo/Achievements';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Edit from './EditUser';
import Dialog from './UserInfo/Achievements/Dialog';
import MergeAchievements from './_shared/MergeAchievements';

const Profile = () => {
  const match = useRouteMatch();

  return (
    <MergeAchievements>
      {(achievements) => (
        <div className="lb-page">
          <div className="lb-screen">
            <div className="lb-wrapper">
              <Switch>
                <Route
                  exact
                  path={`${match.url}`}
                  render={() => <UserInfo achievements={achievements} />}
                />

                <Route
                  path={`${match.url}/achievements`}
                  render={() => <Achievements achievements={achievements} />}
                />

                <Route path={`${match.url}/edit`} render={() => <Edit />} />
                <Route
                  path={`${match.path}`}
                  render={() => <Redirect to={`${match.path}`} />}
                />
              </Switch>
            </div>
          </div>
          <Route
            path={`${match.url}/achievements/:id`}
            render={() => <Dialog achievements={achievements} />}
          />
        </div>
      )}
    </MergeAchievements>
  );
};

export default Profile;
