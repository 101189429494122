import React from 'react';
import styled from 'styled-components/macro';
import LoadingIconWhite from '../../../../_sass/icon/loading_white.png';
import LoadingIconOrange from '../../../../_sass/icon/loading_orange.png';
import { StyledFC } from '../../../_shared/types';

export const FileExist = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: solid ${({ theme }) => theme.colors.greySpan} 1px;
  height: 64px;
  width: 64px;
`;

export const FileNone = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: dashed ${({ theme }) => theme.colors.greySpan} 1.5px;
  height: 64px;
  width: 64px;

  display: grid;
  grid-template: 1/-1;
  align-items: center;
  justify-items: center;
  > div {
    color: ${({ theme }) => theme.colors.greySpan};
    font-family: Geometria, sans-serif;
    font-size: 32px;
  }
`;

const LoadingIcon_: StyledFC<{ color: 'white' | 'orange' }> = ({
  className,
  color,
}) => (
  <img
    className={className}
    src={color === 'white' ? LoadingIconWhite : LoadingIconOrange}
    alt="..."
  />
);
export const LoadingIcon = styled(LoadingIcon_)`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  animation: rotation 1.2s infinite linear;
`;
