import React, { FC } from 'react';
import './style.scss';

const BadgeContent: FC<{
  name: string;
  description: string;
}> = ({ name, description }) => {
  return (
    <>
      <div className="content-name">{name}</div>
      <div className="content-desc">{description}</div>
    </>
  );
};
export default BadgeContent;
