import * as api from '../internal';
import { QuestSystem } from '../../_types';

const path = 'quest-systems';

export type CreateQuestSystemArg = {
  name: string;
};
export type UpdateQuestSystemArg = CreateQuestSystemArg;

export const getQuestSystems = api.getList<QuestSystem>(path);
export const getQuestSystem = api.getEntity<QuestSystem>(path);

export const createQuestSystem = api.createEntity<
  CreateQuestSystemArg,
  QuestSystem
>(path);
export const updateQuestSystem = api.updateEntity<
  UpdateQuestSystemArg,
  QuestSystem
>(path);
export const deleteQuestSystem = api.deleteEntity(path);
