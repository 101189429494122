import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Api, useApiWoParams } from '../_shared/Api';
import {
  getCurrentSeason,
  getNotReadNotificationsCount,
  getTeamRatingTotalPoints,
} from '../../_api';

import MenuPanel from './MenuPanel';
import SeasonPage from './Season';
import Cards from './Cards';
import styled from 'styled-components/macro';
import { SockContext } from '../App/SockContext';
import { StyledFC } from '../_shared/types';
import MainView from './MainView';

const MainPage_: StyledFC = ({ className }) => {
  const notificationsState = useApiWoParams(getNotReadNotificationsCount);

  const seasonState = useApiWoParams(getCurrentSeason);
  const teamRatingTotalPoints = useApiWoParams(getTeamRatingTotalPoints);

  // работа с сокетами
  const fromContext = useContext(SockContext);
  const notificationsReFetch = notificationsState.reFetch;
  useEffect(() => {
    const notificationValue: string | undefined =
      fromContext.notificationUpdateValue;
    // здесь необходимо стянуть заново таблицу "История"
    if (notificationValue !== undefined) {
      notificationsReFetch();
      console.log('reFetch notifications');
    }
  }, [fromContext.notificationUpdateValue, notificationsReFetch]);

  return (
    <Api
      result={seasonState}
      renderData={(season) =>
        season === null ? (
          <div>Текущий сезон не установлен</div>
        ) : (
          <Api
            result={teamRatingTotalPoints}
            renderData={(totalPoints) => (
              <Api
                result={notificationsState}
                renderData={(notifications) => {
                  return (
                    <div className={className}>
                      <MainView
                        totalPoints={totalPoints.points}
                        season={season}
                      />
                      <Cards />
                      <MenuPanel notificationsCount={notifications.count} />
                    </div>
                  );
                }}
              />
            )}
          />
        )
      }
    />
  );
};

const MainPage = styled(MainPage_)`
  display: grid;
  grid-template-rows: 90% auto;
  grid-gap: 2em;
  height: 100%;
  width: 100%;

  background-position: center;
  @media (min-width: 400px) {
    background-position-x: 1em;
  }
  background-size: cover;
  background: rgba(18, 12, 38, 1);
  background: radial-gradient(
    circle,
    rgba(94, 46, 118, 1) 0%,
    rgba(63, 33, 163, 0.2) 35%,
    rgba(18, 12, 38, 0) 60%
  );
`;

const Main = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={MainPage} />
      <Route path={`${match.path}/season`} component={SeasonPage} />
      <Route render={() => <Redirect to="/main" />} />
    </Switch>
  );
};

export default Main;
