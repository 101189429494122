import React, { FC, useContext, useEffect } from 'react';
import { Route, Switch, Link, useRouteMatch } from 'react-router-dom';

import Approved from './approved';
import Counter from './counter';
import LevelUp from './levelup';
import Target from './target';
import Teaming from './teaming';
import Thanks from './thanks';
import styled from 'styled-components/macro';
import MainBackground from '../../_sass/img/bg_default@3x.png';
import { SockContext } from '../App/SockContext';
import NewBadge from './newbadge';

const PageComponent = styled.div`
  .back-link {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 1rem;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url(${MainBackground});
`;

const HostPathMock: FC = () => {
  const path = useRouteMatch().path;

  return (
    <>
      <Link to={`${path}/approved?gained=100`}>/approved</Link>
      <Link to={`${path}/counter?gained=100`}>/counter</Link>
      <Link to={`${path}/levelup`}>/levelup</Link>
      <Link to={`${path}/newbadge`}>/newbadge</Link>
      <Link to={`${path}/target`}>/target</Link>
      <Link to={`${path}/teaming`}>/teaming</Link>
      <Link to={`${path}/thanks`}>/thanks</Link>
      <Link className="back-link" to="/">
        proceed to main
      </Link>
    </>
  );
};

const CongratsPage: FC = () => {
  const path = useRouteMatch().path;

  // работа с сокетами
  const fromContext = useContext(SockContext);
  useEffect(() => {
    fromContext.setNotificationIsWork(false);
    return () => {
      fromContext.setNotificationIsWork(true);
    };
  }, [fromContext]);

  return (
    <PageComponent>
      <Switch>
        <Route path={`${path}/approved`} component={Approved} />
        <Route path={`${path}/counter`} component={Counter} />
        <Route path={`${path}/levelup`} component={LevelUp} />
        <Route path={`${path}/newbadge`} component={NewBadge} />
        <Route path={`${path}/target`} component={Target} />
        <Route path={`${path}/teaming`} component={Teaming} />
        <Route path={`${path}/thanks`} component={Thanks} />
        <Route component={HostPathMock} />
      </Switch>
    </PageComponent>
  );
};

export default CongratsPage;
