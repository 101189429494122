import React, { Dispatch, useRef } from 'react';
import styled from 'styled-components/macro';
import { Id, Int } from '../../../_types';
import { ListState, useListItem } from './EntityList';
import { StyledFC } from '../../_shared/types';

const EntityListItem_: StyledFC<{
  listState: ListState;
  entityId: Id;
  viewOnClick?: boolean;
  renderButtons: (
    isSelected: boolean,
    setSelected: Dispatch<boolean>,
    size: Int
  ) => JSX.Element;
}> = ({ className, listState, entityId, children, renderButtons }) => {
  const { isSelected, setSelected } = useListItem(listState, entityId);
  const itemDiv = useRef<HTMLDivElement>(null);
  const size = itemDiv.current ? itemDiv.current.clientHeight : 0;
  return (
    <div className={className}>
      <div
        ref={itemDiv}
        className="data"
        onClick={() => {
          setSelected(!isSelected);
        }}
      >
        {children}
      </div>
      {renderButtons(isSelected, setSelected, size)}
    </div>
  );
};

const EntityListItem = styled(EntityListItem_)`
  position: relative;
  > .data {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    font-family: 'Geometria', sans-serif;
  }
`;

export default EntityListItem;
