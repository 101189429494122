import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Api, useApi, useApiManualVoid } from '../../_shared/Api';
import { getNotification } from '../../../_api';
import { dateToString } from '../_shared/helpers';
import { setNotificationRead } from '../../../_api';
import ScreenWrap from '../_shared/screen-wrap';
import styled from 'styled-components/macro';

const DetailedComponent = styled.div`
  .notification-data {
    h2 {
      font-family: 'Geometria-ExtraBold';
      font-size: 25px;
      font-weight: 800;
      line-height: 1.2;
    }

    .date {
      font-size: 12px;
      color: #828282;
      margin-bottom: 32px;
    }

    .content {
      font-size: 16px;
    }

    & > * {
      margin: 0;
      margin-bottom: 8px;
    }

    & > :last-child {
      margin-bottom: inherit;
    }

    width: 100%;
  }

  a {
    &::-moz-focus-inner {
      border: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 4px 16px 0 rgba(255, 176, 22, 0.5);
    background-color: #ffb016;
    font-family: 'Geometria-Bold';
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 12px;
    padding: 0;
    min-height: 44px;
    min-width: 180px;
    width: 55%;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  line-height: 1.4;
`;

interface RouteParams {
  id: string;
}

const Detailed = () => {
  const params = useParams<RouteParams>();
  const elemID = parseInt(params.id);
  const result = useApi(getNotification, elemID);

  const [setRead] = useApiManualVoid(setNotificationRead);

  useEffect(() => {
    setRead(elemID);
  }, [setRead, elemID]);

  return (
    <ScreenWrap isLoading={result.isLoading} redirect="/notifications">
      <Api
        result={result}
        renderData={(data, { reFetch }) => {
          const item = {
            ...data,
            date: dateToString(new Date(data.date), 'DD.MM.YYYY'),
          };

          let title;
          let detailed;
          let dest = '';

          switch (item.eventType) {
            case 'START_NEW_QUEST':
              title = 'Начало нового квеста';
              detailed = 'Новый квест начался!';
              dest = item.quest ? `/script/${item.quest.id}` : '';
              break;
            case 'RECEIVED_NEW_BADGE':
              title = 'Получен новый бейж';
              detailed = 'Вы получили новый бейдж! Поздравляем';
              dest = '/congrats/target';
              break;
            case 'TRANSITION_NEW_LEVEL':
              title = 'Получен новый уровень';
              detailed = 'Поздравляем с получением нового уровня!';
              dest = '/congrats/levelup';
              break;
            case 'CUSTOMER_APPROVED_BUG':
              title = 'Баг одобрен';
              detailed = 'Найденный баг одобрен!';
              dest = '/congrats/thanks';
              break;
            case 'SET_SEASON_GOAL':
              title = 'Новая цель';
              detailed = 'Начался новый сезон!';
              break;
            case 'QUEST_COMPLETED_USER':
              title = 'Квест выполнен';
              detailed = 'Вы выполнили текущий квест. Поздравляем!';
              break;
            case 'QUEST_CONFIRMED_ADMIN':
              title = 'Квест подтвержден';
              detailed = 'Выполнение квеста подтверждено';
              break;
            case 'QUEST_REJECTED_ADMIN':
              title = 'Квест отклонен';
              detailed = 'Статус выполнения квеста: отклонен';
              break;
            case 'EXTRA_POINTS':
              title = 'Ежедневная награда';
              detailed = 'Получены очки за ежедневный вход!';
              dest = '/congrats/counter';
              break;

            default:
              break;
          }

          const { header, content, date } = {
            header: title,
            content: detailed,
            date: item.date,
          };

          return (
            <DetailedComponent>
              <div className="notification-data">
                <h2>{header}</h2>
                <p className="date">{date}</p>
                <p className="content">{content}</p>
              </div>
              {dest ? <Link to={dest}>Открыть</Link> : <></>}
            </DetailedComponent>
          );
        }}
      />
    </ScreenWrap>
  );
};

export default Detailed;
