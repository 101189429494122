import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { fileUrl } from '../../_api/controller/file';

const ListItemIcon: FC<{
  className: string;
  isMyRating: boolean;
  avatar: string;
  levelIcon: string;
}> = ({ className, isMyRating, avatar, levelIcon }) => {
  return isMyRating ? (
    <MyListItemIconComponent className={className}>
      <div className="relative">
        {' '}
        <img className="award" src={levelIcon} alt="award" />
      </div>
      <img className="avatar" src={fileUrl(avatar)} alt="avatar" />
    </MyListItemIconComponent>
  ) : (
    <ListItemIconComponent className={className}>
      <img className="avatar" src={fileUrl(avatar)} alt="avatar" />
      <img className="award" src={levelIcon} alt="award" />
    </ListItemIconComponent>
  );
};

const ListItemIconComponent = styled.div`
  width: 32px;
  height: 32px;
  background: #4f4f4f;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  .award {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(calc(-50% + 1px), 13px);
    height: 25px;
    width: 25px;
  }
`;

const MyListItemIconComponent = styled.div`
  background: #4f4f4f;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: absolute;
  width: 64px;
  height: 64px;
  left: 25px;
  top: -7px;
  .relative {
    position: relative;
  }
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  .award {
    position: absolute;
    top: 34px;
    left: 50%;
    transform: translateX(-50%);
    height: 46px;
    width: 46px;
  }
`;

export default ListItemIcon;
