import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AppState, AuthState } from '../../../_types';
import { authActions, SignOutAction } from '../../auth/_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { useMe } from '../../_shared';
import { StyledFC } from '../../_shared/types';
import QaIcon from '../../_shared/QaIcon';

const RegularButtonComponent = styled.div`
  .notification-wrapper {
    display: flex;
    position: relative;

    .counter {
      position: absolute;
      top: -6px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-size: 13px;
      border-radius: 100%;
      border: solid 4px #ffffff;
      background-color: #f2994a;
      color: #fff;
      font-family: 'Roboto';
      font-weight: 700;
    }
  }

  span {
    font-size: 3rem;
    overflow: visible;
  }

  p {
    display: initial;
    font-family: 'Geometria-Medium';
    font-size: 12px;
    white-space: pre-line;
    text-align: center;
  }

  a {
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }

    & > * {
      margin: 0.5rem 0;
    }

    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: calc(50% - 16px);
    height: auto;
    color: #35323b;
  }

  & > * {
    margin: 0.5rem 0;
  }

  &:after {
    content: '';
    display: block;
    padding-top: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 16px);
  margin: 8px;
  border-radius: 32px;
  box-shadow: 0 16px 24px 0 rgba(53, 50, 59, 0.4),
    0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  cursor: pointer;
  z-index: 10;
`;

const PendingButtonComponent = styled(RegularButtonComponent)`
  span {
    color: #f2994a;
  }

  color: #f2994a;
`;

const AdminButtonComponent = styled(RegularButtonComponent)`
  background-color: #f2994a;
  a {
    color: #fff;
  }
`;

const SignOutButtonComponent = styled(RegularButtonComponent)`
  span {
    color: #fff;
  }

  background-color: #35323b;
  color: #fff;
  div {
    span {
      margin: 0.5rem 0 0.5rem 0.8rem;
    }

    p {
      margin: 0.5rem 0;
    }

    height: auto;
    width: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonWrap: FC<{
  notifications?: number;
  to: string;
  text: string;
  icon: React.ReactElement | React.ReactElement[];
  onClick?: () => void;
}> = ({ notifications, to, text, icon, onClick }) =>
  notifications ? (
    <PendingButtonComponent>
      <Link to={to}>
        <div className="notification-wrapper">
          {icon}
          <span className="counter">{notifications}</span>
        </div>
        <p>{text}</p>
      </Link>
    </PendingButtonComponent>
  ) : to === '/admin' ? (
    <AdminButtonComponent>
      <Link to={to}>
        {icon}
        <p>{text}</p>
      </Link>
    </AdminButtonComponent>
  ) : to === '/signout' ? (
    <SignOutButtonComponent onClick={onClick}>
      <div>
        {icon}
        <p>{text}</p>
      </div>
    </SignOutButtonComponent>
  ) : (
    <RegularButtonComponent>
      <Link to={to}>
        {icon}
        <p>{text}</p>
      </Link>
    </RegularButtonComponent>
  );

const Menu_: StyledFC<{
  notificationsCount: number;
}> = ({ className, notificationsCount }) => {
  const me = useMe();
  const match = useRouteMatch();

  // useEffect(() => {
  //   const rootChild = (document.getElementById('root') as HTMLElement)
  //     .childNodes[0] as HTMLElement;
  //   rootChild.style.overflow = 'hidden';
  //
  //   return () => {
  //     rootChild.style.overflow = 'auto';
  //   };
  // }, []);

  const { refreshToken } = useSelector<AppState, AuthState>(
    (state) => state.auth
  );
  const signOutDispatch = useDispatch<Dispatch<SignOutAction>>();
  const signOut = () => {
    if (refreshToken) authActions.signOut(refreshToken)(signOutDispatch);
  };

  return (
    <div
      className={className}
      onScroll={(e) => {
        e.stopPropagation();
      }}
    >
      <ButtonWrap
        notifications={notificationsCount}
        to={'/notifications'}
        text={'Уведомления'}
        icon={<QaIcon name="notification" />}
      />
      <ButtonWrap
        to={'/rating'}
        text={'Рейтинг'}
        icon={<QaIcon name="leaderboard" />}
      />
      <ButtonWrap
        to={`${match.path}/season`}
        text={'Команды'}
        icon={<QaIcon name="bag" />}
      />
      <ButtonWrap
        to="/about"
        text={'О системе'}
        icon={<QaIcon name="question-2" />}
      />
      {me.isAdmin && (
        <ButtonWrap
          to={'/admin'}
          text={'Админ'}
          icon={<QaIcon name="lightning" />}
        />
      )}
      <ButtonWrap
        to={'/signout'}
        onClick={signOut}
        text={'Выйти'}
        icon={<QaIcon name="signout" />}
      />
    </div>
  );
};

const Menu = styled(Menu_)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  bottom: calc(var(--wh) * 100) px;
  align-self: center;
  justify-self: center;
  align-items: center;
  pointer-events: all;
  & > :nth-child(odd) {
    justify-self: end;
  }
  & > :nth-child(even) {
    justify-self: start;
  }
  & > * {
    --size: calc(var(--ww) * 40);
    --max-size: calc(150px);
    width: var(--size);
    height: var(--size);
    max-width: var(--max-size);
    max-height: var(--max-size);

    font-weight: normal;
  }
`;

export default Menu;
