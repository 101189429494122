import React from 'react';
import { ListState } from '../../_shared/EntityList';
import { SeasonGoalLevel } from '../../../../_types';
import { ReFetch } from '../../../_shared/Api';
import { StyledFC } from '../../../_shared/types';
import styled from 'styled-components/macro';
import EditButton from '../../_shared/overlay-buttons/EditButton';
import OverlayButtons from '../../_shared/overlay-buttons/OverlayButtons';
import EntityListItem from '../../_shared/EntityListItem';

const ListItem_: StyledFC<{
  listState: ListState;
  entity: SeasonGoalLevel;
  index: number;
  reFetch: ReFetch;
}> = ({ className, listState, entity }) => {
  return (
    <EntityListItem
      className={className}
      listState={listState}
      entityId={entity.id}
      renderButtons={(isSelected, setSelected, size) => (
        <OverlayButtons isVisible={isSelected} size={size}>
          {[
            <EditButton
              key="edit"
              entityId={entity.id}
              size={size}
              before={() => setSelected(false)}
            />,
          ]}
        </OverlayButtons>
      )}
    >
      <p className="header">{entity.name}</p>
      <p className="description">{`${entity.pointsCount} пыльцы доброты`}</p>
    </EntityListItem>
  );
};

const ListItem = styled(ListItem_)`
  position: relative;
  > .data {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    font-family: 'Geometria', sans-serif;
    > .header {
      font-weight: bold;
      font-size: 12px;
    }
    > .description {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export default ListItem;
