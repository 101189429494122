import React, { FC } from 'react';
import QaIcon from '../../_shared/QaIcon';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const BackHeader: FC = () => {
  return (
    <Header>
      <Link to={`/main`}>
        <QaIcon name={'back'} />
      </Link>
    </Header>
  );
};

const Header = styled.div`
  width: 100%;
  display: flex;
  height: 28px;
  padding-right: 24px;
  justify-content: space-between;
  align-items: center;
  span {
    position: absolute;
    font-size: 1.1rem;
    line-height: 1.8rem;
    left: 23px;
    top: 28px;
  }
  .cancel-button {
    font-family: Geometria, sans-serif;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: right;
    text-decoration: none;
    color: #4f4f4f;
  }
`;

export default BackHeader;
