import { sockNotification } from '../../_types';
import { sockNotificationTypes, sockRedirectUrls } from '../../_constants';

export const checkSockTypeAndGetPushUrl = (res: sockNotification) => {
  const T = sockNotificationTypes;
  const U = sockRedirectUrls;
  if (res.eventType === T.RECEIVED_NEW_BADGE)
    return {
      pathname: U.NEWBADGE,
      achievementImage: res.achievementImage,
      achievementDescription: res.achievementDescription,
    };
  else if (res.eventType === T.BIG_TASK) return { pathname: U.TARGET };
  else if (res.eventType === T.TRANSITION_NEW_LEVEL)
    return { pathname: U.LEVELUP };
  else if (res.eventType === T.QUEST_COMPLETED_USER)
    return { pathname: U.THANKS, gained: res.points };
  else if (res.eventType === T.CUSTOMER_APPROVED_BUG)
    return { pathname: U.APPROVED, gained: res.points };
  else if (res.eventType === T.EXTRA_POINTS)
    return { pathname: U.COUNTER, gained: res.points };
  else {
    return undefined;
  }
};

export const getCurrentUserEmail = () => {
  let email = '';
  let localStorage = window.localStorage.getItem('auth');
  if (localStorage !== undefined && localStorage !== null) {
    const emailIndex = localStorage.indexOf('email') + 8;
    const avatarIndex = localStorage.indexOf('avatar') - 3;
    email = localStorage.substring(emailIndex, avatarIndex);
    return email;
  }
  return '';
};

export const getSockUrl = (email: string) => {
  return `${process.env.REACT_APP_BASE_PATH}api/v1/notifications-socket?token=${email}`;
};
