import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import MenuButton from './MenuButton';
import EmptyAva from '../../../_sass/icon/empty_ava.png';
import { fileUrl } from '../../../_api/controller/file';
import { useMe } from '../../_shared';
import { StyledFC } from '../../_shared/types';
import Menu from './Menu';

type LocationProps = {
  menu: boolean | undefined;
};

const MenuPanel_: StyledFC<{
  notificationsCount: number;
}> = ({ className, notificationsCount }) => {
  const me = useMe();
  const location = useLocation();
  const { menu }: LocationProps = (location?.state as {
    menu: boolean;
  }) || { menu: false };
  const [isMenuOpen, setMenuOpen] = useState(menu);
  return (
    <div className={`${className} ${isMenuOpen ? 'open' : ''}`}>
      <div className="central-block">
        <div className="menu-row">
          <div className="menu-button">
            <MenuButton
              notificationsCount={notificationsCount}
              isMenuOpen={isMenuOpen}
              onClick={() => setMenuOpen(!isMenuOpen)}
            />
          </div>
          <div className="profile-button">
            <Link to="/profile">
              <div>
                <div className="name">{me.name}</div>
                <img
                  className="ava"
                  src={me.avatar ? fileUrl(me.avatar) : EmptyAva}
                  alt="#"
                />
              </div>
            </Link>
          </div>
        </div>
        {isMenuOpen && <Menu notificationsCount={notificationsCount} />}
      </div>
    </div>
  );
};

const MenuPanel = styled(MenuPanel_)`
  position: fixed;
  display: grid;
  grid-template-rows: min-content auto;
  height: 100%;
  width: 100%;
  //max-width: 560px;
  font-size: 13px;
  font-family: Geometria-Bold, sans-serif;
  font-weight: bold;

  &.open {
    background-color: #2228;
  }
  pointer-events: none;
  --badge-border-radius: 1.2em;
  --badge-padding-ver: 1.5em;
  --badge-padding-hor: calc(var(--badge-padding-ver) + 0.5em);

  & > .central-block {
    position: relative;
    justify-self: center;
    height: calc(var(--wh) * 100);
    //width: 100%;
    max-width: 375px;
    pointer-events: none;
    & > .menu-row {
      pointer-events: all;
      display: grid;
      grid-template-columns: 1fr 4fr;
      & > .menu-button {
        display: block;
        align-self: stretch;
        justify-self: stretch;

        padding: var(--badge-padding-ver) var(--badge-padding-hor);

        & > div {
          padding: 1em 1em 0.8em;
          border-radius: var(--badge-border-radius);
        }
      }

      & > .profile-button {
        display: block;
        align-self: center;
        justify-self: end;
        padding-top: var(--badge-padding-ver);
        padding-bottom: var(--badge-padding-ver);
        padding-right: var(--badge-padding-hor);

        & > a {
          text-decoration: none;

          & > div {
            display: grid;
            grid-template-columns: auto auto;
            align-self: center;
            justify-self: end;
            vertical-align: center;
            height: 100%;
            width: 100%;
            border-radius: var(--badge-border-radius);
            background-color: white;
            --div-padding: 0.1em;
            padding: var(--div-padding);

            & > .name {
              align-self: center;
              justify-self: start;
              padding: calc(1em - var(--div-padding) * 2);
            }

            & > .ava {
              align-self: center;
              justify-self: end;
              --ava-size: calc(
                (var(--badge-padding-ver) - var(--div-padding)) * 2
              );
              max-width: var(--ava-size);
              max-height: var(--ava-size);
              border-radius: var(--badge-border-radius);
            }
          }
        }
      }
    }
  }
`;

export default MenuPanel;
