import React from 'react';
import styled from 'styled-components/macro';

const LoaderComponent = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  //z-index: 100;
  margin: 0 !important;
  .loader,
  .loader:after {
    margin: 0 !important;
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 176, 22, 0.2);
    border-right: 1.1em solid rgba(255, 176, 22, 0.2);
    border-bottom: 1.1em solid rgba(255, 176, 22, 0.2);
    border-left: 1.1em solid #ffb016;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => {
  return (
    <LoaderComponent>
      <div className="loader"> </div>
    </LoaderComponent>
  );
};

export default Loader;
