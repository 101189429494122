import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Description from './Description';
import Test from './Test';
import styled from 'styled-components/macro';
import ScriptId from './ScriptId';
import MainBackground from '../../_sass/img/bg_default@3x.png';

const Script: FC = () => {
  const match = useRouteMatch();
  return (
    <ScriptPageComponent>
      <div className="mainScreen">
        <div className="wrapper">
          <Switch>
            <Route
              exact
              path={`${match.url}/:scriptId/description`}
              component={Description}
            />
            <Route
              exact
              path={`${match.url}/:scriptId/Test`}
              component={Test}
            />
            <Route path={`${match.url}/:scriptId/`} component={ScriptId} />
            <Route render={() => <Redirect to={``} />} />
          </Switch>
        </div>
      </div>
    </ScriptPageComponent>
  );
};

const ScriptPageComponent = styled.div`
  * {
    box-sizing: border-box;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url(${MainBackground});

  .mainScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 360px;
    overflow: hidden;
    width: calc(100% - 16px);
    max-height: 737px;
    height: 90%;
    margin: 8px;
    background-color: white;
    border-radius: 24px;

    .wrapper {
      position: relative;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
    }
  }
`;

export default Script;
