import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Int } from '../../../_types';
import PointsIcon from './PointsIcon';

const Points: FC<{ pointsCount?: Int }> = ({ pointsCount }) => {
  return (
    <PointsComponent>
      <div className="text">количество пыльцы доброты за баг</div>
      <div className="points">
        <span>{pointsCount}</span>
        <PointsIcon color={'#2F80ED'} />
      </div>
    </PointsComponent>
  );
};

const PointsComponent = styled.div`
  width: 40px;
  margin-top: auto;
  padding-top: 24px;
  .points {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #2f80ed;
    span {
      font-weight: bold;
      position: absolute;
      font-size: 14px;
      left: 50%;
      transform: translateX(-35%);
    }
  }
  .text {
    font-family: Geometria, sans-serif;
    width: 144px;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 140%;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 4px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }
`;

export default Points;
