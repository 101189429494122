import { StyledFC } from '../../_shared/types';
import { ReFetch, useApiManual } from '../../_shared/Api';
import React, { Dispatch, useEffect, useState } from 'react';
import { createQuestSystem } from '../../../_api/controller/quest-system';
import ActionDialog from '../../_shared/dialog/ActionDialog';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { QuestSystem } from '../../../_types';

const SystemModal_: StyledFC<{
  setSelected: Dispatch<QuestSystem>;
  resetSelected: () => void;
  reFetchList: ReFetch;
}> = ({ className, setSelected, resetSelected, reFetchList }) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [create, createState] = useApiManual(createQuestSystem);
  const goBack = history.goBack;
  const created = createState.data;
  useEffect(() => {
    if (created) {
      reFetchList();
      setSelected(created);
      goBack();
    }
  }, [goBack, created, setSelected, reFetchList]);
  return (
    <ActionDialog
      className={className}
      header={`Другая ОС`}
      onCancel={() => {
        resetSelected();
        goBack();
      }}
      actionName="Применить"
      action={() => create({ name })}
      actionState={createState}
    >
      <input
        style={{
          width: '-webkit-fill-available',
        }}
        id="name"
        type="text"
        placeholder="Введите название ОС"
        required
        minLength={1}
        defaultValue={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
    </ActionDialog>
  );
};

const SystemDialog = styled(SystemModal_)`
  > .container > .window {
    input {
      padding: 0 1em;
    }
    > .buttons > .action {
      background-color: ${({ theme }) => theme.colors.red};
      box-shadow: 0 4px 16px 0 rgb(235, 87, 87, 0.5);
    }
  }
`;

export default SystemDialog;
