import React, { FC } from 'react';
import './style.scss';
import { useMe } from '../../../_shared';

const StatBlock: FC = () => {
  const me = useMe();
  return (
    <>
      <div className="stat-block">
        <div className="number">{me.countQuestsPass || 0}</div>
        <div className="text">
          выполненных <br />
          квестов
        </div>
        <div className="number">{me.countGoalsAchieved || 0}</div>
        <div className="text">
          достигнутых <br />
          целей
        </div>
      </div>
    </>
  );
};

export default StatBlock;
