import React, { FC, ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState, Me } from '../_types';
import { MeContext } from './_shared';
import Api, { useApi } from './_shared/Api';
import { getUser } from '../_api/controller';

const ProtectedRoute: FC<{
  path: string;
  component: FC;
}> = ({ path, component }) => {
  const Comp = component;
  const isAuth = useSelector<AppState>((state) => !!state.auth.accessToken);
  const maybeMe = useSelector<AppState, Me | undefined>(
    (state) => state.auth.user
  );
  return isAuth && maybeMe ? (
    <WithMe me={maybeMe}>{() => <Route path={path} component={Comp} />}</WithMe>
  ) : (
    <Redirect to="/auth" />
  );
};

export default ProtectedRoute;

const WithMe: FC<{
  me: Me;
  children: () => ReactNode;
}> = ({ me, children }) => {
  const userState = useApi(getUser, me.id);
  return (
    <Api
      result={userState}
      renderData={(user, { reFetch }) => (
        <MeContext.Provider value={{ me: { ...me, ...user }, reFetch }}>
          {children()}
        </MeContext.Provider>
      )}
    />
  );
};
