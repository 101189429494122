import * as api from '../internal';
import {
  ActionHistory,
  CountOfNotReadNotifications,
  Id,
  Notification,
} from '../../_types';

const path = 'notifications';
export const getNotifications = api.getPage<Notification>(path);
export const getNotification = api.getEntity<Notification>(path);
export const deleteNotification = api.deleteEntity(path);

const countPath = `/notifications/not-read/count`;

export const getNotReadNotificationsCount = api.get<
  void,
  CountOfNotReadNotifications
>(countPath)(undefined);

export const getNotificationStory = api.getPage<ActionHistory>(`${path}/story`);

export const setNotificationRead = (id: Id) =>
  api.put<void, void>(`${path}/${id}/is-read`)(undefined);
