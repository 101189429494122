import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../types';

// todo: Use <dialog>
const Modal_: StyledFC<{
  header: string;
  text?: string;
  children: ReactNode;
}> = ({ className, header, text, children }) => {
  return (
    <div className={className}>
      <div className="tint" />
      <div className="container">
        <div className="window">
          <h2 className="header">{header}</h2>
          {text && <h3 className="text">{text}</h3>}
          {children}
        </div>
      </div>
    </div>
  );
};

const Dialog = styled(Modal_)`
  // TODO: Reuse: Tint
  > .tint {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
    background-color: ${({ theme }) => theme.colors.blueGrey};
  }
  > .container {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    //grid-template-columns: 1fr;
    //grid-template-rows: 1fr 1fr 1fr;
    //grid-template:
    //  [header-left] "." 10px [header-right]
    //  [window-left] "window" 10px [window-right]
    //  [buttons-left] "." 10px [buttons-right] ;
    grid-template-areas:
      '.'
      'window'
      '.';
    align-items: center;
    justify-content: center;

    > .window {
      grid-area: window;

      margin: 0 4px;
      border-radius: 32px;
      opacity: 100%;
      background-color: white;
      max-width: 378px;
      width: calc(100vw - 8px);

      display: grid;
      grid-gap: 1em;
      grid-template-columns: 1fr;
      grid-template-rows: 1.5fr 0.5fr 0.5fr;
      align-items: center;
      > * {
        margin-left: 32px;
        margin-right: 32px;
      }
      > .header {
        align-self: end;
        font-family: Geometria-ExtraBold, sans-serif;
        font-size: 22px;
        text-align: center;
      }
      > .text {
        margin-top: 0;
        align-self: start;
        font-family: Geometria, sans-serif;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      > :last-child {
        margin-bottom: 32px;
      }
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  z-index: 20;
`;

export default Dialog;
