import React, { FC, ReactNodeArray } from 'react';
import { Link } from 'react-router-dom';
import { deleteQuest, getQuests } from '../../../_api';
import styled from 'styled-components/macro';
import { Api, ReFetch } from '../../_shared/Api';
import DeleteDialog from '../../_shared/dialog/DeleteDialog';
import ListItem from './ListItem';

import Edit from './Edit';
import { FooterNew } from '../_shared/FooterAction';
import { RouteDelete, RouteEdit, RouteNew, RouteView } from '../_shared/routes';
import createEntityList from '../_shared/createEntityList';
import { Quest } from '../../../_types';
import New from './New';
import View from './View';
import { exportReports } from '../../../_api/controller/report';
import DownloadButton from '../_shared/files/DownloadButton';
import { useMe, useScroll } from '../../_shared';
import TabContent, { StyledTab } from '../../_shared/Tabs/TabContent';
import { isSuperAdmin } from '../../auth/_actions';

const Delete: FC<{ reFetchList?: ReFetch }> = ({ reFetchList }) => (
  <DeleteDialog
    entityTypeName="квест"
    deleteApi={deleteQuest}
    reFetchList={reFetchList}
  />
);

const Links = styled.div<{ children: ReactNodeArray }>`
  display: grid;
  grid-template-columns: repeat(${({ children }) => children.length}, auto);
  grid-template-rows: auto;
  grid-gap: 0.5em;
  align-self: center;
  //width: 100%;
  > a,
  button,
  div {
    font-family: Geometria-Medium, sans-serif;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0 0.5rem;
    border-width: 0;
    border-radius: ${({ theme }) => theme.borderRadius}px;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.h2MainPageWhite};
    text-decoration: none;
    height: 36px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

/** Apply component factory statically (out of render scope) */
const QuestList = createEntityList<Quest>(ListItem);

const Quests_: StyledTab = ({ className, show }) => {
  const me = useMe();
  const { apiState, list, onScrollEnd, reset } = useScroll(getQuests, 10);
  return (
    <TabContent className={className} show={show}>
      <Links>
        {[
          <DownloadButton
            key="report"
            title="Отчёт по квестам"
            api={exportReports}
          />,
          ...(isSuperAdmin(me)
            ? [
                <Link key="balance" to={'balance'}>
                  Баланс
                </Link>,
                <Link key="seasons" to={'seasons'}>
                  Сезоны
                </Link>,
              ]
            : []),
        ]}
      </Links>
      <Api
        result={apiState}
        renderData={(data, { reFetch }) => (
          <>
            <QuestList list={list} onScrollEnd={onScrollEnd} />
            {show && (
              <>
                <FooterNew />
                <RouteEdit>{() => <Edit reFetchList={reFetch} />}</RouteEdit>
                <RouteNew>{() => <New reFetchList={reFetch} />}</RouteNew>
                <RouteView>{() => <View />}</RouteView>
                <RouteDelete>
                  {() => <Delete reFetchList={reset} />}
                </RouteDelete>
              </>
            )}
          </>
        )}
      />
    </TabContent>
  );
};

const Quests = styled(Quests_)`
  display: grid;
  grid-template-rows: 60px auto 72px 0;
  align-self: center;
  height: 100%;
  margin: 0;
  &:first-child {
    align-self: center;
  }
  > * {
    margin: 0 24px;
  }
`;

export default Quests;
