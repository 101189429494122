import { emailRegexp } from '../../../_constants';

export const validateEmail = (e: {
  target: EventTarget & HTMLInputElement;
}): boolean => {
  if (!e.target.value.match(emailRegexp)) {
    e.target.setCustomValidity('Введите e-mail');
    return false;
  } else {
    e.target.setCustomValidity('');
    return true;
  }
};
