import { Id } from '../_types';
import { delete_, get, post, put } from './rest';
import { CancelToken } from 'axios';

export type GetEntity<Entity> = (
  id: Id
) => (token?: CancelToken) => Promise<Entity>;
export const getEntity: <Entity>(
  path: string,
  map?: (res: Entity) => Entity
) => GetEntity<Entity> = (path, map) => (id: Id) => {
  return get(`${path}/${id}`, map)(undefined);
};

export type CreateEntity<Arg, Entity> = (
  arg: Arg
) => (token?: CancelToken) => Promise<Entity>;
export const createEntity: <Arg, Entity>(
  path: string,
  map?: (res: Entity) => Entity
) => CreateEntity<Arg, Entity> = (path, map) => (arg) =>
  post(`${path}`, map)(arg);

export type UpdateArg<Value> = { id: Id; value: Value };
export type UpdateEntity<Value, Entity> = (
  arg: UpdateArg<Value>
) => (token?: CancelToken) => Promise<Entity>;
export const updateEntity: <Value, Entity>(
  path: string,
  map?: (res: Entity) => Entity
) => UpdateEntity<Value, Entity> = (path, map) => (arg) =>
  put(`${path}/${arg.id}`, map)(arg.value);

export const deleteEntity = <Res = void>(
  path: string,
  map?: (res: Res) => Res
) => (id: Id) => {
  return delete_<Res>(`${path}/${id}`, map);
};

// export const createEntityWithoutId = <Arg, Entity>(
//   path: string,
//   map?: (res: Entity) => Entity
// ) => (arg: Arg) => {
//   return post<Arg, Entity>(`${path}`, map)(arg);
// };
