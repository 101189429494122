import React, { useCallback, useState } from 'react';
import { StyledFC } from '../../../_shared/types';
import { ReFetch } from '../../../_shared/Api';
import { createLevel, CreateLevelArg } from '../../../../_api/controller';
import FormInputImage from '../../_shared/form/FormInputImage';
import {
  FormInputName,
  FormInputPointsCount,
} from '../../_shared/form/FormInput';
import createPageNew from '../../_shared/page/createPageNew';
import { Level } from '../../../../_types';
import styled from 'styled-components/macro';
import ActionForm from '../../_shared/form/ActionForm';

const CustomForm = styled(ActionForm)`
  > .scroll-container > .scroll {
    grid-template-rows: 80px 60px 60px;
  }
`;

/** Apply component factory statically (out of render scope) */
const PageNewLevel = createPageNew<CreateLevelArg, Level>(
  createLevel,
  'уровень',
  CustomForm
);

const New: StyledFC<{ reFetchList: ReFetch }> = ({
  className,
  reFetchList,
}) => {
  const [name, setName] = useState<string>();
  const [pointsCount, setPointsCount] = useState<number>();
  const [image, setImage] = useState<string>();
  const validate = useCallback(
    () =>
      name !== undefined && pointsCount !== undefined && image !== undefined
        ? { name, pointsCount, image }
        : undefined,
    [name, pointsCount, image]
  );
  return (
    <PageNewLevel
      className={className}
      validate={validate}
      reFetch={reFetchList}
    >
      <FormInputImage required image={image} setImage={setImage} />
      <FormInputName state={name} setState={setName} />
      <FormInputPointsCount state={pointsCount} setState={setPointsCount} />
    </PageNewLevel>
  );
};

export default New;
