import React from 'react';
import { StyledFC } from '../types';
import Dialog from './Dialog';
import styled from 'styled-components/macro';
import { ApiStateManual } from '../Api';

const ActionModal_: StyledFC<{
  header: string;
  text?: string;
  onCancel: () => void;
  actionName: string;
  action: () => void;
  actionState: ApiStateManual<any>;
}> = ({
  className,
  header,
  text,
  actionName,
  onCancel,
  action,
  actionState,
  children,
}) => (
  <Dialog className={className} header={header} text={text}>
    {children}
    <div className="buttons">
      <button
        className="cancel"
        onClick={() => {
          actionState.cancel('Canceled by user');
          onCancel();
        }}
      >
        Отменить
      </button>
      <button
        className="action"
        disabled={actionState.isLoading}
        onClick={() => {
          action();
        }}
      >
        {actionName}
      </button>
    </div>
  </Dialog>
);

const ActionDialog = styled(ActionModal_)`
  > .container > .window > .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    grid-column-gap: 1em;

    > .cancel,
    .action {
      padding: 0.65em 1em;
      font-family: Geometria-Bold, sans-serif;
      font-size: 16px;
      border-radius: 12px;
      border-width: 0;
      color: white;
      background-color: ${({ theme }) => theme.colors.blueGrey};

      box-shadow: 0 4px 16px 0 rgb(53, 50, 59, 0.5);

      &:disabled {
        color: ${({ theme }) => theme.colors.grey};
        background-color: ${({ theme }) => theme.colors.greySpan};
      }
    }
  }
`;

export default ActionDialog;
