import * as api from '../internal';
import { Rating, RatingTotalPoints } from '../../_types';

const path = 'users/rating';
export const getCommonRating = api.getPage<Rating>(`${path}/common`);
export const getTeamRating = api.getPage<Rating>(`${path}/team`);

const teamTotalPointsPath = 'users/rating/team/total-points';
const commonTotalPointsPath = 'users/rating/common/total-points';

export const getTeamRatingTotalPoints = api.get<void, RatingTotalPoints>(
  teamTotalPointsPath
)(undefined);

export const getCommonRatingTotalPoints = api.get<void, RatingTotalPoints>(
  commonTotalPointsPath
)(undefined);
