import React, { FC, ReactNode, useContext, useEffect } from 'react';
import Api, { useApi, useApiWoParams } from '../../_shared/Api';
import { getAchievements, getUserAchievements } from '../../../_api/controller';
import { SockContext } from '../../App/SockContext';
import { Achievement } from '../../../_types';
import { useMe } from '../../_shared';

/** Достижения пользователя плюс оставшиеся закрытые */
export const mergeAchievements = (
  achievements: Achievement[],
  userAchievements: Achievement[]
): Achievement[] => {
  const head = [...userAchievements].sort(
    (a, b) => b.date.getTime() - a.date.getTime()
  );
  const userAchIds = userAchievements.map((a) => a.id);
  const tail = achievements.filter((a) => !userAchIds.includes(a.id));
  return [...head, ...tail];
};

const MergeAchievemnets: FC<{
  children: (achievements: Achievement[]) => ReactNode;
}> = ({ children }) => {
  const me = useMe();
  const achievementsState = useApiWoParams(getAchievements);
  const myAchievementsState = useApi(getUserAchievements, me.id);
  const reFetchMyAchievements = myAchievementsState.reFetch;

  /** Sockets */
  const fromContext = useContext(SockContext);
  const profileUpdateValue = fromContext.profileUpdateValue;
  useEffect(() => {
    // здесь необходимо стянуть заново обновленные данные
    if (profileUpdateValue !== undefined) {
      console.log('reFetch my achievements');
      reFetchMyAchievements();
    }
  }, [profileUpdateValue, reFetchMyAchievements]);

  return (
    <Api
      result={achievementsState}
      renderData={(achievements) => (
        <Api
          result={myAchievementsState}
          renderData={(myAchievements) => (
            <>{children(mergeAchievements(achievements, myAchievements))}</>
          )}
        />
      )}
    />
  );
};

export default MergeAchievemnets;
