import React, {
  FC,
  FormEvent,
  MouseEvent,
  RefObject,
  useState,
  useEffect,
} from 'react';
import { Redirect, Link, useRouteMatch, useLocation } from 'react-router-dom';
import {
  authActions,
  RestoreAction,
  SetSignInData,
  SetSignInDataPayload,
  SignInAction,
} from '../_actions';
import { useDispatch, useSelector } from 'react-redux';

import InputRow from '../../_shared/InputRow';
import InputBar from '../_shared/InputBar';
import './style.scss';
import { Dispatch } from 'redux';
import { AppState, AuthState } from '../../../_types';
import MessageToast, { useToast } from '../_shared/MessageToast';
import { validateEmail } from '../_validation';
import axios, { CancelToken } from 'axios';
import { useCancelSource } from '../../_shared';
import QaIcon from '../../_shared/QaIcon';

type LocationProps = {
  email: string | undefined;
};

const RestoreForm: FC = () => {
  const match = useRouteMatch();

  const { email: signinEmail }: LocationProps = (useLocation()?.state as {
    email: string;
  }) || { email: undefined };

  const appState = useSelector<AppState, AuthState>((state) => state.auth);
  const { email, password } = appState;

  const [currentEmail, setCurrentEmail] = useState(signinEmail);
  const [mailSent, setMailSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const currentURL = match.url.split('/')[match.url.split('/').length - 1];
  const signinURL = match.url.replace(currentURL, 'signin');
  const signupURL = match.url.replace(currentURL, 'signup');
  const [toastData, pushMsg] = useToast();
  const cancelSource = useCancelSource();

  const setDataDispatch = useDispatch<Dispatch<SetSignInData>>();
  const setData = (data: SetSignInDataPayload) =>
    authActions.setData(data)(setDataDispatch);

  const signInDispatch = useDispatch<Dispatch<SignInAction>>();
  const signIn = (email: string, password: string, token: CancelToken) =>
    authActions.signIn(email, password, token)(signInDispatch);

  const restoreDispatch = useDispatch<Dispatch<RestoreAction>>();

  const setEmail = (
    event: FormEvent<HTMLFormElement> | MouseEvent,
    ref: RefObject<HTMLInputElement>
  ) => {
    if (ref.current && validateEmail({ target: ref.current })) {
      setData({ email: ref.current?.value });
    }
    event.preventDefault();
  };

  useEffect(() => {
    const restore = (email: string, token: CancelToken) =>
      authActions.restore(email, token)(restoreDispatch);

    if (email && !mailSent)
      restore(email, cancelSource.token)
        .then(() => setMailSent(true))
        .catch((error) => {
          if (!axios.isCancel(error)) {
            const status = error.response ? error.response.status : '000';
            const message = error.response?.data.message;
            setIsError(true);
            pushMsg(status, message);
          }
        });
  }, [mailSent, email, cancelSource.token, pushMsg, restoreDispatch]);

  const setPassword = () => {
    signIn(email, password, cancelSource.token).catch((error) => {
      if (!axios.isCancel(error)) {
        const status = error.response ? error.response.status : '000';
        const message = error.response?.data.message;
        setData({ password: '' });
        setIsError(true);
        pushMsg(status, message);
      }
    });
  };

  const isAuth = !!useSelector<AppState, AuthState>((state) => state.auth)
    .accessToken;

  const validate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setCurrentEmail(email);
  };

  const passwordToInput = appState.password;

  return isAuth ? (
    <Redirect to="/main" />
  ) : (
    <div className="form">
      <div className="content">
        {email ? (
          <>
            <span className="icon">
              <QaIcon name={'login-pass'} />
              <QaIcon name={'login-contour'} />
            </span>
            <div className="wrapper pass">
              <h2>Введи код-пароль, отправленный на e-mail</h2>
              <p>{email}</p>
              <InputRow
                sendAction={setPassword}
                autoFocus={true}
                isError={isError}
                password={passwordToInput}
                setPassword={setData}
                type={'text'}
              />
              <span>
                <MessageToast data={toastData} />
              </span>
            </div>
          </>
        ) : (
          <>
            <span className="icon">
              <QaIcon name={'login-restore'} />
              <QaIcon name={'login-contour'} />
            </span>
            <div className="wrapper login">
              <h2>Введи e-mail</h2>
              <p>Мы вышлем новый пароль на твою почту</p>
              <InputBar
                value={currentEmail}
                onChange={validate}
                sendAction={setEmail}
              />
              <span>
                <MessageToast data={toastData} />
              </span>
            </div>
          </>
        )}
        <div
          className="footer"
          style={{ justifyContent: email ? 'space-between' : 'flex-end' }}
        >
          {email && (
            <Link
              to={{
                pathname: signupURL,
              }}
              onClick={() => setData({ email: '' })}
            >
              Я без аккаунта
            </Link>
          )}
          <Link
            to={{
              pathname: signinURL,
            }}
            onClick={() => setData({ email: !email ? signinEmail : '' })}
          >
            {!email ? 'Отменить' : 'У меня есть аккаунт'}
          </Link>
        </div>
      </div>
      <div className="backplate"></div>
    </div>
  );
};

export default RestoreForm;
