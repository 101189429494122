import React, { useCallback, useState } from 'react';
import { StyledFC } from '../../_shared/types';
import createPageNew from '../_shared/page/createPageNew';
import { createSeason, CreateSeasonArg } from '../../../_api/controller';
import { Season } from '../../../_types';
import { ReFetch } from '../../_shared/Api';
import {
  FormInputEndDate,
  FormInputName,
  FormInputStartDate,
  FormInputString,
} from '../_shared/form/FormInput';
import styled from 'styled-components/macro';
import ActionForm from '../_shared/form/ActionForm';

const CustomForm = styled(ActionForm)`
  > .scroll-container > .scroll {
    grid-template-rows: 60px 60px 60px 60px;
  }
`;

/** Apply component factory statically (out of render scope) */
const PageNewLevel = createPageNew<CreateSeasonArg, Season>(
  createSeason,
  'сезон',
  CustomForm
);

const New: StyledFC<{ reFetchList: ReFetch }> = ({
  className,
  reFetchList,
}) => {
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const validate = useCallback(
    () =>
      name != null &&
      description != null &&
      startDate != null &&
      endDate != null
        ? { name, description, startDate, endDate }
        : undefined,
    [name, description, startDate, endDate]
  );
  return (
    <PageNewLevel
      className={className}
      validate={validate}
      reFetch={reFetchList}
    >
      <FormInputName state={name} setState={setName} />
      <FormInputString
        label={`Цель сезона`}
        id="description"
        state={description}
        setState={setDescription}
      />
      <FormInputStartDate
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
      />
      <FormInputEndDate
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </PageNewLevel>
  );
};

export default New;
