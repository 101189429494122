import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../_shared/types';
import { useApiManualVoidWoParams } from '../../../_shared/Api';
import ApiManual from '../../../_shared/ApiManual';
import { CancelToken } from 'axios';

const DownloadButton_: StyledFC<{
  required?: boolean;
  api: (token?: CancelToken) => Promise<'done'>;
  title: string;
}> = ({ className, title, api }) => {
  const [download, downloadState] = useApiManualVoidWoParams(api);
  // console.log({ downloadState });
  return (
    <button
      className={className + ' action'}
      onClick={() => download()}
      disabled={downloadState.isLoading}
    >
      <ApiManual
        result={downloadState}
        renderInit={({ isLoading }) => {
          // console.log({ isLoading });
          return showTitle(isLoading, title);
        }}
        renderData={(_, { isLoading }) => showTitle(isLoading, title)}
      />
    </button>
  );
};

const showTitle = (isLoading: boolean, title: string) =>
  isLoading ? <>Загрузка...</> : <>{title}</>;

const DownloadButton = styled(DownloadButton_)``;

export default DownloadButton;
