import React, { FC, useCallback, useState } from 'react';
import './style.scss';
import './responsive.scss';
import BadgeItem from '../../_shared/BadgeItem';
import QaIcon from '../../../_shared/QaIcon';
import { Link, useRouteMatch } from 'react-router-dom';
import { Achievement } from '../../../../_types';

const LastAchievements: FC<{
  achievements: Achievement[];
}> = ({ achievements }) => {
  const match = useRouteMatch();

  /** Адаптация количества достижений под размер экрана */
  const startWidth = document.body.clientWidth;
  //console.log('startW:', startWidth);
  const initialQuantity = startWidth < 321 ? 3 : 7;
  const [achQuantity, setAchQuantity] = useState(initialQuantity);

  const onReSize = (window.onresize = () => {
    const changedWidth = document.body.clientWidth;
    //console.log('changedW:', changedWidth);
    setAchQuantity(changedWidth < 321 ? 3 : 7);
  });

  useCallback(() => {
    onReSize();
  }, [onReSize]);

  return (
    <div className="last-achievements">
      <h3> Достижения </h3>
      <div className="badge-gallery">
        {achievements.slice(0, achQuantity).map((a: Achievement, i: number) => (
          <Link key={i} to={`${match.url}/achievements/${a.id}`}>
            <BadgeItem achievement={a} />
          </Link>
        ))}
        <Link className="right-button" to={`${match.url}/achievements`}>
          <QaIcon name={'back'} />
        </Link>
      </div>
    </div>
  );
};

export default LastAchievements;
