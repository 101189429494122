import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import { useApiManual } from '../../_shared/Api';
import QaIcon from '../../_shared/QaIcon';
import { importReport } from '../../../_api/controller/report';
import { FileNone, LoadingIcon } from '../_shared/files';

const Report_: StyledFC<{
  status: 'init' | 'loading' | 'failure' | 'success';
}> = ({ className, status }) => {
  return (
    <div className={`${className} ${status}`}>
      <FileNone>
        {status === 'loading' ? (
          <LoadingIcon color="orange" />
        ) : (
          <QaIcon name="up" />
        )}
      </FileNone>
      <div>
        {status === 'init' ? (
          <>Загрузить отчёт, чтобы игроки получили дополнительные очки</>
        ) : status === 'loading' ? (
          <>Загружаем отчёт...</>
        ) : status === 'failure' ? (
          <>
            Возникли проблемы при загрузке отчёта
            <br />
            Попробуйте ещё раз
          </>
        ) : (
          <>
            Отчёт успешно загружен. <br />
            Его можно обновить, загрузив повторно
          </>
        )}
      </div>
    </div>
  );
};

const Report = styled(Report_)`
  display: grid;
  grid-template-columns: 64px auto;
  align-items: center;  
  
  &.init {
    color: ${({ theme }) => theme.colors.grey};
  }
  &.loading {
    color: ${({ theme }) => theme.colors.yellow};   
  }
  &.success {
    color: ${({ theme }) => theme.colors.green};
  }  
  &.failure {
    color: ${({ theme }) => theme.colors.red};
  }
  > :nth-child(2) {
    padding-left: 1em;
    font-size: 12px;
    // color: ${({ theme }) => theme.colors.grey};
  }
`;

// TODO: Show Loader on isLoading
// TODO: Restrict image file type
const ReportInput_: StyledFC<{
  required?: boolean;
}> = ({ className, required }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [upload, uploadState] = useApiManual(importReport);

  console.log({ uploadState });

  const status =
    uploadState.error !== undefined
      ? 'failure'
      : uploadState.isLoading
      ? 'loading'
      : uploadState.countOfCompletions > 0
      ? 'success'
      : 'init';
  console.log({ status });
  return (
    <div className={className}>
      <label htmlFor="badge">
        <Report status={status} />
      </label>
      <input
        disabled={uploadState.isLoading}
        required={required}
        id="badge"
        accept={[
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].join(',')}
        style={{ display: 'none', border: 'solid white 1px' }}
        ref={fileInput}
        type="file"
        onInput={() => {
          const current = fileInput.current;
          if (current && current.files && current.files[0]) {
            const data = new FormData();
            data.append('file', current.files[0]);
            upload(data);
          }
        }}
      />
    </div>
  );
};

const ReportInput = styled(ReportInput_)``;

export default ReportInput;
