import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import createEntityPage from '../_shared/page/createEntityPage';
import {
  getQuest,
  updateQuestWithSteps,
  UpdateQuestWithStepsArg,
} from '../../../_api/controller';
import { Quest, QuestStep } from '../../../_types';
import Api, { ReFetch, useApiWoParams } from '../../_shared/Api';
import {
  FormInputEndDate,
  FormInputName,
  FormInputStartDate,
  FormInputString,
} from '../_shared/form/FormInput';
import FormTextArea from '../_shared/form/FormTextArea';
import TypeInput from './TypeInput';
import createUpdateForm from '../_shared/form/createUpdateForm';
import StepsEditor from './StepsEditor';
import SystemEditor from './SystemEditor';
import { Route, useRouteMatch } from 'react-router-dom';
import SystemDialog from './SystemDialog';
import { getQuestSystems } from '../../../_api/controller/quest-system';
import isEqual from 'lodash/isEqual';
import PageEdit from '../_shared/page/PageEdit';

export const sortQuestSteps = (steps: QuestStep[]) =>
  [...steps].sort((a, b) => a.number - b.number);

/** Apply component factory statically (out of render scope) */
const UpdateForm = createUpdateForm(updateQuestWithSteps);

const EditForm_: StyledFC<{
  entityTypeName: string;
  entity: Quest;
  reFetchList: ReFetch;
  children: {};
}> = ({ className, entityTypeName, entity, reFetchList }) => {
  const match = useRouteMatch();

  const isStarted = entity.startDate.getTime() < Date.now();

  const systemsState = useApiWoParams(getQuestSystems);

  const [name, setName] = useState(entity.name);
  const [description, setDescription] = useState(entity.description);
  const [link, setLink] = useState(entity.link);
  const [questSystem, setQuestSystem] = useState(entity.questSystem);
  const [startDate, setStartDate] = useState(entity.startDate);
  const [endDate, setEndDate] = useState(entity.endDate);
  const [questType, setQuestType] = useState(entity.questType);
  const [fullDescription, setFullDescription] = useState(
    entity.fullDescription
  );
  const [stepDescriptions, setStepDescriptions] = useState(
    sortQuestSteps(entity.questSteps).map((s) => s.description)
  );

  const isChanged = useCallback(
    () => () =>
      entity &&
      (name !== entity.name ||
        description !== entity.description ||
        link !== entity.link ||
        questSystem.id !== entity.questSystem.id ||
        startDate !== entity.startDate ||
        endDate !== entity.endDate ||
        questType.id !== entity.questType.id ||
        fullDescription !== entity.fullDescription ||
        !isEqual(
          stepDescriptions,
          entity.questSteps.map((s) => s.description)
        )),
    [
      entity,
      name,
      description,
      link,
      questSystem.id,
      startDate,
      endDate,
      questType.id,
      fullDescription,
      stepDescriptions,
    ]
  );
  const validate = useCallback(
    (): UpdateQuestWithStepsArg | undefined =>
      isChanged() &&
      // TODO: Really need check != null?
      name != null &&
      description != null &&
      link != null &&
      questSystem != null &&
      startDate != null &&
      endDate != null &&
      questType != null &&
      fullDescription != null
        ? {
            quest: {
              name,
              description,
              fullDescription,
              link,
              questSystemId: questSystem.id,
              startDate,
              endDate,
              questTypeId: questType.id,
            },
            stepDescriptions: questType.withSteps ? stepDescriptions : [],
          }
        : undefined,
    [
      isChanged,
      name,
      description,
      link,
      questSystem,
      startDate,
      endDate,
      questType,
      fullDescription,
      stepDescriptions,
    ]
  );
  return (
    <Api
      result={systemsState}
      renderData={(systems, { reFetch: reFetchSystems }) => (
        <PageEdit className={className} entityTypeName={entityTypeName}>
          <UpdateForm
            entity={entity}
            validate={validate}
            reFetchList={reFetchList}
          >
            <>
              <FormInputName state={name} setState={setName} />
              <FormTextArea
                label={`Краткое описание`}
                id="description"
                state={description}
                setState={setDescription}
              />
              <FormInputString
                label={`Ссылка на приложение`}
                id="link"
                state={link}
                setState={setLink}
              />
              <SystemEditor
                systems={systems}
                state={questSystem}
                setState={setQuestSystem}
              />
              <FormInputStartDate
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                disabled={isStarted}
              />
              <FormInputEndDate
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <TypeInput
                state={questType}
                setState={setQuestType}
                disabled={isStarted}
              />
              <FormTextArea
                label={`Полное описание`}
                id="fullDescription"
                state={fullDescription}
                setState={setFullDescription}
              />
              {questType.withSteps && (
                <StepsEditor
                  steps={stepDescriptions}
                  setSteps={(ds) => {
                    console.log('setSteps', ds);
                    setStepDescriptions(ds);
                  }}
                  isAddRemoveEnabled={!isStarted}
                />
              )}
              <Route
                path={`${match.url}/newSystem`}
                render={() => (
                  <SystemDialog
                    setSelected={setQuestSystem}
                    resetSelected={() => setQuestSystem(questSystem)}
                    reFetchList={reFetchSystems}
                  />
                )}
              />
            </>
          </UpdateForm>
        </PageEdit>
      )}
    />
  );
};

const EditForm = styled(EditForm_)`
  > .scroll-container > .scroll {
    grid-template-rows: 60px 120px 60px 60px 65px 65px 80px auto;
  }
`;

/** Apply component factory statically (out of render scope) */
const EditWoChildren = createEntityPage(getQuest, 'квест', EditForm);

const Edit: StyledFC<{
  reFetchList: ReFetch;
}> = (props) => <EditWoChildren {...props}>{}</EditWoChildren>;

export default Edit;
