import React, { FC } from 'react';
import styled from 'styled-components/macro';

const Header_: FC<{ className?: string; title: string }> = ({
  className,
  title,
}) => (
  <div className={className}>
    <h2>{title}</h2>
  </div>
);

const Header = styled(Header_)`
  //grid-area: header;
  //margin: 8px 16px;
  h2 {
    font-family: 'Geometria-ExtraBold', sans-serif;
    font-size: 22px;
    line-height: 30px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin: 0;
  }
`;

export default Header;
