export type Int = number;
export type Id = Int;

export type Identity = <T>(x: T) => T;
export const identity: Identity = (x) => x;

export type AppState = { auth: AuthState };

export type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
  roles: Role[];
  agreement: boolean;
  email: string;
  password: string;
  user?: Me;
};

export enum RoleName {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_USER = 'ROLE_USER',
}

export type Role = { name: RoleName };

export type Me = User & { isAdmin: boolean };

export type Entity = {
  id: Id;
  createdBy: string;
  createdDate: Date;
  modifiedBy: string;
  modifiedDate: Date;
};

export type User = Entity & {
  name: string | null;
  email: string;
  avatar: string;
  points: Int;
  countGoalsAchieved: Int;
  countQuestsPass: Int;
  team: Team;
  roles: Role[];
  isBlocked: boolean;
};

export type Team = Entity & {
  name: string;
  color: string;
};

export type Quest = Entity & {
  name: string;
  description: string;
  fullDescription: string;
  link: string;
  userCountQuestPass: Int;
  startDate: Date;
  endDate: Date;
  season: Season;
  user: User;
  questSystem: QuestSystem;
  questType: QuestType;
  questSteps: QuestStep[];
};

export type QuestSystem = Entity & {
  name: string;
};

export type QuestType = Entity & {
  name: string;
  color: string;
  image: string;
  points: Int;
  pointsApproved: Int;
  withSteps: boolean;
};

export type QuestStep = Entity & {
  questId: Id;
  number: Int;
  description: string;
};

export type Level = Entity & {
  name: string;
  image: string;
  pointsCount: Int;
};

export type Season = Entity & {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  seasonGoalLevels: SeasonGoalLevel[];
};

export type SeasonGoalLevel = Entity & {
  name: string;
  pointsCount: Int;
  image: string;
};

export type Achievement = Entity & {
  description: string;
  id: Id;
  image: string;
  name: string;
  date: Date;
};

export type Notification = Entity & {
  id: number;
  isReaded: boolean;
  eventType: string;
  date: string;
  quest: Quest;
};

export type Rating = Entity & {
  points: number;
  pointsTotal: number;
  position: number;
  userId: number;
  username: string;
  avatar: string;
  teamName: string;
  color: string;
};

export type sockNotification = {
  eventType: string;
  entityType: string;
  points?: number;
  achievementImage?: string;
  achievementDescription?: string;
};

export type ActionHistory = Entity & {
  eventType: string;
  points: Int;
  quest: {
    createdDate: Date;
    name: string;
    questType: { pointsApproved: Int };
  };
};

export type CountOfNotReadNotifications = {
  count: number;
};

export type RatingTotalPoints = {
  points: number;
};
