import styled from 'styled-components/macro';
import { StyledFC } from '../types';

export type StyledTab<Props = {}> = StyledFC<Props & { show: boolean }>;

const TabContent = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? '' : 'none')} !important;
`;

export default TabContent;
