import { StyledFC } from '../_shared/types';
import styled from 'styled-components/macro';
import ListItem from './ListItem';
import React from 'react';
import { fileUrl } from '../../_api/controller/file';
import { getLevelByScore } from './ratingFunctions';
import { useMe, useScroll } from '../_shared';
import Api from '../_shared/Api';
import { Rating } from '../../_types';
import { GetPage } from '../../_api';
import TabContent from '../_shared/Tabs/TabContent';

const RatingList_: StyledFC<{
  show: boolean;
  api: GetPage<Rating>;
  levelImages: string[];
  levelBoundaries: number[];
}> = ({ className, show, api, levelImages, levelBoundaries }) => {
  const me = useMe();
  const { apiState, list, onScroll } = useScroll<Rating>(api, 15);
  const getIcon = (points: number) => {
    return fileUrl(levelImages[getLevelByScore(levelBoundaries, points)]);
  };
  return (
    <Api
      result={apiState}
      renderData={() => (
        <TabContent className={className} show={show} onScroll={onScroll}>
          {list.map((el: Rating, index: number) => {
            return (
              <ListItem
                key={el.userId}
                avatar={el.avatar}
                className="listItem"
                color={el.color}
                position={index + 1}
                name={el.username}
                score={el.points}
                isMyRating={me.id === el.userId}
                levelIcon={getIcon(el.pointsTotal)}
              />
            );
          })}
        </TabContent>
      )}
    />
  );
};

const RatingList = styled(RatingList_)`
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  height: 100%;
  margin-top: 20px !important;
  .listItem {
    width: 100%;
    height: 48px;
    margin-top: 10px;
  }
`;

export default RatingList;
