import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { deleteSeason, getSeasons } from '../../../_api';
import { Api, ReFetch, useApiWoParams } from '../../_shared/Api';
import Page from '../_shared/page/Page';
import { StyledFC } from '../../_shared/types';
import DeleteDialog from '../../_shared/dialog/DeleteDialog';
import ListItem from './ListItem';
import New from './New';
import Edit from './Edit';
import { FooterNew } from '../_shared/FooterAction';
import { RouteDelete, RouteEdit, RouteNew } from '../_shared/routes';
import createEntityList from '../_shared/createEntityList';
import { Season } from '../../../_types';

const Delete: FC<{ reFetch?: ReFetch }> = ({ reFetch }) => (
  <DeleteDialog
    entityTypeName="сезон"
    deleteApi={deleteSeason}
    reFetchList={reFetch}
  />
);

/** Apply component factory statically (out of render scope) */
const SeasonList = createEntityList<Season>(ListItem);

const Seasons_: StyledFC = ({ className }) => {
  const state = useApiWoParams(getSeasons);
  return (
    <div className={className}>
      <Api
        result={state}
        renderData={(list, { reFetch }) => (
          <>
            <SeasonList list={list} />
            <FooterNew />
            <RouteEdit>{() => <Edit reFetchList={reFetch} />}</RouteEdit>
            <RouteNew>{() => <New reFetchList={reFetch} />}</RouteNew>
            <RouteDelete>{() => <Delete reFetch={reFetch} />}</RouteDelete>
          </>
        )}
      />
    </div>
  );
};

const Seasons = styled(Seasons_)`
  display: grid;
  grid-template-rows: auto 72px 0;
  align-self: center;
  height: 100%;
  &:first-child {
    align-self: center;
  }
  > * {
    margin: 0 24px;
  }
`;

const SeasonsPage: FC = () => {
  // TODO: Avoid extra <div/>
  return (
    <Page title="Сезоны">
      <div />
      <Seasons />
    </Page>
  );
};

export default SeasonsPage;
