import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useGoUp, useMe } from '../../../_shared';
import { useApiManual } from '../../../_shared/Api';
import { verifyUserEmail } from '../../../../_api/controller';
import { useDispatch, useStore } from 'react-redux';
import './style.scss';
import { Dispatch } from 'redux';
import {
  authActions,
  SetSignInDataPayload,
  SuccessAction,
} from '../../../auth/_actions';
//import { useHistory } from 'react-router-dom';

const BLANK = '\u200B';
const CELLS_COUNT = 6;

const ApproveDialog: FC<{
  setOldEmail: React.Dispatch<React.SetStateAction<string>>;
  setNewEmail: React.Dispatch<React.SetStateAction<string>>;
  newEmail: string;
}> = ({ setOldEmail, setNewEmail, newEmail }) => {
  const me = useMe();
  const userId = useMe().id;
  const store = useStore();

  /** Dispatch tokens by Redux */

  const setDispatchToken = useDispatch<
    Dispatch<SuccessAction<'SET_SIGN_IN_DATA', SetSignInDataPayload>>
  >();

  const checkAndUpdateToken = useCallback(
    (data: { token: string; refreshToken: string }) => {
      const storageString = localStorage.getItem('auth');
      if (storageString !== null) {
        const json = JSON.parse(storageString) as {
          accessToken: string;
          refreshToken: string;
        };
        json.accessToken = data.token;
        json.refreshToken = data.refreshToken;
        localStorage.setItem('auth', JSON.stringify(json));

        authActions.setData({
          accessToken: data.token,
          refreshToken: data.refreshToken,
        })(setDispatchToken);
      } else throw new Error('Ошибка обновления токенов');
    },
    [setDispatchToken]
  );

  const [updatePassEmail, updateStatePassEmail] = useApiManual(verifyUserEmail);
  const updateData = updateStatePassEmail.data;

  /** PASSWORD */
  const [password, setPassword] = useState('');

  const postPassToVerify = (newPassword: string) => {
    //console.log(newPassword);
    updatePassEmail({
      userId: userId,
      password: newPassword,
    });
  };

  /** dispatch email */

  const updateDone =
    updateStatePassEmail.error === undefined &&
    updateStatePassEmail.countOfCompletions > 0;

  useEffect(() => {
    if (updateData && updateDone) {
      window.history.go(-2);
      checkAndUpdateToken(updateData);
      setOldEmail(newEmail);
      console.log(store.getState());
    }
  }, [
    updateData,
    updateDone,
    newEmail,
    store,
    setOldEmail,
    checkAndUpdateToken,
  ]);

  const onChangeInputs = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    // crop selected character from password input
    let res: string[] = password?.substr(0, i).split('');
    // crop blank character from updated input
    const updated: string[] = e.target.value
      .toUpperCase()
      .split('')
      .filter((l: string) => l !== BLANK);
    if (updated.length) {
      if (updated[0] !== ' ') {
        res?.push(updated[0]);
        focusNext(i);
      }
    } else {
      focusPrev(i);
    }
    const newPassword = res.join('').substr(0, CELLS_COUNT);
    setPassword(newPassword);
    if (i === 5 && newPassword.length === CELLS_COUNT) {
      postPassToVerify(newPassword);
    }
  };

  /** FOCUS */
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const focusPrev = (i: number) => {
    if (i > 0) {
      inputsRef.current[i - 1].focus();
      return;
    }
    inputsRef.current[i].focus();
  };
  const focusCurrent = (i: number) => {
    const cell = password?.split('')[i - 1];
    if (i > 0 && !cell) {
      focusCurrent(i - 1);
      return;
    }
    inputsRef.current[i].focus();
  };
  const focusNext = (i: number) => {
    if (i < CELLS_COUNT - 1) {
      inputsRef.current[i + 1].focus();
      return;
    }
    inputsRef.current[i].focus();
  };

  const goUp = useGoUp();

  const onPastePass = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasted = e.clipboardData.getData('Text');
    const pastedPass = pasted.toUpperCase().substr(0, CELLS_COUNT);
    if (pastedPass.length === CELLS_COUNT) {
      setPassword(pastedPass);
      postPassToVerify(pastedPass);
    }
  };

  return (
    <div className="wrap-modal">
      <div className="modal-bg">
        <div className="modal-window">
          <div className="modal-content">
            <h3>Введите код-пароль, который мы выслали</h3>
            <form className="input-row-approve">
              {Array(CELLS_COUNT)
                .fill(BLANK)
                .map((_, i) => (
                  <input
                    type="password"
                    key={i}
                    ref={(el) => {
                      if (el !== null) inputsRef.current[i] = el;
                    }}
                    value={password[i] ? password[i] : ''}
                    maxLength={1}
                    /*disabled={password?.length === CELLS_COUNT}*/
                    autoCapitalize="characters"
                    autoFocus={i === 0 ? true : false}
                    onClick={(e) => focusCurrent(i)}
                    onChange={(e) => onChangeInputs(e, i)}
                    //placeholder="&#9679;"
                    onPaste={(e) => onPastePass(e)}
                  />
                ))}
            </form>
            <div className="buttons">
              <button
                className="black"
                onClick={() => {
                  setNewEmail(me.email);
                  goUp(2);
                }}
              >
                Отменить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveDialog;
