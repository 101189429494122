import React, { FC } from 'react';
import './style.scss';
import QaIcon from '../../../_shared/QaIcon';
import { dateToString } from '../../../notifications/_shared/helpers';
import { Int } from '../../../../_types';

const HistoryItem: FC<{
  createdDate: Date;
  points: Int;
  name: string;
  eventType: string;
}> = ({ createdDate, points, name, eventType }) => {
  let statusIcon;
  let status;
  switch (eventType) {
    case 'QUEST_COMPLETED_USER':
      statusIcon = <QaIcon name={'timer'} />;
      status = 'Проверка бага';
      break;
    case 'QUEST_REJECTED_ADMIN':
      statusIcon = <QaIcon name={'rejected'} />;
      status = 'Баг отклонен';
      break;
    case 'QUEST_CONFIRMED_ADMIN':
      statusIcon = (
        <div className="points-block">
          <span className="points">{points}</span>
          <span className="icon">
            <QaIcon name={'points-plus'} />
            <QaIcon name={'points-circle'} />
          </span>
        </div>
      );
      status = 'Баг подтвержден';
      break;
    default:
      statusIcon = '<span>&nbsp;</span>';
      status = '';
      break;
  }
  return (
    <div>
      <div className="action-history-item">
        <div>
          <div className="meta-data">
            <div className="date">
              {dateToString(createdDate, 'DD.MM.YYYY')}
            </div>
            <div className="status">{status}</div>
          </div>
          <div className="quest-name">{name}</div>
        </div>
        <div className="status-icon">{statusIcon}</div>
      </div>
    </div>
  );
};
export default HistoryItem;
