import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';

const Scroll_: StyledFC = ({ className, children }) => (
  <div className={className + ' scroll-container'}>
    <div className="scroll">{children}</div>
  </div>
);

const Scroll = styled(Scroll_)`
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  scrollbar-width: none;

  > .scroll {
    max-height: 1vmin;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default Scroll;
