import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { StyledFC } from '../../_shared/types';
import { Season } from '../../../_types';
import { useMe } from '../../_shared';
import BarParticles from '../../../_sass/icon/gif_bar_particles.gif';
import QaIcon from '../../_shared/QaIcon';
import { ago } from '../../admin/_util';

const InfoPanel_: StyledFC<{
  season: Season;
  levelName: string;
}> = ({ className, season, levelName }) => {
  const me = useMe();
  const teamName = me.team.name;
  return (
    <div className={className}>
      <div className="team-name">{teamName}</div>
      <div className="progress-bar">
        <div />
        <Link to={`main/season`}>
          <div className="progress">
            <div
              className="current"
              style={{ width: levelName.split(' ').join('') }}
            >
              <div className="pattern" />
              <div className="ending" />
            </div>
            <img
              src={BarParticles}
              alt="#"
              style={{
                left: `calc(${levelName.split(' ').join('')} - 9px)`,
              }}
            />
          </div>
        </Link>
        <Link to={`main/season`}>
          <div className="question">
            <QaIcon name="question" />
          </div>
        </Link>
      </div>
      <div className="season-info">
        <Link to="rating">
          <p>Сезон {season.name}</p>
          <p className="headline">{season.description}</p>
          <p>
            До конца сезона осталось
            <br />
            {ago(season.endDate)}
          </p>
        </Link>
      </div>
    </div>
  );
};

const InfoPanel = styled(InfoPanel_)`
  position: relative;
  align-self: start;
  justify-self: center;
  align-items: center;
  justify-content: center;
  margin-top: -2.5em;
  width: 100%;
  max-width: 375px;

  .team-name {
    text-align: center;

    font-family: Geometria-Heavy, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.2;
    color: #f2f2f2;
    margin: 0.5rem;
  }

  & > .progress-bar {
    display: grid;
    grid-template-columns: 15% auto 15%;

    & > a {
      width: 100%;
      align-self: center;
      .progress {
        display: flex;

        align-items: center;
        position: relative;
        height: 8px;
        width: 100%;
        border-radius: 16px;
        background-color: #4f4f4f;

        .current {
          position: relative;
          height: 100%;
          background-color: #eb5757;
          border-radius: 16px;
          overflow: hidden;

          .pattern {
            position: absolute;
            left: -50vw;
            top: -50vw;
            width: 100vw;
            height: 100vw;
            transform: rotate(60deg);
            background-repeat: repeat;
            background-position: center;
            background-size: 13px;
            background-image: linear-gradient(
              -90deg,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0.2) 40%,
              transparent 40%,
              transparent 60%,
              rgba(255, 255, 255, 0.2) 60%,
              rgba(255, 255, 255, 0.2) 100%
            );
          }

          .ending {
            position: absolute;
            right: 0;
            height: 100%;
            min-width: 63px;
            width: 30%;
            background-image: linear-gradient(
              to left,
              #ffffff,
              rgba(242, 153, 74, 0)
            );
            border-radius: 16px;
          }
        }

        img {
          position: absolute;
          height: 36px;
          width: 18px;
        }

        svg {
          position: absolute;
          left: 100%;
          filter: invert(50%);
          transform: scale(0.5);
          cursor: pointer;
        }
      }
    }
    & > a {
      width: 100%;
      text-align: center;
      text-decoration: none;
      .question {
        align-self: center;
        justify-self: center;
        color: ${({ theme }) => theme.colors.greySpan};
        font-size: 22px;
        padding-top: 0.2em;
      }
    }
  }

  .season-info {
    & > a {
      font-family: Geometria-Bold, sans-serif;
      font-size: 10px;
      line-height: 1.2;
      color: #828282;
      text-align: center;

      margin-top: 0.7rem;
      text-decoration: none;
      .headline {
        font-family: Geometria-ExtraBold, sans-serif;
        color: #e0e0e0;
      }

      & > * {
        justify-content: center;
        margin: 2px 0;
      }
    }
  }

  & > * {
    margin: 0.3rem 0;
  }
`;

export default InfoPanel;
