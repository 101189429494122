import React, { useRef, useEffect, FC, useState, useCallback } from 'react';
import {
  authActions,
  SetSignInData,
  SetSignInDataPayload,
} from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import './style.scss';
import { Dispatch } from 'redux';
import { AppState, AuthState } from '../../../../_types';

const BLANK = '\u200B';
const CELLS_COUNT = 6;

const InputRow: FC<{
  autoFocus?: boolean;
  sendAction: () => void;
  isError?: boolean;
}> = ({ autoFocus = false, sendAction, isError }) => {
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const { password } = useSelector<AppState, AuthState>((state) => state.auth);
  const setDataDispatch = useDispatch<Dispatch<SetSignInData>>();
  const setData = (data: SetSignInDataPayload) =>
    authActions.setData(data)(setDataDispatch);
  const [arr, setArr] = useState<string[]>([]);

  useEffect(() => {
    setArr(password ? password.split('') : []);
  }, [setArr, password]);

  const updateArr = useCallback(() => {
    setArr(password.split(''));
  }, [password]);

  useEffect(() => {
    updateArr();
  }, [updateArr]);

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, CELLS_COUNT);

    if (password.length === CELLS_COUNT) {
      sendAction();
    }
    if (password.length === 0) {
      focusCurrent(CELLS_COUNT - 1);
    }
  });

  const updatePass = (newPassword: string) => {
    // don't perform send if password unchanged
    if (newPassword !== password) {
      setData({
        password: newPassword,
      });
    }
  };

  const focusPrev = (i: number) => {
    if (i > 0) {
      inputsRef.current[i - 1].focus();
      return;
    }

    inputsRef.current[i].focus();
  };

  const focusCurrent = (i: number) => {
    const cell = password.split('')[i - 1];
    if (i > 0 && !cell) {
      focusCurrent(i - 1);
      return;
    }

    inputsRef.current[i].focus();
  };

  const focusNext = (i: number) => {
    if (i < CELLS_COUNT - 1) {
      inputsRef.current[i + 1].focus();
      return;
    }

    inputsRef.current[i].focus();
  };

  const onChangeInput = (
    i: number,
    e?: React.ChangeEvent<HTMLInputElement>,
    c?: React.ClipboardEvent
  ) => {
    // IF PUT
    if (e) {
      // crop selected character from password input
      let res: string[] = password.substr(0, i).split('');

      // crop blank character from updated input
      const updated: string[] = e.target.value
        .toUpperCase()
        .split('')
        .filter((l) => l !== BLANK);

      if (updated.length) {
        if (updated[0] !== ' ') {
          res.push(updated[0]);
          focusNext(i);
        }
      } else {
        focusPrev(i);
      }

      // crop updated password
      const res1 = res.join('').substr(0, CELLS_COUNT);
      updatePass(res1);
    } else if (c) {
      // IF PASTE
      const pastePassword = c.clipboardData.getData('Text');

      // current index
      const i = password.length;

      // current value
      let res: string[] = password.substr(0, i).split('');

      const startedLength = res.length;
      let additionalLength = 0;
      // current paste value
      for (let i = 0; i < pastePassword.length; i++) {
        // take every letter
        const updated: string[] = pastePassword[i]
          .toUpperCase()
          .split('')
          .filter((l) => l !== BLANK);

        if (updated.length) {
          if (updated[0] !== ' ') {
            if (res.length < 6) {
              additionalLength++;
              res.push(updated[0]);
            }
          }
        } else {
          focusPrev(i);
        }
      }

      focusNext(startedLength + additionalLength - 1);

      // crop updated password
      const res1 = res.join('').substr(0, CELLS_COUNT);
      updatePass(res1);
    }
  };

  return (
    <form
      className={classNames('input-row', { error: isError })}
      onSubmit={sendAction}
      onPaste={(e: React.ClipboardEvent) => onChangeInput(0, undefined, e)}
    >
      {Array(CELLS_COUNT)
        .fill(BLANK)
        .map((_, i) => (
          <input
            type="text"
            key={i}
            ref={(el) => {
              if (el !== null) inputsRef.current[i] = el;
            }}
            value={arr[i] ? arr[i] : BLANK}
            disabled={password.length === CELLS_COUNT}
            autoCapitalize="characters"
            autoFocus={i === 1 ? autoFocus : false}
            onClick={(e) => focusCurrent(i)}
            onChange={(e) => onChangeInput(i, e)}
          />
        ))}
    </form>
  );
};

export default InputRow;
