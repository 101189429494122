import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Quests from './Quests';
import Users from './Users';
import Balance from './Balance';
import SeasonsPage from './Seasons';
import Page from './_shared/page/Page';
import { useMe } from '../_shared';
import Tabs from '../_shared/Tabs';
import { isSuperAdmin } from '../auth/_actions';

const Admin: FC = () => {
  const match = useRouteMatch();
  const me = useMe();
  console.log({ me });
  return me.isAdmin ? (
    <Switch>
      <Route path={`${match.path}/balance`} component={Balance} />
      <Route path={`${match.path}/seasons`} component={SeasonsPage} />
      <Route
        path={`${match.path}`}
        render={() => (
          <Page title="Админ-панель">
            <Tabs default="quests">
              {{
                quests: {
                  title: 'Квесты',
                  render: (show) => <Quests show={show} />,
                },
                ...(isSuperAdmin(me)
                  ? {
                      users: {
                        title: 'Пользователи',
                        render: (show) => <Users show={show} />,
                      },
                    }
                  : {}),
              }}
            </Tabs>
          </Page>
        )}
      />
    </Switch>
  ) : (
    <Redirect to="/auth" />
  );
};

export default Admin;
