import React from 'react';
import { StyledFC } from '../../../_shared/types';
import ApiManual from '../../../_shared/ApiManual';
import FooterFormAction, { FormActionType } from './FooterFormAction';
import { ApiStateManual } from '../../../_shared/Api';
import styled from 'styled-components/macro';
import Scroll from '../Scroll';

const ActionForm_: StyledFC<{
  type: FormActionType;
  onSubmit: () => void;
  actionState: ApiStateManual<any>;
  enabled: boolean;
}> = ({ className, type, onSubmit, children, actionState, enabled }) => {
  const footer = (isLoading: boolean) => (
    <FooterFormAction type={type} disabled={!enabled || isLoading} />
  );
  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
        return false;
      }}
    >
      {/*<div className="form-container">*/}
      {/*  <div className="scroller">{children}</div>*/}
      {/*</div>*/}
      <Scroll>{children}</Scroll>

      <ApiManual
        result={actionState}
        renderInit={({ isLoading }) => footer(isLoading)}
        renderError={({ isLoading }) => footer(isLoading)}
        renderData={(_, { isLoading }) => footer(isLoading)}
      />
    </form>
  );
};

const ActionForm = styled(ActionForm_)`
  align-self: start;
  > * {
    margin: 0 24px;
  }
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 72px;
  align-items: stretch;
  //overflow-scrolling: touch;

  //> .form-container {
  //  display: block;
  //  overflow-y: scroll;
  //  overflow-x: hidden;
  //  position: relative;
  //
  //  > .scroller {
  //    max-height: 1vmin;
  //    display: grid;
  //    grid-template-columns: 1fr;
  //    align-items: center;
  //  }
  //  &::-webkit-scrollbar {
  //    display: none;
  //  }
  //}
  > :last-child {
    align-self: stretch;
  }
  > div > input {
    padding-left: 1em;
  }
`;

export default ActionForm;
