import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import BackHeader from '../../_shared/BackHeader';
import BadgeItem from '../../_shared/BadgeItem';
import BadgeContent from './BadgeContent';
import { Achievement } from '../../../../_types';
import './style.scss';

const Achievements: FC<{ achievements: Achievement[] }> = ({
  achievements,
}) => {
  const match = useRouteMatch();
  return (
    <>
      <BackHeader />
      <div className="ach-info">
        <div className="achievements">
          <h3>Достижения</h3>
          <div className="badge-gallery">
            {achievements.map((a: Achievement, i: number) => (
              <Link key={i} to={`${match.url}/${a.id}`}>
                <div className="badge-block">
                  <BadgeItem achievement={a} />
                  <div className="badge-content">
                    <BadgeContent name={a.name} description={a.description} />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Achievements;
