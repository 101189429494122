import React, { useEffect } from 'react';
import { StyledFC } from '../types';
import styled from 'styled-components/macro';
import ActionDialog from './ActionDialog';
import { useGoUp } from '../hooks';
import { Id } from '../../../_types';
import { ReFetch, useApiManualVoid } from '../Api';
import { CancelToken } from 'axios';
import { useRouteMatch } from 'react-router-dom';

const DeleteModal_: StyledFC<{
  entityTypeName: string;
  deleteApi: (params: Id) => (token?: CancelToken) => Promise<void>;
  reFetchList?: ReFetch;
}> = ({ className, entityTypeName, deleteApi, reFetchList }) => {
  const match = useRouteMatch<{ id: string }>();
  const entityId = parseInt(match.params.id);
  const goUp = useGoUp();
  if (entityId < 0) {
    throw new Error(`Invalid entityId: ${entityId}`);
  }
  const [doDelete, deleteState] = useApiManualVoid(deleteApi);
  useEffect(() => {
    if (deleteState.countOfCompletions > 0) {
      reFetchList?.();
      goUp(2);
    }
  }, [goUp, deleteState, reFetchList]);
  return (
    <ActionDialog
      className={className}
      header={`Ты правда хочешь удалить этот ${entityTypeName} ?`}
      text={`${entityTypeName} будет удалён навсегда`}
      onCancel={() => goUp(2)}
      actionName="Удалить"
      action={() => doDelete(entityId)}
      actionState={deleteState}
    />
  );
};

const DeleteDialog = styled(DeleteModal_)`
  > .container > .window > .buttons > .action {
    background-color: ${({ theme }) => theme.colors.red};
    box-shadow: 0 4px 16px 0 rgb(235, 87, 87, 0.5);
  }
`;

export default DeleteDialog;
