import { AuthAction } from '../_actions';
import { AuthState } from '../../../_types';

export const initialState = (): AuthState => {
  const auth = localStorage.getItem('auth');
  const initial: AuthState = {
    accessToken: null,
    refreshToken: null,
    roles: [],
    agreement: false,
    email: '',
    password: '',
    user: undefined,
  };
  const parsed: AuthState = auth ? JSON.parse(auth) : {};

  return auth ? parsed : initial;
};

const auth = (
  state: AuthState = initialState(),
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'SET_SIGN_IN_DATA':
      const res = action.payload;
      return res ? { ...state, ...res } : state;

    case 'SIGN_IN_SUCCESS':
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        roles: action.payload.user.roles,
        user: action.payload.user,
      };

    case 'REFRESH_TOKEN_SUCCESS':
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case 'SIGN_OUT':
      return initialState();

    case 'SIGN_IN_FAILURE':
    case 'SIGN_UP_SUCCESS':
    case 'SIGN_UP_FAILURE':
    case 'REFRESH_TOKEN_FAILURE':
    default:
      return state;
  }
};

export default auth;
