import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import { Season, SeasonGoalLevel } from '../../../_types';
import Bag from './Bag';
import InfoPanel from './InfoPanel';

const MainView_: StyledFC<{
  totalPoints: number;
  season: Season;
}> = ({ className, totalPoints, season }) => {
  const gd: SeasonGoalLevel[] = season.seasonGoalLevels;

  const levelSlice = [...gd].filter((g) => g.pointsCount <= totalPoints);

  const levelName = levelSlice.filter(
    (l) => l.pointsCount === Math.max(...levelSlice.map((ld) => ld.pointsCount))
  )[0].name;

  return (
    <div className={className}>
      <Bag levelName={levelName} />
      <InfoPanel season={season} levelName={levelName} />
    </div>
  );
};

const MainView = styled(MainView_)`
  position: relative;
  display: grid;
  grid-template-rows: auto min-content;
  width: 100%;
  height: 100%;
`;

export default MainView;
