import React, { FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Achievement } from '../../../../../_types';
import { getBadge } from '../../../_shared/util';
import './style.scss';

const BadgeDialog: FC<{
  achievements: Achievement[];
}> = ({ achievements }) => {
  const { id } = useParams();

  const history = useHistory();
  const a = achievements.filter((x: Achievement) => x.id.toString() === id)[0];

  return (
    <div className="wrap-ach">
      <div className="modal">
        <div className="ava">
          <img src={getBadge(a)} alt={a.image} />
        </div>
        <div className="content">
          <h3>{a.name}</h3>
          <p>{a.description}</p>

          <button className="btn" onClick={() => history.goBack()}>
            Ничего себе
          </button>
        </div>
      </div>
    </div>
  );
};
export default BadgeDialog;
