import { StyledFC } from '../../../_shared/types';
import React, { ReactNodeArray } from 'react';
import styled from 'styled-components/macro';
import { Int } from '../../../../_types';

const OverlayButtons_: StyledFC<{
  isVisible: boolean;
  children: ReactNodeArray;
}> = ({ className, isVisible, children }) => {
  return (
    <div className={`${className} ${isVisible ? 'visible' : ''}`}>
      {children}
    </div>
  );
};

const OverlayButtons = styled(OverlayButtons_)<{ size: Int }>`
  display: grid;

  grid-template-columns: ${({ children }) =>
    children.map((_) => '1fr').join(' ')};
  grid-template-rows: 1fr;
  align-items: stretch;
  justify-items: stretch;
  grid-gap: 0;

  position: absolute;
  top: 0;
  height: ${({ size }) => size}px;

  right: ${({ size, children }) => -size * children.length}px;
  transition: right 0.4s ease;

  &.visible {
    transition: right 0.5s ease-in-out;
    right: 0;
  }
`;

export default OverlayButtons;
