import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import WelcomeScreen from './WelcomeScreen';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import RestoreForm from './RestoreForm';
import './style.scss';

const AuthPage: FC = () => {
  const match = useRouteMatch();
  return (
    <div className="auth-page">
      <Switch>
        <Route
          exact
          path={`${match.url}`}
          render={() => <Redirect to={`${match.path}/welcome`} />}
        />
        <Route exact path={`${match.url}/welcome`} component={WelcomeScreen} />
        <Route exact path={`${match.url}/signin`} component={SignInForm} />
        <Route exact path={`${match.url}/signup`} component={SignUpForm} />
        <Route exact path={`${match.url}/restore`} component={RestoreForm} />
        <Route render={() => <Redirect to={`${match.path}/`} />} />
      </Switch>
    </div>
  );
};

export default AuthPage;
