import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import BackHeader from './_shared/BackHeader';
import Button from './_shared/Button';
import Points from './_shared/Points';
import FooterAction from './_shared/FooterAction';
import styled from 'styled-components/macro';
import { Api, useApi } from '../_shared/Api';
import { getQuest } from '../../_api/controller';
import { SockContext } from '../App/SockContext';
import TaskScript from '../../_sass/icon/Task_script@3x.png';
import TaskBug from '../../_sass/icon/Tsk_bug@3x.png';

const DescriptionComponent = styled.div`
  width: 100%;
  height: 100%;

  .scriptContent {
    & > div:last-child {
      margin-top: auto;
      margin-bottom: 30px !important;
    }
    & > div:first-child {
      margin-top: 24px !important;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 128px);
    padding: 0 24px;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .title {
    text-align: left;
    font-family: Geometria-Bold, sans-serif;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    width: 177px;
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 8px;
  }

  .system {
    font-family: Geometria, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #828282;
    margin-bottom: 16px;
  }

  .description {
    position: relative;
    margin-bottom: 16px;
    width: 100%;
    .image {
      position: absolute;
      width: 227px;
      height: 160px;
      right: -22px;
      top: 50%;
      transform: translateY(-50%);
    }
    .text {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      width: 206px;
      position: relative;
      z-index: 1;
    }
  }

  .download-button {
    margin-right: auto;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #333333;
    margin-bottom: 24px;
    cursor: pointer;
  }
  .start-button {
    a {
      text-decoration: none;
    }
  }
`;

const Description: FC = () => {
  const [isShowTextArea] = useState(false);

  const match = useRouteMatch();
  const scriptId = +match.url.split('/')[match.path.split('/').length - 2];

  const parentURL = 'main';
  const startURL = 'script/' + scriptId + '/test';

  const scenarioTypes: { scenario: string; bugs: string } = {
    scenario: 'scenario',
    bugs: 'bugs',
  };

  const result = useApi(getQuest, scriptId);

  const typeOfScript =
    result.data?.questType.id === 1
      ? scenarioTypes.scenario
      : scenarioTypes.bugs;

  // работа с сокетами
  const fromContext = useContext(SockContext);
  useEffect(() => {
    fromContext.setNotificationIsWork(false);
    return () => {
      fromContext.setNotificationIsWork(true);
    };
  }, [fromContext]);

  return (
    <DescriptionComponent>
      <BackHeader backUrl={parentURL} />
      <div className="scriptContent">
        <Api
          result={result}
          renderData={(data) => (
            <>
              <div className="title">{data.name}</div>
              <div className="system">{data.questSystem?.name}</div>
              <div className="description">
                <div className="text">{data.description}</div>
                <div
                  className="image"
                  style={{
                    background:
                      typeOfScript === scenarioTypes.scenario
                        ? `url(${TaskScript}) no-repeat right`
                        : `url(${TaskBug}) no-repeat right`,
                    backgroundSize: 'contain',
                  }}
                >
                  {' '}
                </div>
              </div>
              <a href={match.url} className="download-button">
                Скачать приложение{' '}
              </a>
              <div className="start-button">
                <Link to={`/${startURL}`}>
                  <Button text="Начать тестирование" type={typeOfScript} />
                </Link>
              </div>
              <Points pointsCount={data.questType?.points} />
            </>
          )}
        />
      </div>
      <FooterAction
        scriptId={scriptId}
        isShowTextArea={isShowTextArea}
        type={typeOfScript}
        indicatorIndex={1}
        openCommentSection={() => console.log('open')}
        updateFeedbackText={() => console.log('update')}
      />
    </DescriptionComponent>
  );
};

export default Description;
