export const ago = (date: Date) => {
  const now = new Date();
  const interval = Math.abs(date.getTime() - now.getTime());

  const durationTypes = Object.freeze({
    SECOND: ['секунда', 'секунды', 'секунд'],
    MINUTE: ['минута', 'минуты', 'минут'],
    HOUR: ['час', 'часа', 'часов'],
    DAY: ['день', 'дня', 'дней'],
    WEEK: ['неделя', 'недели', 'недель'],
    MONTH: ['месяц', 'месяца', 'месяцев'],
    NOW: 'сейчас',
  });

  let duration = [durationTypes.NOW];

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;

  if (interval > second) {
    if (interval > minute) {
      if (interval > hour) {
        if (interval > day) {
          if (interval > week) {
            duration = durationTypes.WEEK;
            // if (interval > month) {
            //   duration = durationTypes.MONTH;
            // } else duration = durationTypes.WEEK;
          } else duration = durationTypes.DAY;
        } else duration = durationTypes.HOUR;
      } else duration = durationTypes.MINUTE;
    } else duration = durationTypes.SECOND;
  }

  let numeric;

  switch (duration[0]) {
    case durationTypes.SECOND[0]:
      numeric = Math.ceil(interval / second);
      break;
    case durationTypes.MINUTE[0]:
      numeric = Math.ceil(interval / minute);
      break;
    case durationTypes.HOUR[0]:
      numeric = Math.ceil(interval / hour);
      break;
    case durationTypes.DAY[0]:
      numeric = Math.ceil(interval / day);
      break;
    case durationTypes.WEEK[0]:
      numeric = Math.ceil(interval / week);
      break;
    case durationTypes.MONTH[0]:
      numeric = Math.ceil(interval / month);
      break;
    default:
      numeric = 0;
  }

  const str = numeric.toString().slice(-1);

  switch (str) {
    case '1':
      return `${numeric} ${duration[0]}`;
    case '2':
    case '3':
    case '4':
      return `${numeric} ${duration[1]}`;
    default:
      return `${numeric} ${duration[2]}`;
  }
};
