import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { deleteLevel, getLevels } from '../../../../_api';
import { Api, ReFetch, useApiWoParams } from '../../../_shared/Api';
import DeleteDialog from '../../../_shared/dialog/DeleteDialog';
import New from './New';
import Edit from './Edit';
import createEntityList from '../../_shared/createEntityList';
import { Level } from '../../../../_types';
import { FooterNew } from '../../_shared/FooterAction';
import { RouteDelete, RouteEdit, RouteNew } from '../../_shared/routes';
import ListItem from './ListItem';
import TabContent, { StyledTab } from '../../../_shared/Tabs/TabContent';

const Delete: FC<{ reFetch?: ReFetch }> = ({ reFetch }) => (
  <DeleteDialog
    entityTypeName="уровень"
    deleteApi={deleteLevel}
    reFetchList={reFetch}
  />
);

/** Apply component factory statically (out of render scope) */
const LevelList = createEntityList<Level>(ListItem);

const Levels_: StyledTab = ({ className, show }) => {
  const state = useApiWoParams(getLevels);
  return (
    <TabContent className={className} show={show}>
      <Api
        result={state}
        renderData={(list, { reFetch }) => (
          <>
            <LevelList list={list} />
            {show && (
              <>
                <FooterNew />
                <RouteNew>{() => <New reFetchList={reFetch} />}</RouteNew>
                <RouteEdit>{() => <Edit reFetchList={reFetch} />}</RouteEdit>
                <RouteDelete>{() => <Delete reFetch={reFetch} />}</RouteDelete>
              </>
            )}
          </>
        )}
      />
    </TabContent>
  );
};

const Levels = styled(Levels_)`
  display: grid;
  grid-template-rows: auto 72px 0;
  align-self: center;
  height: 100%;
  margin: 0;
  &:first-child {
    align-self: center;
  }
  > * {
    margin: 0 24px;
  }
`;

export default Levels;
