import { Id } from '../../../_types';
import { ApiState, ApiStateManual, ReFetch } from './types';
import {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { CancelToken } from 'axios';
import { useCancelSource } from '../hooks';
import { ErrorContext } from '../dialog/ErrorDialog';
import hash from 'object-hash';

const useReFetch = (): [Id, ReFetch] => {
  const [fetchId, setFetchId] = useState(0);
  const reFetch = useCallback(() => setFetchId((id) => id + 1), []);
  return [fetchId, reFetch];
};

/** Для загрузки данных с параметрами (например, getUser(id))
 * Первая загрузка осуществляется - автоматически.
 * Последующее обновление данных - через reFetch.
 */
export const useApi = <Params, Data>(
  api: (params: Params) => (token?: CancelToken) => Promise<Data>,
  params: Params
): ApiState<Data> => {
  const [fetchId, reFetch] = useReFetch();
  const [data, setData] = useState<Data | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [countOfCompletions, setCountOfCompletions] = useState<number>(0);
  const cancelSource = useCancelSource();
  const paramsHash = params ? hash(params) : undefined;
  useEffect(
    () => {
      console.log('useApi');
      setLoading(true);
      api(params)(cancelSource.token)
        .then((data) => {
          setData(data);
          setError(undefined);
        })
        .catch(setError)
        .finally(() => {
          setCountOfCompletions((n) => n + 1);
          setLoading(false);
        });
    },
    /** Avoid params from deps */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [api, fetchId, cancelSource, paramsHash]
  );
  const { setError: setErrorInContext } = useContext(ErrorContext);
  useEffect(() => {
    if (error) {
      setErrorInContext(error);
      console.error(error);
    }
  }, [setErrorInContext, error]);
  return useMemo(
    () => ({
      isInit: data === undefined && error === undefined && loading,
      data,
      error,
      isLoading: loading,
      reFetch,
      cancel: cancelSource.cancel,
      countOfCompletions,
    }),
    [data, error, loading, reFetch, cancelSource.cancel, countOfCompletions]
  );
};

/** useApi без параметров (например, getUsers()) */
export const useApiWoParams = <Data>(
  api: (token?: CancelToken) => Promise<Data>
): ApiState<Data> => {
  const api_ = useCallback(() => api, [api]);
  return useApi(api_, undefined);
};

/** Для ручной загрузки данных (например, по кнопке) или мутаций с возвращаемым значением (например, updateUser(id, {name})) */
export const useApiManual = <Params, Data>(
  api: (params: Params) => (token?: CancelToken) => Promise<Data>
): [Dispatch<Params>, ApiStateManual<Data>] => {
  const [data, setData] = useState<Data | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const cancelSource = useCancelSource();
  const [countOfCompletions, setCountOfCompletions] = useState<number>(0);
  const dispatchParams = useCallback(
    (params: Params) => {
      setLoading(true);
      console.log('call api', params);
      api(params)(cancelSource.token)
        .then((data) => {
          setData(data);
          setError(undefined);
        })
        .catch(setError)
        .finally(() => {
          setLoading(false);
          setCountOfCompletions((n) => n + 1);
        });
    },
    [api, cancelSource]
  );
  const { setError: setErrorInContext } = useContext(ErrorContext);
  useEffect(() => {
    if (error) {
      setErrorInContext(error);
      console.error(error);
    }
  }, [setErrorInContext, error]);
  const state = useMemo(
    () => ({
      isInit: data === undefined && error === undefined && loading,
      data,
      error,
      isLoading: loading,
      cancel: cancelSource.cancel,
      countOfCompletions,
    }),
    [data, error, loading, cancelSource.cancel, countOfCompletions]
  );
  return [dispatchParams, state];
};

/** Для мутаций без возвращаемого значения (например, deleteUser(id)) */
export const useApiManualVoid = <Params>(
  api: (params: Params) => (token?: CancelToken) => Promise<void>
): [Dispatch<Params>, ApiStateManual<void>] => {
  return useApiManual(api);
};

/** Для мутаций без возвращаемого значения и аргументов */
export const useApiManualVoidWoParams = (
  api: (token?: CancelToken) => Promise<'done'>
): [Dispatch<void>, ApiStateManual<'done'>] => {
  return useApiManual(() => api);
};
