import React, { FC, useContext } from 'react';
import { Int, Id } from '../../../../_types';
import OverlayButton from './OverlayButton';
import { ThemeContext } from 'styled-components/macro';
import { useHistory, useRouteMatch } from 'react-router-dom';

const EditButton: FC<{
  entityId: Id;
  size: Int;
  before?: () => void;
}> = ({ entityId, size, before }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const theme = useContext(ThemeContext);
  return (
    <OverlayButton
      size={size}
      icon="edit"
      onClick={() => {
        before?.();
        history.push(`${match.path}/${entityId}/edit`);
      }}
      bgColor={theme.colors.yellow}
      color="white"
    />
  );
};

export default EditButton;
