import styled from 'styled-components/macro';

const ErrorMessage = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  background: ${({ theme }) => theme.colors.red};
  color: white;
  padding: 1em;
  font-family: Geometria-Bold, sans-serif;
`;

export default ErrorMessage;
