import React, { useCallback, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledFC } from '../../_shared/types';
import createEntityPage from '../_shared/page/createEntityPage';
import { getSeason, updateSeason } from '../../../_api/controller';
import { Season } from '../../../_types';
import { ReFetch } from '../../_shared/Api';
import {
  FormInputEndDate,
  FormInputName,
  FormInputStartDate,
  FormInputString,
} from '../_shared/form/FormInput';
import styled from 'styled-components/macro';
import createUpdateForm from '../_shared/form/createUpdateForm';
import PageEdit from '../_shared/page/PageEdit';

/** Apply component factory statically (out of render scope) */
const UpdateForm = createUpdateForm(updateSeason);

const EditForm_: StyledFC<{
  entity: Season;
  reFetchList: ReFetch;
  children: {};
}> = ({ className, entity, reFetchList }) => {
  const [name, setName] = useState(entity.name);
  const [description, setDescription] = useState(entity.description);
  const [startDate, setStartDate] = useState(entity.startDate);
  const [endDate, setEndDate] = useState(entity.endDate);

  const isChanged = useCallback(
    () => () =>
      entity &&
      (name !== entity.name ||
        description !== entity.description ||
        startDate !== entity.startDate ||
        endDate !== entity.endDate),
    [entity, name, description, startDate, endDate]
  );
  const validate = useCallback(
    () =>
      isChanged() &&
      name != null &&
      description != null &&
      startDate != null &&
      endDate != null
        ? { name, description, startDate, endDate }
        : undefined,
    [isChanged, name, description, startDate, endDate]
  );
  return (
    <PageEdit className={className} entityTypeName="сезон">
      <UpdateForm
        className={className}
        entity={entity}
        validate={validate}
        reFetchList={reFetchList}
      >
        <>
          <FormInputName state={name} setState={setName} />
          <FormInputString
            label={`Цель сезона`}
            id="description"
            state={description}
            setState={setDescription}
          />
          <FormInputStartDate
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
          />
          <FormInputEndDate
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </>
      </UpdateForm>
    </PageEdit>
  );
};

const EditForm = styled(EditForm_)`
  > .scroll-container > .scroll {
    grid-template-rows: 60px 60px 60px 60px;
  }
`;

/** Apply component factory statically (out of render scope) */
const EditWoChildren = createEntityPage(getSeason, 'сезон', EditForm);

const Edit: StyledFC<{
  reFetchList: ReFetch;
}> = (props) => <EditWoChildren {...props}>{}</EditWoChildren>;

export default Edit;
