export const isScoreBetweenNumbers = (
  score: number,
  numOne: number,
  numTwo: number
) => {
  return score >= numOne && score < numTwo;
};

export const getLevelScore = (
  levelBoundaries: number[],
  index: number,
  def: number
) => {
  return levelBoundaries[index + 1] ? levelBoundaries[index + 1] : def;
};

export const getLevelByScore = (levelBoundaries: number[], score: number) => {
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 0, 1),
      getLevelScore(levelBoundaries, 1, 100)
    )
  )
    return 1;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 1, 100),
      getLevelScore(levelBoundaries, 2, 200)
    )
  )
    return 2;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 2, 200),
      getLevelScore(levelBoundaries, 3, 400)
    )
  )
    return 3;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 3, 400),
      getLevelScore(levelBoundaries, 4, 800)
    )
  )
    return 4;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 4, 800),
      getLevelScore(levelBoundaries, 5, 1600)
    )
  )
    return 5;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 5, 1600),
      getLevelScore(levelBoundaries, 6, 3200)
    )
  )
    return 6;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 6, 3200),
      getLevelScore(levelBoundaries, 7, 6400)
    )
  )
    return 7;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 7, 6400),
      getLevelScore(levelBoundaries, 8, 12400)
    )
  )
    return 8;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 8, 12400),
      getLevelScore(levelBoundaries, 9, 24800)
    )
  )
    return 8;
  if (
    isScoreBetweenNumbers(
      score,
      getLevelScore(levelBoundaries, 9, 24800),
      getLevelScore(levelBoundaries, 10, 50000)
    )
  )
    return 10;
  else return 10;
};
