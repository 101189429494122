import React, { FC, useState } from 'react';
import { Redirect, Link, useRouteMatch } from 'react-router-dom';
import {
  getTeams,
  getCurrentSeason,
  getTeamRatingTotalPoints,
  getCommonRatingTotalPoints,
} from '../../../_api';
import { Api, useApiWoParams } from '../../_shared/Api';
import { ago } from '../../admin/_util';
import { SeasonGoalLevel } from '../../../_types';

import styled from 'styled-components/macro';

import QaIcon from '../../_shared/QaIcon';
import BarParticles from '../../../_sass/icon/gif_bar_particles.gif';
import IconBagBlue1 from '../_shared/bag_icons/2x/bag_blue_1.png';
import IconBagBlue2 from '../_shared/bag_icons/2x/bag_blue_2.png';
import IconBagBlue3 from '../_shared/bag_icons/2x/bag_blue_3.png';
import IconBagBlue4 from '../_shared/bag_icons/2x/bag_blue_4.png';
import IconBagBlue5 from '../_shared/bag_icons/2x/bag_blue_5.png';
import IconBagRed1 from '../_shared/bag_icons/2x/bag_red_1.png';
import IconBagRed2 from '../_shared/bag_icons/2x/bag_red_2.png';
import IconBagRed3 from '../_shared/bag_icons/2x/bag_red_3.png';
import IconBagRed4 from '../_shared/bag_icons/2x/bag_red_4.png';
import IconBagRed5 from '../_shared/bag_icons/2x/bag_red_5.png';
import MainBackground from '../../../_sass/img/bg_default@3x.png';
import Screen from '../../_shared/Screen';

const ButtonDataComponent = styled.div`
  div {
    span {
      position: absolute;
      font-size: 1.4rem;
      line-height: 1.8rem;
      left: 21px;
      color: #fff;
    }

    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 6%;
  width: calc(100% - 7% * 2);
  cursor: pointer;
  background-color: #35323b;
  margin: 0 !important;
  padding: 32px 7% 0;
`;

const ButtonData = () => {
  const parentPath = (path: string, protector: string = '/') => {
    return path
      ? path
          .split('/')
          .slice(0, path.split('/').length - 1)
          .join('/')
      : protector;
  };

  const match = useRouteMatch();
  const [redirect, setRedirect] = useState(false);
  const dest = parentPath(match.path);

  return redirect ? (
    <Redirect to={dest} />
  ) : (
    <ButtonDataComponent>
      <div onClick={() => setRedirect(true)}>
        <QaIcon name={'back'} />
      </div>
    </ButtonDataComponent>
  );
};

const TeamDataComponent = styled.div`
  .image-wrap {
    img {
      position: absolute;
      left: 50%;
      top: 55%;
      max-height: 440px;
      height: 245%;
      width: auto;
      transform: translate(calc(-54% + 22px), -50%);
      pointer-events: none;
    }

    position: relative;
    width: 100%;
    flex-grow: 1;
  }

  .progress {
    .points {
      font-family: 'Geometria';
      color: #f2f2f2;
      font-size: 10px;
    }

    .name {
      font-family: 'Geometria-Bold';
      color: #828282;
      font-size: 10px;
    }

    .progress-bar {
      .current {
        .ending {
          position: absolute;
          right: 0;
          height: 100%;
          min-width: 63px;
          width: 30%;
          background-image: linear-gradient(
            to left,
            #ffffff,
            rgba(242, 153, 74, 0)
          );
          border-radius: 16px;
        }

        position: absolute;
        height: 100%;
        background-color: #eb5757;
        background-image: linear-gradient(
          -30deg,
          rgba(255, 255, 255, 0.2) 40%,
          transparent 40%,
          transparent 55%,
          rgba(255, 255, 255, 0.2) 55%,
          rgba(255, 255, 255, 0.2)
        );
        background-repeat: repeat;
        background-size: 23px;
        background-position: center;
        border-radius: 16px;
        overflow: hidden;
      }

      img {
        position: absolute;
        height: 36px;
        width: 18px;
      }

      display: flex;
      align-items: center;
      position: relative;
      height: 8px;
      width: 100%;
      border-radius: 16px;
      background-color: #4f4f4f;
      transform: scaleX(-1);
    }

    & > * {
      margin: 2.5% 0;
    }

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  display: flex;
  flex-direction: column;
  position: relative;
  width: 45%;
  z-index: 5;
`;

const TeamData: FC<{
  name: string;
  bag: string;
  points: number;
  level: string;
  isReversed?: boolean;
}> = ({ name, bag, points, level, isReversed = false }) => (
  <TeamDataComponent>
    <div className="image-wrap">
      <img src={bag} alt={bag} />
    </div>
    <div className="progress">
      <p className="points">{points}</p>
      <div className="progress-bar">
        <div className="current" style={{ width: level.split(' ').join('') }}>
          <div className="ending"></div>
        </div>
        <img
          src={BarParticles}
          alt="#"
          style={{ left: `calc(${level.split(' ').join('')} - 9px)` }}
        />
      </div>
      <p className="name">{name}</p>
    </div>
  </TeamDataComponent>
);

const SeasonComponent = styled.div`
  .data-info {
    .headline {
      font-size: 12px;
      line-height: 1.2;
      color: #eb5757;
      text-transform: uppercase;
    }

    .season {
      font-size: 25px;
      line-height: 1.2;
      color: #fff;
    }

    .remaining {
      font-size: 10px;
      line-height: 1.2;
      color: #f2f2f2;
      text-transform: uppercase;
    }

    p:first-child {
      margin-bottom: 0;
    }

    & > * {
      width: 100;
      font-family: 'Geometria-ExtraBold';
      margin: calc(6.5% - 1rem) 5%;
      display: initial;
    }

    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    z-index: 5;
  }

  .team-stats {
    .backplate {
      .bag-shadow {
        position: absolute;
        top: calc(62% - 2rem);
        min-height: 3rem;
        height: 7%;
        width: 65%;
        user-select: none;

        background-image: radial-gradient(
          ellipse farthest-side at 50% 50%,
          #0008,
          rgba(53, 50, 59, 0)
        );
      }

      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      user-select: none;

      background-image: radial-gradient(
        circle at 50% 40%,
        #524e5a 0%,
        #35323b 45%
      );
    }

    & > :last-child {
      .image-wrap {
        transform: scaleX(-1);
      }

      .progress {
        .progress-bar {
          transform: scaleX(1);
        }

        align-items: flex-start;
      }
    }

    & > *:not(:first-child):not(:last-child) {
      margin-right: 1rem;
    }

    display: flex;
    justify-content: center;
    max-height: 55%;
    width: 100%;
    flex-grow: 1;
    margin-bottom: 8px;
  }

  .footer {
    a {
      font-family: 'Geometria-Bold';
      font-size: 16px;
      line-height: 1.2;
      color: #ffffff;
      text-decoration: none;
      padding: 12px 1.5rem;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(255, 176, 22, 0.5);
      background-color: #ffb016;
      user-select: none;
    }

    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 5;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: calc(100% - 7% * 2);
  margin: 0 !important;
  padding: 32px 7%;
  padding-top: 0;
  background-color: #35323b;
`;

const Season = () => {
  const icons = {
    bag_blue_1: IconBagBlue1,
    bag_blue_2: IconBagBlue2,
    bag_blue_3: IconBagBlue3,
    bag_blue_4: IconBagBlue4,
    bag_blue_5: IconBagBlue5,
    bag_red_1: IconBagRed1,
    bag_red_2: IconBagRed2,
    bag_red_3: IconBagRed3,
    bag_red_4: IconBagRed4,
    bag_red_5: IconBagRed5,
  };

  const seasonState = useApiWoParams(getCurrentSeason);
  const teams = useApiWoParams(getTeams);
  const teamRatingTotalPoints = useApiWoParams(getTeamRatingTotalPoints);
  const commonRatingTotalPoints = useApiWoParams(getCommonRatingTotalPoints);

  return (
    <SeasonComponent>
      <Api
        result={seasonState}
        renderData={(season, { reFetch }) =>
          season === null ? (
            <div>Текущий сезон не установлен</div>
          ) : (
            <Api
              result={teams}
              renderData={(teamData, { reFetch }) => (
                <Api
                  result={teamRatingTotalPoints}
                  renderData={(teamRatingData, { reFetch }) => (
                    <Api
                      result={commonRatingTotalPoints}
                      renderData={(commonRatingData, { reFetch }) => {
                        const data = teamData.map((t, index) => {
                          const gd: SeasonGoalLevel[] = season.seasonGoalLevels;

                          const teamData =
                            index === 0 ? teamRatingData : commonRatingData;
                          const points =
                            teamData === undefined ? 0 : teamData.points;

                          const levelSlice = [...gd].filter(
                            (g) => g.pointsCount <= points
                          );

                          const level = levelSlice.filter(
                            (l) =>
                              l.pointsCount ===
                              Math.max(
                                ...levelSlice.map((ld) => ld.pointsCount)
                              )
                          )[0].name;

                          const bag =
                            level === '0 %'
                              ? t.color === '#EB5757'
                                ? icons.bag_red_1
                                : icons.bag_blue_1
                              : level === '25 %'
                              ? t.color === '#EB5757'
                                ? icons.bag_red_2
                                : icons.bag_blue_2
                              : level === '50 %'
                              ? t.color === '#EB5757'
                                ? icons.bag_red_3
                                : icons.bag_blue_3
                              : level === '75 %'
                              ? t.color === '#EB5757'
                                ? icons.bag_red_4
                                : icons.bag_blue_4
                              : t.color === '#EB5757'
                              ? icons.bag_red_5
                              : icons.bag_blue_5;

                          return {
                            name: t.name,
                            bag: bag,
                            points: points,
                            level: level,
                          };
                        });

                        return (
                          <>
                            <div className="data-info">
                              <p className="headline">Сезон</p>
                              <h2 className="season">{season.name}</h2>
                              <p className="remaining">
                                Осталось {ago(season.endDate)}
                              </p>
                            </div>
                            <div className="team-stats">
                              <div className="backplate">
                                <div className="bag-shadow"></div>
                              </div>
                              {data.map((d, i) => (
                                <TeamData
                                  key={i}
                                  {...d}
                                  isReversed={i % 2 === 1}
                                />
                              ))}
                            </div>
                            <div className="footer">
                              <Link to="/main">Нужно поднажать</Link>
                            </div>
                          </>
                        );
                      }}
                    />
                  )}
                />
              )}
            />
          )
        }
      />
    </SeasonComponent>
  );
};

const PageComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(${MainBackground});
`;

const SeasonPage = () => {
  return (
    <PageComponent>
      <Screen>
        <ButtonData />
        <Season></Season>
      </Screen>
    </PageComponent>
  );
};

export default SeasonPage;
