import styled from 'styled-components/macro';

const Footer = styled.div`
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    user-select: none;
    margin: 16px 7%;
    flex-grow: 1;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #35323b;
  text-align: center;
  margin: 0 !important;
`;

export default Footer;
