import React, { Dispatch, useEffect } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import { QuestSystem } from '../../../_types';
import { useHistory, useRouteMatch } from 'react-router-dom';

const SystemEditor_: StyledFC<{
  systems: QuestSystem[];
  state: QuestSystem | undefined;
  setState: Dispatch<QuestSystem>;
  disabled?: boolean;
}> = ({ className, systems, state, setState, disabled }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const defaultState = systems[0];
  useEffect(() => {
    if (state === undefined && defaultState !== undefined)
      setState(defaultState);
  }, [state, defaultState, setState]);
  return (
    <div className={className}>
      <label htmlFor="system">Операционная система</label>
      <select
        name="system"
        value={state?.id}
        onChange={(e) => {
          if (e.target.value === 'new') {
            console.log('New System Dialog!');
            history.push(`${match.url}/newSystem`);
          } else {
            setState(systems[e.target.selectedIndex]);
          }
        }}
        style={{
          width: '-webkit-fill-available',
        }}
        disabled={disabled}
      >
        {systems.map((system, i) => (
          <option key={i} id={String(system.id)} value={system.id}>
            {system.name}
          </option>
        ))}
        <option key="new-system" id="new-system" value={'new'}>
          Другое...
        </option>
      </select>
    </div>
  );
};

const SystemEditor = styled(SystemEditor_)`
  > label {
    position: relative;
    top: 1em;
    left: 1em;
    padding: 0 0.5em;
    color: ${({ theme }) => theme.colors.grey};
    // &:focus {
    //   color: ${({ theme }) => theme.colors.greySpan};
    // }
    background: linear-gradient(white, white, rgba(255,255,255, 0));
  }
  > select {
    min-height: 2em;
    font-family: Geometria-Medium, sans-serif;
    font-size: 16px;
    -webkit-appearance: none;
  
    padding: 0.7em 1em;
    background-color: white;
    color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.grey};
    border-radius: ${({ theme }) => theme.borderRadius}px;
    border-width: 2px;
    &:focus {
      color: ${({ theme }) => theme.colors.yellow};
      border-color: ${({ theme }) => theme.colors.greySpan};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`;

export default SystemEditor;
