import React, { useState, useEffect, useRef } from 'react';
import { Api, useApi, useApiWoParams } from '../../_shared/Api';
import { getNotifications, getNotReadNotificationsCount } from '../../../_api';
import { dateToString } from '../_shared/helpers';
import ScreenWrap from '../_shared/screen-wrap';
import ListItem from '../_shared/list-item';
import styled from 'styled-components/macro';
import { Notification } from '../../../_types';

const ListComponent = styled.div`
  h2 {
    span {
      color: #f2994a;
    }

    font-family: 'Geometria-ExtraBold';
    font-size: 25px;
    font-weight: 800;
    line-height: 1.2;
    color: #333;
  }

  .content {
    ::-webkit-scrollbar {
      display: none;
    }

    height: 0%;
    flex-grow: 1;
    overflow: scroll;
    scrollbar-width: none;
  }

  & > * {
    margin: 0;
    margin-bottom: 36px;
  }

  & > :last-child {
    margin-bottom: inherit;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 0%;
  flex-grow: 1;
`;

const List = () => {
  const listRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [list, setList] = useState<Notification[]>([]);
  const result = useApi(getNotifications, { page: page });
  const counterRes = useApiWoParams(getNotReadNotificationsCount);

  useEffect(() => {
    if (result.data !== undefined) {
      setList((list) => [...new Set([...list, ...result?.data?.items])]);
    }
  }, [result]);

  const fetchMore = () => {
    const el = listRef.current;
    if (el !== null) {
      if (result.data !== undefined)
        if (
          el.scrollTop + el.clientHeight >= el.scrollHeight &&
          list.length < result.data.totalItems
        ) {
          setPage(page + 1);
          result.reFetch();
        }
    }
  };

  const items = list
    .map((i) => ({
      ...i,
      date: dateToString(new Date(i.date), 'DD.MM.YYYY'),
    }))
    .sort((a, b) => (b.isReaded ? -1 : 0));

  return (
    <ScreenWrap
      isLoading={counterRes.isLoading}
      redirect={{
        pathname: '/main',
        state: { menu: true },
      }}
    >
      <ListComponent>
        <Api
          result={counterRes}
          renderData={(pendingData, { reFetch }) => {
            let pendingCount = pendingData.count;

            return (
              <h2>
                Уведомления
                {pendingCount > 0 && (
                  <>
                    &nbsp;&nbsp;
                    <span>+{pendingCount}</span>
                  </>
                )}
              </h2>
            );
          }}
        />
        <div className="content" onScroll={fetchMore} ref={listRef}>
          {items.map((item) => (
            <ListItem key={item.id} {...item} />
          ))}
        </div>
      </ListComponent>
    </ScreenWrap>
  );
};

export default List;
