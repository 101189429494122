import { Link, useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import QaIcon from '../../../_shared/QaIcon';
import { Int } from '../../../../_types';

const UpButton_: FC<{
  className?: string;
  level?: Int;
}> = ({ className, level = 1 }) => {
  const match = useRouteMatch();
  const pathItems = match.path.split('/').filter((x) => x.length > 0);
  if (level <= 0 || level > pathItems.length)
    throw new Error(
      `Invalid level: Expected (0, lengthOf(${pathItems.join(
        '/'
      )}) ], actual ${level}`
    );
  const to = '/' + pathItems.slice(0, -level).join('/');
  // console.log('BackButton', match.path, to);
  return (
    <Link className={className} to={to}>
      <QaIcon name="back" />
    </Link>
  );
};

const UpButton = styled(UpButton_).attrs((props) => ({
  svgSize: 48,
  coeff: 0.6,
}))`
  align-self: center;

  display: block;
  position: relative;
  min-height: ${(props) => Math.round(props.svgSize * props.coeff)}px;
  //margin-top: 28px !important;
  text-decoration: none;
  font-size: 24px;
  text-align: left;
  margin-left: 18px !important;

  svg {
    //position: absolute;
    //left: ($svg-size * $coeff) / -4;
    width: ${(props) => Math.round(props.svgSize * props.coeff)}px;
    height: ${(props) => Math.round(props.svgSize * props.coeff)}px;
  }
`;

export default UpButton;
