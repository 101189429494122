export const sockNotificationTypes = {
  RECEIVED_NEW_BADGE: 'RECEIVED_NEW_BADGE',
  TRANSITION_NEW_LEVEL: 'TRANSITION_NEW_LEVEL',
  CUSTOMER_APPROVED_BUG: 'CUSTOMER_APPROVED_BUG',
  QUEST_COMPLETED_USER: 'QUEST_COMPLETED_USER',
  EXTRA_POINTS: 'EXTRA_POINTS',
  BIG_TASK: 'BIG_TASK',
  HISTORY: 'history',
};

export const sockRedirectUrls = {
  TARGET: '/congrats/target',
  LEVELUP: '/congrats/levelup',
  THANKS: '/congrats/thanks',
  APPROVED: '/congrats/approved',
  COUNTER: '/congrats/counter',
  NEWBADGE: '/congrats/newbadge',
};

export const entityType = {
  NOTICE: 'NOTICE',
  STORY: 'STORY',
};
