import React, { FC, useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';

import Confetti from '../qa-particles.mp4';
import styled from 'styled-components/macro';

const ScreenComponent = styled.div`
  & > .wrapper {
    .video-wrap {
      video {
        width: 100%;
        height: auto;
        z-index: 0;
      }

      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      border-radius: 24px;
      overflow: hidden;
    }

    .circle-wrap {
      .circle {
        position: absolute;
        width: 100%;
        height: auto;
      }

      position: absolute;
      top: -10%;
      //left: 7%;
      left: 9%;
      display: flex;
      justify-content: center;
      align-items: center;
      //height: 58%;
      height: 64%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      overflow: hidden;
      mask-image: linear-gradient(
        to bottom,
        rgb(0, 0, 0) 60%,
        rgba(0, 0, 0, 0) 95%
      );
    }

    .image-wrap {
      &.extended {
        margin-bottom: 21%;
      }
      .newBadgeImage {
        max-height: 80%;
        max-width: 80%;
        background: #333333;
        border: 6px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .img {
        height: 100%;
        width: auto;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 42%;
      margin-bottom: 12%;
    }

    .data {
      h2 {
        font-family: 'Geometria-ExtraBold';
        font-size: 25px;
        line-height: 1.2;
      }

      p {
        font-family: 'Geometria';
        font-size: 16px;
        line-height: 1.4;
        display: initial;
      }

      & > * {
        margin: unset;
        margin-bottom: 18px;
      }

      & > :last-child {
        margin-bottom: unset;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      flex-grow: 1;
      width: 90%;
      text-align: center;
      margin: 12px 0;
      overflow: scroll;
      scrollbar-width: none;
    }

    button {
      &::-moz-focus-inner {
        border: none;
      }

      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(255, 176, 22, 0.5);
      background-color: #ffb016;
      font-family: 'Geometria-Bold';
      font-size: 16px;
      line-height: 1.2;
      margin-bottom: calc(46px - 4%);
      padding: 0;
      min-height: 44px;
      min-width: 180px;
      width: 55%;
      border: none;
      outline: none;
      color: #fff;
      cursor: pointer;
    }

    & > * {
      //width: 88%;
      width: 84%;
      z-index: 5;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 4%;
    width: 92%;
  }

  position: relative;
  display: flex;
  border-radius: 24px;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  max-width: 360px;
  width: calc(100% - 16px);
  max-height: 737px;
  height: 90%;
  margin: 8px;
  color: #333;
  overflow: hidden;
`;

const CongratsScreen: FC<{
  image?: string;
  circleImage?: string;
  header: string;
  message: string | React.ReactNode;
  action: string;
  children?: React.ReactNode;
}> = ({ image, circleImage, header, message, action, children }) => {
  const [videoHidden, setVideoHidden] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const dest = '/';

  const videoRef = useRef<HTMLVideoElement>(null);
  const dataRef = useRef<HTMLDivElement>(null);
  const pref = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    dataRef.current && pref.current
      ? dataRef.current.scrollTo(0, pref.current.offsetTop)
      : console.log('awd');
    if (dataRef.current !== null) dataRef.current.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (videoRef)
      videoRef?.current?.play().catch((error) => setVideoHidden(true));
  }, []);

  const displayHeight = window.outerHeight;

  const newBadge = circleImage === '';

  return redirect ? (
    <Redirect to={dest} />
  ) : (
    <ScreenComponent>
      <div className="wrapper">
        <div className="video-wrap">
          <video autoPlay muted playsInline ref={videoRef} hidden={videoHidden}>
            <source src={Confetti} type="video/mp4" />
          </video>
        </div>
        {!newBadge && (
          <div className="circle-wrap">
            <img src={circleImage} alt="#" className="circle" />
          </div>
        )}
        <div className={classNames('image-wrap', { extended: children })}>
          <img
            src={image}
            alt="#"
            className={newBadge ? 'newBadgeImage img' : 'img'}
          />
          {children}
        </div>
        <div
          ref={dataRef}
          className="data"
          style={{
            width: displayHeight <= 668 ? '94%' : '90%',
            margin: displayHeight <= 668 ? '6px 0' : '12px 0',
          }}
        >
          <h2
            style={{
              marginBottom: displayHeight <= 668 ? 9 : 18,
            }}
          >
            {header}
          </h2>
          <p ref={pref}>{message}</p>
        </div>
        <button
          onClick={() => setRedirect(true)}
          style={{
            marginBottom: displayHeight <= 668 ? 0 : 'calc(46px - 4%)',
          }}
        >
          {action}
        </button>
      </div>
    </ScreenComponent>
  );
};

export default CongratsScreen;
