import React, { FC, FormEvent, MouseEvent, RefObject, useState } from 'react';
import { Redirect, Link, useRouteMatch } from 'react-router-dom';
import {
  authActions,
  SetSignInData,
  SetSignInDataPayload,
  SignInAction,
  SignUpAction,
} from '../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import axios, { CancelToken } from 'axios';

import InputRow from '../_shared/InputRow';
import InputBar from '../_shared/InputBar';
import './style.scss';
import { useCancelSource } from '../../_shared';
import { AppState, AuthState } from '../../../_types';
import MessageToast, { useToast } from '../_shared/MessageToast';
import { validateEmail } from '../_validation';
import QaIcon from '../../_shared/QaIcon';

const SignUpForm: FC = () => {
  const match = useRouteMatch();
  const { accessToken } = useSelector<AppState, AuthState>(
    (state) => state.auth
  );
  const isAuth = !!accessToken;
  const [isError, setIsError] = useState(false);

  const { email, password } = useSelector<AppState, AuthState>(
    (state) => state.auth
  );
  const signInDispatch = useDispatch<Dispatch<SignInAction>>();
  const signIn = (email: string, password: string, token: CancelToken) =>
    authActions.signIn(email, password, token)(signInDispatch);
  const signUpDispatch = useDispatch<Dispatch<SignUpAction>>();
  const signUp = (email: string, token: CancelToken) =>
    authActions.signUp(email, token)(signUpDispatch);
  const setDataDispatch = useDispatch<Dispatch<SetSignInData>>();
  const setData = (data: SetSignInDataPayload) =>
    authActions.setData(data)(setDataDispatch);
  const [toastData, pushMsg] = useToast();
  const cancelSource = useCancelSource();

  const currentURL = match.url.split('/')[match.url.split('/').length - 1];
  const signinURL = match.url.replace(currentURL, 'signin');
  // const checkboxRef = useRef<HTMLInputElement>(null);

  // const validateAgreements = (e: {
  //   target: { checked: boolean; setCustomValidity: (msg: string) => void };
  // }) => {
  //   if (!e.target.checked) {
  //     e.target.setCustomValidity(
  //       'Вы должны принять правила и пользовательское соглашение'
  //     );
  //   } else {
  //     e.target.setCustomValidity('');
  //   }
  // };

  const doSignUp = (
    event: FormEvent<HTMLFormElement> | MouseEvent,
    ref: RefObject<HTMLInputElement>
  ) => {
    console.log('sendEmail', event, ref);
    if (ref.current && validateEmail({ target: ref.current })) {
      // if (agreement) {
      signUp(ref.current.value, cancelSource.token)
        .then(() => {
          if (ref.current) setData({ email: ref.current.value });
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            const status = error.response ? error.response.status : '000';
            const message = error.response?.data.message;
            setIsError(true);
            pushMsg(status, message);
          }
        });
      // }
    }
    // if (!agreement && checkboxRef.current)
    //   validateAgreements({ target: checkboxRef.current });
    event.preventDefault();
  };

  const doSignIn = () => {
    signIn(email, password, cancelSource.token).catch((error) => {
      if (!axios.isCancel(error)) {
        const status = error.response ? error.response.status : '000';
        const message = error.response?.data.message;
        setData({ password: '' });
        setIsError(true);
        pushMsg(status, message);
      }
    });
  };

  // const AgreementCheckbox = () => (
  //   <label className="user-agreement">
  //     <input
  //       ref={checkboxRef}
  //       type="checkbox"
  //       defaultChecked={agreement}
  //       onChange={(e) => setData({ agreement: e.target.checked })}
  //       // onInvalid={(e) => validateCheckbox({ target: e.target })}
  //       // onInvalid={validateCheckbox}
  //       onInvalid={(e) => console.log('onInvalid', e)}
  //     />
  //     <span className="checkmark"></span>
  //     <div>
  //       Принимаю <Link to="/about/rules">правила</Link> и{' '}
  //       <Link to="/about/agreement">пользовательское соглашение</Link>
  //     </div>
  //   </label>
  // );

  return isAuth ? (
    <Redirect to="/onboarding" />
  ) : (
    <div className="form">
      <div className="content">
        {!email ? (
          <>
            <span className="icon">
              <QaIcon name={'login-mail'} />
              <QaIcon name={'login-contour'} />
            </span>
            <div className="wrapper login">
              <h2>Нет аккаунта? Зарегистрируйся</h2>
              <p>Введи адрес корпоративной почты</p>
              <InputBar
                onChange={validateEmail}
                // checkbox={AgreementCheckbox}
                sendAction={doSignUp}
              />
              <span>
                <MessageToast data={toastData} />
              </span>
            </div>
          </>
        ) : (
          <>
            <span className="icon">
              <QaIcon name={'login-pass'} />
              <QaIcon name={'login-contour'} />
            </span>
            <div className="wrapper pass">
              <h2>Введи код-пароль, отправленный на e-mail</h2>
              <p>{email}</p>
              <InputRow
                sendAction={doSignIn}
                autoFocus={true}
                isError={isError}
              />
              <span>
                <MessageToast data={toastData} />
              </span>
            </div>
          </>
        )}
        <div className="footer">
          <span>
            <span className={!email.length ? 'login' : 'pass'}>•</span>
          </span>
          <Link to={signinURL} onClick={() => setData({})}>
            У меня есть аккаунт
          </Link>
        </div>
      </div>
      <div className="backplate"></div>
    </div>
  );
};

export default SignUpForm;
