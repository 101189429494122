import React, { FC, useCallback, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';
import { useApiManual } from '../../../_shared/Api';
import { updateUserPassword } from '../../../../_api/controller';
import { Id } from '../../../../_types';
import { useGoUp } from '../../../_shared';

const CodeDialog: FC<{
  callback?: () => void;
  userId: Id;
  password: string;
}> = ({ callback, userId, password }) => {
  //const history = useHistory();

  const [updatePass, updateStatePass] = useApiManual(updateUserPassword);
  const updateDone =
    updateStatePass.error === undefined &&
    updateStatePass.countOfCompletions > 0;
  const goBack = useGoUp();

  const backToEdit = useCallback(() => {
    if (callback) callback();
    goBack(1);
  }, [callback, goBack]);

  useEffect(() => {
    if (updateDone) {
      backToEdit();
    }
  }, [updateDone, backToEdit]);

  return (
    <div className="wrap-modal">
      <div className="modal-bg">
        <div className="modal-window">
          <div className="modal-content">
            <h3>
              Изменить <br /> код-пароль?
            </h3>

            <div className="buttons">
              <button onClick={backToEdit} className="black">
                Отменить
              </button>
              <button
                onClick={() => {
                  updatePass({
                    id: userId,
                    password,
                  });
                }}
              >
                Заменить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeDialog;
