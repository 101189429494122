import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import List from './list';
import Detailed from './detailed';
import styled from 'styled-components/macro';
import MainBackground from '../../_sass/img/bg_default@3x.png';

const PageComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url(${MainBackground});
  overflow-x: hidden;
`;

const NotificationsPage = () => {
  const match = useRouteMatch();

  return (
    <PageComponent>
      <Switch>
        <Route exact path={match.path} component={List} />
        <Route path={`${match.path}/:id`} component={Detailed} />
      </Switch>
    </PageComponent>
  );
};

export default NotificationsPage;
