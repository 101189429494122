import * as api from '../internal';
import { Id, Season, SeasonGoalLevel } from '../../_types';
import { CancelToken } from 'axios';
import API from '../axios';

const path = 'seasons';

export type CreateSeasonArg = {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
};
export type UpdateSeasonArg = Partial<CreateSeasonArg>;

export const getSeasons = api.getList<Season>(path);
export const getSeason = api.getEntity<Season>(path);
export const createSeason = api.createEntity<CreateSeasonArg, Season>(path);
export const updateSeason = api.updateEntity<UpdateSeasonArg, Season>(path);
export const deleteSeason = api.deleteEntity(path);

export const getSeasonGoalLevels = (id: Id) => (token?: CancelToken) =>
  API.get<SeasonGoalLevel[]>(`${path}/${id}/goal-levels`, {
    cancelToken: token,
  }).then((res) => res?.data);

export const getCurrentSeason = (token?: CancelToken) =>
  api
    .get<void, Season | null>(`${path}/current`)(undefined)(token)
    .then(
      (season) =>
        season && {
          ...season,
          seasonGoalLevels: api.sortLevels(season.seasonGoalLevels),
        }
    );
