import React, { MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../_shared/types';
import QaIcon, { QaIconName } from '../../../_shared/QaIcon';
import { Int } from '../../../../_types';

const OverlayButton_: StyledFC<{
  icon: QaIconName;
  onClick?: MouseEventHandler<HTMLDivElement>;
}> = ({ className, icon, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <QaIcon name={icon} />
    </div>
  );
};

export const OverlayButton = styled(OverlayButton_)<{
  bgColor: string;
  color: string;
  size: Int;
}>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};

  width: ${({ size }) => size}px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  .qa-icon {
    text-decoration: none;
    font-size: 32px;
  }
`;

export default OverlayButton;
