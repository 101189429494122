import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import OverlayButtons from './OverlayButtons';
import React, { FC } from 'react';
import { Int, Id } from '../../../../_types';

const EditDeleteButtons: FC<{
  isVisible: boolean;
  size: Int;
  before: () => void;
  entityId: Id;
}> = ({ isVisible, size, before, entityId }) => (
  <OverlayButtons isVisible={isVisible} size={size}>
    {[
      <EditButton key="edit" entityId={entityId} size={size} before={before} />,
      <DeleteButton
        key="delete"
        entityId={entityId}
        size={size}
        before={before}
      />,
    ]}
  </OverlayButtons>
);

export default EditDeleteButtons;
