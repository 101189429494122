import React, { FC, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import ProtectedRoute from '../ProtectedRoute';
import Auth from '../auth';
import About from '../about';
import Onboarding from '../onboarding';
import Main from '../main';
import Admin from '../admin';
import Congrats from '../congrats';
import Notifications from '../notifications';
import GlobalFonts from '../../_styled/GlobalFonts';
import { ThemeProvider } from 'styled-components/macro';
import { theme } from '../../_styled/theme';
import Script from '../script';
import Profile from '../profile';
import Rating from '../rating';
import ErrorDialog, { ErrorContext } from '../_shared/dialog/ErrorDialog';
import {
  checkSockTypeAndGetPushUrl,
  getCurrentUserEmail,
  getSockUrl,
} from '../_shared/sock';
// @ts-ignore
import SockJsClient from 'react-stomp';
import { SockContext } from './SockContext';
import { entityType } from '../../_constants';
import { sockNotification } from '../../_types';
import { SockPushObject, SockRef } from '../_shared/types';
import { useCssWindow } from '../_shared';

const App: FC = () => {
  useCssWindow();
  const [error, setError] = useState<Error>();

  // SOCKET
  const history = useHistory();
  let clientRef: SockRef | undefined = undefined;

  const [profileUpdateValue, setProfileUpdateValue] = useState<
    string | undefined
  >(undefined);
  const [notificationUpdateValue, setNotificationUpdateValue] = useState<
    string | undefined
  >(undefined);
  const [clientRefUpdateValue, setClientRefUpdateValue] = useState<
    SockRef | undefined
  >(undefined);
  const [isNotificationWork, setIsNotificationOn] = useState(true);
  const [historyPushQueue, setHistoryPushQueue] = useState<SockPushObject[]>(
    []
  );

  const setNotificationIsWork = (bool: boolean) => {
    setIsNotificationOn(bool);
  };

  const pushWithState = useCallback(
    (pushObj: SockPushObject) => {
      history.push({
        pathname: pushObj.pathname,
        state: {
          gained: pushObj.gained,
          achievementImage: pushObj.achievementImage,
          achievementDescription: pushObj.achievementDescription,
        },
      });
    },
    [history]
  );

  useEffect(() => {
    if (isNotificationWork && historyPushQueue.length > 0) {
      const pushObj = historyPushQueue[0];
      setHistoryPushQueue(historyPushQueue.slice(1));
      pushWithState(pushObj);
    }
  }, [isNotificationWork, history, historyPushQueue, pushWithState]);

  // const checkMessage = (res: sockNotification) => {
  const checkMessage = (res: sockNotification) => {
    console.log(res);
    // 1) при любом уведомлении даем знать, чтобы на странице MainPage дернуть уведомления
    setNotificationUpdateValue(Date.now().toString());

    // 2) если пришли уведомления типа "история" то, даем знать, чтобы на странице userInfo дернуть "Историю"
    if (res.entityType === entityType.STORY)
      setProfileUpdateValue(Date.now().toString());

    // 3) получаем url, и редиректимся по нему, если это одно из нужных нам уведомлений
    const pushObj: SockPushObject | undefined = checkSockTypeAndGetPushUrl(res);

    if (pushObj !== undefined)
      if (isNotificationWork) {
        pushWithState(pushObj);
      } else {
        const newHistoryPushQueue = [...historyPushQueue, pushObj];
        setHistoryPushQueue(newHistoryPushQueue);
      }
  };

  const email = getCurrentUserEmail();
  const url = getSockUrl(email);

  const onConnect = () => {
    setClientRefUpdateValue(clientRef);
    console.log('Sock connected');
  };
  const onDisconnect = () => {
    console.log('Sock disconnected');
  };

  useEffect(() => {
    setClientRefUpdateValue(clientRef);
  }, [clientRef]);

  // SOCKET

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      <ThemeProvider theme={theme}>
        <GlobalFonts />
        {/*{me && <FileUploadExample />}*/}
        {/*<TestErrorButton />*/}
        {email !== '' && (
          <SockJsClient
            onConnect={onConnect}
            onDisconnect={onDisconnect}
            url={url}
            topics={['/user/queue/reply']}
            onMessage={(msg: sockNotification) => checkMessage(msg)}
            ref={(client: SockRef) => {
              clientRef = client;
            }}
          />
        )}
        <SockContext.Provider
          value={{
            profileUpdateValue,
            notificationUpdateValue,
            clientRefUpdateValue,
            setNotificationIsWork,
          }}
        >
          <Switch>
            <Route path="/auth" component={Auth} />
            <Route path="/about" component={About} />
            <Route path="/onboarding" component={Onboarding} />
            <Route
              path="/tutorial"
              render={() => (
                <Redirect
                  to={{
                    pathname: '/onboarding',
                    state: { tutorial: true },
                  }}
                />
              )}
            />
            <ProtectedRoute path="/main" component={Main} />
            <ProtectedRoute path="/congrats" component={Congrats} />
            <ProtectedRoute path="/notifications" component={Notifications} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/admin" component={Admin} />
            <ProtectedRoute path="/script" component={Script} />
            <ProtectedRoute path="/rating" component={Rating} />
            <Route render={() => <Redirect to="/main" />} />
          </Switch>
        </SockContext.Provider>
        <ErrorDialog />
      </ThemeProvider>
    </ErrorContext.Provider>
  );
};

export default App;
