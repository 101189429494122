import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import MainBackground from '../../_sass/img/bg_default@3x.png';
import { StyledFC } from '../_shared/types';
import FooterContent from './FooterContent';
import ScreenContent from './ScreenContent';
import Footer from '../_shared/Screen/Footer';
import Page from './Page';

type LocationProps = {
  tutorial: boolean | undefined;
};

const Onboarding_: StyledFC = ({ className }) => {
  const location = useLocation();

  const { tutorial }: LocationProps = (location?.state as {
    tutorial: boolean;
  }) || { tutorial: false };

  const [screenId, setScreenId] = useState(0);

  return screenId >= 3 ? (
    <Redirect to={tutorial ? '/main' : '/congrats/teaming'} />
  ) : (
    <Page className={className}>
      <ScreenContent screenId={screenId} />
      <Footer>
        <FooterContent
          screenID={screenId}
          onNext={() => setScreenId(screenId + 1)}
        />
      </Footer>
    </Page>
  );
};

const Onboarding = styled(Onboarding_)`
  background-image: url(${MainBackground});
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr auto;
`;

export default Onboarding;
