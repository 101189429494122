import React, { FC, useEffect, useState } from 'react';
import Button from './Button';
import styled from 'styled-components/macro';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ScreenshotView: FC<{
  type: string;
  view: File[];
  currentScreenshotIndex: number;
  onClose: () => void;
  deleteScreenshotHandler: (index: number) => void;
}> = ({
  deleteScreenshotHandler,
  type,
  view,
  currentScreenshotIndex,
  onClose,
}) => {
  const scenarioActiveIndicator = { background: '#ffb016' };
  const bugsActiveIndicator = { background: '#27AE60' };
  const defaultIndicator = { background: '#4f4f4f' };

  const indicatorStyles = (index: number) => {
    return index === screenshotIndex + 1
      ? type.toUpperCase() === 'BUGS'
        ? bugsActiveIndicator
        : scenarioActiveIndicator
      : defaultIndicator;
  };

  const getURL = (file: File) => {
    return URL.createObjectURL(file);
  };

  useEffect(() => {
    setScreenshotIndex(currentScreenshotIndex);
  }, [currentScreenshotIndex]);

  const [screenshotIndex, setScreenshotIndex] = useState(0);

  const deleteScreenshot = () => {
    if (screenshotIndex === 0) setScreenshotIndex(view.length - 2);
    else setScreenshotIndex(screenshotIndex - 1);
    deleteScreenshotHandler(screenshotIndex);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: currentScreenshotIndex,
    afterChange: function (index: number) {
      setScreenshotIndex(index);
    },
  };

  if (!view[screenshotIndex]) return null;

  return (
    <ScreenshotDialog>
      <div className="closeIcon" onClick={onClose}>
        {' '}
      </div>
      <div className="screenshotFooter">
        {' '}
        <Button onClick={deleteScreenshot} text="Удалить" type={type} />
      </div>
      <Slider {...settings}>
        {view.map((screenshot, index) => (
          <img
            key={screenshot.name}
            className="screenshotImage"
            src={getURL(view[index])}
            alt=""
          />
        ))}
      </Slider>
      <div className="indicatorWrapper">
        {view.map((viewElement, index) => {
          return (
            <div
              key={viewElement.name}
              className="indicator"
              style={indicatorStyles(index + 1)}
            >
              {' '}
            </div>
          );
        })}
      </div>
    </ScreenshotDialog>
  );
};

/* eslint-disable */
const ScreenshotDialog = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  background-color: #49464f;
  z-index: 2;
  border-radius: 24px;
  .screenshotFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    height: 72px;
    border-radius: 0 0 24px 24px;
    background-color: #35323b;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .closeIcon {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 22px;
    height: 22px;
    opacity: 1;
    &:before,
    &:after {
      top: 2.5px;
      right: 9.56px;
      position: absolute;
      content: ' ';
      height: 16px;
      width: 2px;
      background-color: white;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  .indicatorWrapper {
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    display: flex;
    .indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background: #4f4f4f;
    }
  }

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }
  .slick-slide > div {
    height: 100%;
  }

  .screenshotImage {
    border-radius: 24px 24px 0 0;
    width: 100%;
    object-fit: contain;
    height: calc(100% - 72px) !important;
  }
`;

export default ScreenshotView;
