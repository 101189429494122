import { StyledFC } from '../../../_shared/types';
import Page from './Page';
import styled from 'styled-components/macro';
import React from 'react';

const PageView_: StyledFC<{ title: string }> = ({
  className,
  title,
  children,
}) => {
  return (
    <Page className={className} title={title} upLevel={2}>
      {children}
    </Page>
  );
};

const PageView = styled(PageView_)`
  grid-template-rows: 80px 60px auto;
  > :last-child {
    height: 100%;
  }
`;
export default PageView;
