import React, { FC, useState } from 'react';
import { Redirect, Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import QaIcon from '../_shared/QaIcon';
import Screen from '../_shared/Screen';
import Footer from '../_shared/Screen/Footer';

const ButtonDataComponent = styled.div`
  span {
    position: absolute;
    font-size: 1.4rem;
    line-height: 1.8rem;
    left: 21px;
  }

  flex-grow: 1;
`;

const ButtonData = () => {
  const parentPath = (path: string, protector: string = '/') => {
    return path
      ? path
          .split('/')
          .slice(0, path.split('/').length - 1)
          .join('/')
      : protector;
  };

  const match = useRouteMatch();
  const [redirect, setRedirect] = useState(false);
  const dest = parentPath(match.path);

  return redirect ? (
    <Redirect to={dest} />
  ) : (
    <ButtonDataComponent onClick={() => setRedirect(true)}>
      <QaIcon name={'back'} />
    </ButtonDataComponent>
  );
};

const FooterDataComponent = styled.div`
  a {
    display: inline;
    font-family: 'Geometria-Bold';
    font-size: 16px;
    line-height: 19.2px;
    white-space: nowrap;
    padding: 10px;
    min-width: 104px;
    cursor: pointer;
    color: #f2994a;
    background: none;
    border: 2px solid #f2994a;
    border-radius: 12px;
    user-select: none;
    text-decoration: none;
  }
`;

const FooterData: FC<{ to: string }> = ({ to }) => (
  <FooterDataComponent>
    <Link to={to}>Обучение</Link>
  </FooterDataComponent>
);

const ScreenDataComponent = styled.div`
  img {
    height: 58%;
    background-color: #e0e0e0;
  }

  .data-wrap {
    div {
      h2 {
        font-family: 'Geometria-ExtraBold';
        font-size: 25px;
        line-height: 30px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
      }

      p,
      a {
        font-family: 'Geometria';
        font-size: 16px;
        line-height: 22.4px;
      }

      a:not(:first-of-type) {
        margin-top: 0;
      }

      & > * {
        display: initial;
        width: 100%;
        margin: 0.5rem 0;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 1.5rem;
      overflow: scroll;
      scrollbar-width: none;
    }

    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 0;
    flex-grow: 1;
    background-color: #fff;
  }

  & > * {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #333333 !important;
  margin: 0 !important;
`;

const ScreenData = () => {
  const text = (
    <p>
      Добро пожаловать в "СилуСбера"! Перед тобой грандиозная площадка для
      тестирования сайтов и приложений экосистемы Сбербанка сотрудниками.
      <br />
      <br />
      Здесь работа разделена на сезоны, на каждый из которых устанавливается
      цель. Что же тебя ждет после ее выполнения? Заполнив "мешочек доброты",
      благородная команда сможет вложить его содержимое в реальную помощь
      природе, животным или детям. А чтобы лишний раз не потеряться в поисках
      конкретной цели и уровня наполненности "мешочка доброты", ты можешь
      обратиться за помощью к главному экрану.
      <br />
      <br />
      Кстати, пора бы и познакомиться с командами: Добрейшины и Щедрейшины! На
      площадке распределение по командам происходит абсолютно рандомно -
      настоящая интрига. Ну, а раз у нас соревнование, тебе необходимо одержать
      победу над командой противника, заполнив "мешочек доброты" своей команды
      быстрее. Посмотреть уровень наполненности "мешочка доброты" команды
      противника можно во вкладке меню "Команды".
      <br />
      <br />
      Провести тестирование ты можешь, выбрав интересующий квест (систему для
      тестирования), внутри которого будет описание необходимых действий и
      ссылка на бета-версию. Сами квесты могут быть двух типов: прохождение по
      сценарию с указанием конкретных шагов, и поиск багов, когда проводится
      тестирование бета- версии системы полностью.
      <br />
      <br />
      Если ты первым хочешь узнать о старте нового квеста, жди соответствующее
      уведомление.
      <br />
      <br />
      Чуть не забыли о самом важном! Получить "пыльцу доброты" ты можешь при
      возвращении в систему, прохождении квеста или одобрении заказчиком
      найденного тобой бага.
      <br />
      <br />
      Зарабатывая "пыльцу доброты", ты получаешь место в общем рейтинге добряков
      и рейтинге своей команды, которые ты можешь в любой момент посмотреть во
      вкладке "Рейтинг".
      <br />
      <br />
      Давай вместе сделаем этот мир лучше! <br />
    </p>
  );

  return (
    <ScreenDataComponent>
      <div className="data-wrap">
        <div>
          <h2>Что такое "СилаСбера"?</h2>
          {text}
          {/*<Link to={`${match.path}/rules`}>Правила</Link>*/}
          {/*<Link to={`${match.path}/agreement`}>*/}
          {/*  Пользовательское соглашение*/}
          {/*</Link>*/}
        </div>
      </div>
    </ScreenDataComponent>
  );
};

const About = () => {
  return (
    <Screen backButton={<ButtonData />}>
      <ScreenData />
      <Footer>
        <FooterData to="/tutorial" />
      </Footer>
    </Screen>
  );
};

export default About;
