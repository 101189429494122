import React, { FC } from 'react';
import './style.scss';
import './responsive.scss';
import HistoryItem from './HistoryItem';
import { ScrollState } from '../../../_shared';
import { ActionHistory } from '../../../../_types';

const History: FC<{ state: ScrollState<ActionHistory> }> = ({ state }) => {
  const { list } = state;

  return (
    <div className="action-history">
      <h3>История</h3>
      <div className="history-list">
        {list.map((s, i) => (
          <HistoryItem
            key={i}
            createdDate={s.quest.createdDate}
            name={s.quest.name}
            eventType={s.eventType}
            points={s.quest.questType.pointsApproved}
          />
        ))}
      </div>
    </div>
  );
};

export default History;
