import IconBagBlue1 from './bag_blue_1.png';
import IconBagBlue2 from './bag_blue_2.png';
import IconBagBlue3 from './bag_blue_3.png';
import IconBagBlue4 from './bag_blue_4.png';
import IconBagBlue5 from './bag_blue_5.png';
import IconBagRed1 from './bag_red_1.png';
import IconBagRed2 from './bag_red_2.png';
import IconBagRed3 from './bag_red_3.png';
import IconBagRed4 from './bag_red_4.png';
import IconBagRed5 from './bag_red_5.png';

export const icons = {
  bag_blue_1: IconBagBlue1,
  bag_blue_2: IconBagBlue2,
  bag_blue_3: IconBagBlue3,
  bag_blue_4: IconBagBlue4,
  bag_blue_5: IconBagBlue5,
  bag_red_1: IconBagRed1,
  bag_red_2: IconBagRed2,
  bag_red_3: IconBagRed3,
  bag_red_4: IconBagRed4,
  bag_red_5: IconBagRed5,
};
