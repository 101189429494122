import React, { useMemo, useState } from 'react';
import { StyledFC } from '../../_shared/types';
import styled from 'styled-components/macro';
import FooterAction from '../_shared/FooterAction';
import { ReFetch, useApiManualVoid } from '../../_shared/Api';
import FormTextArea from '../_shared/form/FormTextArea';
import { inviteUsers } from '../../../_api/controller';
import { useGoUp } from '../../_shared';
import ApiManualButton from '../_shared/ApiManualButton';
import uniq from 'lodash/uniq';
import Page from '../_shared/page/Page';

const emailRegExprRFC2822 = () =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

const New_: StyledFC<{ reFetchList: ReFetch }> = ({
  className,
  reFetchList,
}) => {
  const goUp = useGoUp();
  const [emailsText, setEmailsText] = useState('');
  const emails = useMemo(
    () =>
      uniq(
        emailsText
          .split(',')
          .map((s) => s.trim())
          .filter((s) => s.length > 0)
          .filter((s) => emailRegExprRFC2822().test(s))
      ),
    [emailsText]
  );
  const [invite, inviteState] = useApiManualVoid(inviteUsers);
  return (
    <Page className={className} title="Пригласить пользователей" upLevel={1}>
      <FormTextArea
        id="emails"
        label="Email"
        placeHolder="Укажите e-mail адреса через запятую"
        state={emailsText}
        setState={setEmailsText}
      />
      <div className="message">Будет отправлено на {emails.length} адресов</div>
      <div />
      <FooterAction>
        <ApiManualButton
          title="Отправить"
          state={inviteState}
          onClick={() => invite(emails)}
          onSuccess={() => {
            reFetchList();
            goUp(1);
          }}
          disabled={emails.length === 0}
        />
      </FooterAction>
    </Page>
  );
};

const New = styled(New_)`
  grid-template-rows: 80px 60px min-content 40px auto 72px;
  font-family: Geometria, sans-serif;
  font-size: 12px;

  > .message {
    align-self: center;
    color: ${({ theme }) => theme.colors.grey};
  }
  .action {
    font-size: 14px;
    font-weight: bold;
    background: none;
    &:disabled {
      color: ${({ theme }) => theme.colors.lightGrey};
      border-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
  > :last-child {
    height: 100%;
  }
`;

export default New;
