export const getData = () => [
  'А ты знал, что в античности хвалебные песни звучали в честь Диониса, бога виноделия? Народных вакханалий мы, конечно, не обещаем, но дифирамбы ты явно заслужил!',
  'Научно доказано, что при получении комплимента у человека активируется участок мозга, отвечающий за "второе дыхание". И кстати, ты сегодня славно потрудился!',
  'Эй, трудяга, устал? Немного ягод, подсластитель и обычный желатин готовы преобразиться во вкуснейший мармелад в холодильной камере ради восполнения твоих сил.',
  'Превосходная работа! Говорят, у хорошего специалиста вечно не хватает времени. Но ты, похоже, откопал в дедушкином гараже машину времени. Не поделишься с нами?',
  'Твоей эффективности стоит позавидовать! А мама в детстве наверняка ругала за дружбу с компьютером-хулиганом. Предлагаем сделать скриншот экрана и порадовать ее.',
  'Всемирно известный весельчак Стивен Кинг однажды сказал:\n"Любитель садится и ждет вдохновения. Профессионалы – просто встают и идут работать". А кто сегодня ты?',
  'Известный сюрреалист Сальвадор Дали считал, что гениальность кончается там, где начинается безумие. Как думаешь, пора уже выдвигаться за смирительной рубашкой?',
  'Ты щелкаешь задания, как орешки. С такой производительностью может сравниться разве что самый отвязный бурундук. Какое счастье, что ты не впадаешь в спячку!',
  'Наши задания как коробка шоколадных конфет: никогда не знаешь, какая начинка тебе попадется. Но ты отлично справляешься! А теперь беги дальше, Форест, беги!',
  '– Хьюстон, у нас проблема. Этот персонаж явно желает наполнить свой мешочек доброты до отвалу.\n– Так наградим его за это пыльцой!',
  'Ух, ну и работенка! Или внутри тебя мощнейший энерджайзер, или ты большой любитель сникерсов. Кстати, у этого батончика непростое происхождение - ржачная история.',
  '"Я – король мира!" Самоуверенности Джека можно позавидовать. Хотя зачем? Давай просто повторим за ним, расхвалив себя, например, за проделанную работу.',
  'Ты достоин герцогского титула! Как жаль, что прошли времена драк колбасами, выпивания из винного фонтана и нападений на рыцарей с граблями. Или не прошли?',
  'Невероятная скорость! Кстати, самой продаваемой игрой в истории стал Tetris. Разработка разлетелась по стране за пару часов, а люди прозвали ее "игровой чумой" ',
  'Бесконечность не предел! "История игрушек" 1995 года была первым смоделированным на компьютере мультфильмом. Приятно, что существует прогресс. Касается и тебя!',
  'Эй, хочешь порцию новой мотивации? Профессор из Нью-Дели сумел получить 145 дипломов. В детстве мы думали, что у индийцев много рук, но образований ещё больше.',
  'После такой бесподобной работы нужно воспользоваться правилом "6391": помоги своему сердцу 1 работой всех мышц 639. Зарядка под хорошую музыку - самое оно!',
  'Совершенная работа, второй Левша! Или третий? Поговаривают, мастер из России уже обратил в жизнь историю Лескова. Готов совершить нечто грандиозно миниатюрное?',
  'Новый шаг во имя равновесия! Альберт Эйнштейн сравнивал нашу жизнь с вождением велосипеда, пробуждая не останавливаться.\nИстинный гений, жаль, мозг похитили.',
  'Ты закончил еще одну главу нашей истории! Мы недавно узнали, что самой большой книгой является сборник высказываний известных людей. Может, и твое там имеется?',
];
