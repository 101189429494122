import { Dispatch, MiddlewareAPI } from 'redux';
import { AuthAction } from '../_actions';

export const localStorageMiddleware = (api: MiddlewareAPI) => (
  next: Dispatch<AuthAction>
) => (action: AuthAction) => {
  if (['SIGN_IN_SUCCESS', 'REFRESH_TOKEN_SUCCESS'].includes(action.type)) {
    localStorage.setItem('auth', JSON.stringify(action.payload));
  }

  if (['SIGN_OUT'].includes(action.type)) {
    localStorage.removeItem('auth');
  }
  next(action);
};
