import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import MainBackground from '../../_sass/img/bg_default@3x.png';

import About from './About';
// import Rules from './Rules';
// import Agreement from './Agreement';

const PageComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url(${MainBackground});
`;

const AboutPage = () => {
  const match = useRouteMatch();

  return (
    <PageComponent>
      <Switch>
        <Route exact path={`${match.path}`} component={About} />
        <Route render={() => <Redirect to={`${match.path}`} />} />
        {/*<Route path={`${match.path}/rules`} component={Rules} />*/}
        {/*<Route path={`${match.path}/agreement`} component={Agreement} />*/}
      </Switch>
    </PageComponent>
  );
};

export default AboutPage;
