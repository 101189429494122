import React, { useContext, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled, { ThemeContext, DefaultTheme } from 'styled-components/macro';
import { Int, User } from '../../../_types';
import { ListState } from '../_shared/EntityList';
import { ReFetch } from '../../_shared/Api';
import { StyledFC } from '../../_shared/types';
import OverlayButtons from '../_shared/overlay-buttons/OverlayButtons';
import OverlayButton from '../_shared/overlay-buttons/OverlayButton';
import { isAdmin } from '../../auth/_actions';

const UserIcon = styled(OverlayButton)`
  .qa-icon {
    font-size: 20px;
  }
`;

const getIcons = (user: User, theme: DefaultTheme, size: Int) => {
  const icons = [];
  if (user.isBlocked)
    icons.push(
      <UserIcon
        key="ban"
        icon="ban"
        bgColor="white"
        color={theme.colors.greySpan}
        size={size}
      />
    );
  if (isAdmin(user))
    icons.push(
      <UserIcon
        key="admin"
        icon="lightning"
        bgColor="white"
        color={theme.colors.greySpan}
        size={size}
      />
    );
  return icons;
};

const ListItem_: StyledFC<{
  listState: ListState;
  entity: User;
  index: number;
  reFetch: ReFetch;
}> = ({ className, entity }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const itemDiv = useRef<HTMLDivElement>(null);
  const size = itemDiv.current ? itemDiv.current.clientHeight : 0;
  const theme = useContext(ThemeContext);
  const icons = getIcons(entity, theme, size);
  return (
    <div ref={itemDiv} className={className}>
      <div
        className="data"
        onClick={() => history.push(`${match.path}/${entity.id}/edit`)}
      >
        <p className="header">{`${entity.name}`}</p>
        <p className="description">{`${entity.points} очков`}</p>
      </div>
      <OverlayButtons isVisible={true} size={size}>
        {icons}
      </OverlayButtons>
    </div>
  );
};

const ListItem = styled(ListItem_)`
  position: relative;
  margin-bottom: 0;
  > .data {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    font-family: 'Geometria', sans-serif;
    margin: 8px 0;
    > .header {
      font-weight: bold;
      font-size: 12px;
      margin: 4px 0;
    }
    > .description {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      margin: 4px 0;
    }
  }
`;

export default ListItem;
