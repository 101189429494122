import React, { FC, useContext, useEffect } from 'react';
import './style.scss';

import BackHeader from '../_shared/BackHeader';
import StatBlock from './StatBlock';
import History from './History';
import AvaCard from './AvaCard';

import LastAchievements from './LastAchievements';
import { useReFetchMe, useScroll } from '../../_shared';
import { getLevels, getNotificationStory } from '../../../_api/controller';
import { SockContext } from '../../App/SockContext';
import Api, { useApiWoParams } from '../../_shared/Api';
import { Achievement } from '../../../_types';

const UserInfo: FC<{ achievements: Achievement[] }> = ({ achievements }) => {
  const reFetchMe = useReFetchMe();
  /** History */
  const storyState = useScroll(getNotificationStory, 5);
  const reFetchStory = storyState.apiState.reFetch;

  /** Levels */
  const levelsState = useApiWoParams(getLevels);

  /** Sockets */
  const fromContext = useContext(SockContext);
  const profileUpdateValue: string | undefined = fromContext.profileUpdateValue;
  useEffect(() => {
    // здесь необходимо стянуть заново обновленные данные
    if (profileUpdateValue !== undefined) {
      console.log('reFetch history');
      reFetchStory();
      console.log('reFetch me');
      reFetchMe();
    }
  }, [profileUpdateValue, reFetchStory, reFetchMe]);

  return (
    <Api
      result={storyState.apiState}
      renderData={() => (
        <Api
          result={levelsState}
          renderData={(levels) => (
            <>
              <BackHeader />

              <div className="info" onScroll={storyState.onScroll}>
                <StatBlock />
                <LastAchievements achievements={achievements} />
                <History state={storyState} />
              </div>
              <AvaCard levels={levels} />
            </>
          )}
        />
      )}
    />
  );
};

export default UserInfo;
