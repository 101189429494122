import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import { Link, useRouteMatch } from 'react-router-dom';

const FooterAction_: StyledFC = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

const FooterAction = styled(FooterAction_)`
  margin: 0 !important;
  min-height: 10%;
  background-color: ${({ theme }) => theme.colors.blueGrey};
  text-align: center;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;

  .action {
    display: inline;
    font-family: 'Geometria', sans-serif;
    color: #fff;
    font-size: 32px;
    line-height: 19.2px;
    white-space: nowrap;
    border: 2px solid #fff;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    min-width: 19.2px;
  }
`;

export default FooterAction;

export const FooterLink: StyledFC<{
  className?: string;
  title: string;
  path: string;
}> = ({ className, title, path }) => {
  const match = useRouteMatch();
  return (
    <FooterAction className={className}>
      <Link to={`${match.path}/${path}`} className="action">
        {title}
      </Link>
    </FooterAction>
  );
};

export const FooterNew: StyledFC = () => (
  <FooterLink title={'+'} path={'new'} />
);
