import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

const ScriptId: FC = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}`}
        render={() => <Redirect to={`${match.url}/description`} />}
      />
    </Switch>
  );
};

export default ScriptId;
