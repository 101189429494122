import React, { FC, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import BackHeader from './_shared/BackHeader';
import FooterAction from './_shared/FooterAction';
import QaIcon from '../_shared/QaIcon';
import AddScreenshot from './_shared/AddScreenshot';
import ScreenshotView from './_shared/ScreenshotView';
import SendComment from './_shared/SendComment';
import styled from 'styled-components/macro';
import { Api, useApi, useApiManual } from '../_shared/Api';
import { getQuest } from '../../_api/controller';
import { fileDelete } from '../../_api/controller/file';
import { SockContext } from '../App/SockContext';
import { QuestStep } from '../../_types';
const Test: FC = () => {
  const match = useRouteMatch();
  const scriptId = +match.url.split('/')[match.path.split('/').length - 2];

  const [screenshotView, setScreenshotView] = useState([] as File[]);
  const [screenshotUrls, setScreenshotUrls] = useState([] as string[]);
  const [screenshotsCount, setScreenshotsCount] = useState(0);
  const [openScreenshots, setOpenScreenshots] = useState(false);
  const [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(0);
  const [isSendCommentClicked] = useState(false);

  const [isShowTextArea, setIsShowTextArea] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isShowSendCommentButton, setIsShowSendCommentButton] = useState(false);

  const [deleteScreenshot] = useApiManual(fileDelete);

  useEffect(() => {
    setIsShowSendCommentButton(feedbackText.length > 0);
  }, [feedbackText]);

  const parentURL = 'main';
  const descriptionURL = 'script/' + scriptId + '/description';

  const scenarioTypes: { scenario: string; bugs: string } = {
    scenario: 'scenario',
    bugs: 'bugs',
  };

  const getNoDuplicateScreenshots = (files: File[]) => {
    const currentScreenshots = screenshotView;
    const addedScreenshots = files;
    const newScreenshots = [];
    for (let i = 0; i < addedScreenshots.length; i++) {
      const currentAddedName = addedScreenshots[i].name;
      let isNeedToPush = true;
      for (let j = 0; j < currentScreenshots.length; j++) {
        const currentScreenshotName = currentScreenshots[j].name;
        if (currentAddedName === currentScreenshotName) isNeedToPush = false;
      }
      if (isNeedToPush) newScreenshots.push(addedScreenshots[i]);
    }
    return newScreenshots;
  };

  const getLocalUrl = (backUrl: string) => {
    const splitted = backUrl.split('.');
    return splitted[splitted.length - 2].trim();
  };

  const getNoDuplicateUrls = (urls: string[]) => {
    const currentUrls = screenshotUrls;
    const addedUrls = urls;
    const newUrls = [];
    for (let i = 0; i < addedUrls.length; i++) {
      const currentAddedName = getLocalUrl(addedUrls[i]);
      let isNeedToPush = true;
      for (let j = 0; j < currentUrls.length; j++) {
        const currentScreenshotName = getLocalUrl(currentUrls[j]);
        if (currentAddedName === currentScreenshotName) isNeedToPush = false;
      }
      if (isNeedToPush) newUrls.push(addedUrls[i]);
    }
    return newUrls;
  };

  const addScreenshotHandler = (files: File[], backUrls: string[]): void => {
    if (typeOfScript === 'bugs') setIsShowTextArea(true);
    const newScreenshotFiles = getNoDuplicateScreenshots(files);
    const newScreenshotUrls = getNoDuplicateUrls(backUrls);
    setScreenshotView([...screenshotView, ...newScreenshotFiles]);
    setScreenshotUrls([...screenshotUrls, ...newScreenshotUrls]);
    setScreenshotsCount([...screenshotView, ...newScreenshotFiles].length);
  };

  const getCurrentIndex = (index: number) => {
    setCurrentScreenshotIndex(index);
    setOpenScreenshots(!openScreenshots);
  };

  const closeScreenshot = () => {
    setOpenScreenshots(false);
  };

  const openCommentSection = () => {
    setIsShowTextArea(true);
    setIsShowSendCommentButton(true);
  };

  const updateFeedbackText = (text: string) => {
    setFeedbackText(text);
  };

  const hideSendCommentSection = () => {
    setIsShowTextArea(false);
    setIsShowSendCommentButton(false);
  };

  const CommentComponent = () => {
    return (
      <SendComment
        isInsideFooter={false}
        commentClickHandler={openCommentSection}
      />
    );
  };

  const RenderSendComment = () => {
    if (
      isShowSendCommentButton ||
      isShowTextArea ||
      typeOfScript === scenarioTypes.scenario
    )
      return null;
    else if (
      screenshotsCount === 0 &&
      !isShowTextArea &&
      !isShowSendCommentButton
    )
      return <CommentComponent />;
    return <CommentComponent />;
  };

  const deleteScreenshotHandler = (id: number) => {
    const beforeId = screenshotView.slice(0, id);
    const afterId = screenshotView.slice(id + 1);
    const newScreenshotView = [...beforeId, ...afterId];
    setScreenshotView(newScreenshotView);
    if (newScreenshotView.length === 0) closeScreenshot();

    const beforeId2 = screenshotUrls.slice(0, id);
    const afterId2 = screenshotUrls.slice(id + 1);
    const newScreenshotUrls = [...beforeId2, ...afterId2];
    setScreenshotUrls(newScreenshotUrls);

    deleteScreenshot(screenshotUrls[id]);
  };

  const result = useApi(getQuest, scriptId);

  const typeOfScript =
    result.data?.questType.id === 1
      ? scenarioTypes.scenario
      : scenarioTypes.bugs;

  // работа с сокетами
  const fromContext = useContext(SockContext);
  useEffect(() => {
    fromContext.setNotificationIsWork(false);
    return () => {
      fromContext.setNotificationIsWork(true);
    };
  }, [fromContext]);

  const getSortedSteps = (steps: QuestStep[]) => {
    return [...steps].sort((a, b) => a.number - b.number);
  };

  return (
    <TestComponent>
      {openScreenshots && screenshotView.length > 0 ? (
        <ScreenshotView
          type={typeOfScript}
          view={screenshotView}
          currentScreenshotIndex={currentScreenshotIndex}
          onClose={closeScreenshot}
          deleteScreenshotHandler={deleteScreenshotHandler}
        />
      ) : (
        <>
          <BackHeader
            withCancel
            backUrl={parentURL}
            cancelUrl={descriptionURL}
          />
          <div
            className="scriptContent"
            style={{
              height:
                typeOfScript === 'bugs'
                  ? isShowTextArea
                    ? 'calc(100% - 272px)'
                    : 'calc(100% - 128px)'
                  : isShowTextArea
                  ? 'calc(100% - 344px)'
                  : 'calc(100% - 200px)',
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              if (e.target === e.currentTarget) hideSendCommentSection();
            }}
          >
            <Api
              result={result}
              renderData={(data, { reFetch }) => (
                <>
                  <div>
                    <div className="title" onClick={hideSendCommentSection}>
                      {data.name}
                    </div>
                    <div
                      className="description"
                      onClick={hideSendCommentSection}
                    >
                      <div className="text">{data.fullDescription}</div>
                    </div>
                    {typeOfScript !== scenarioTypes.bugs && (
                      <div
                        className="stepsWrapper"
                        onClick={hideSendCommentSection}
                      >
                        {getSortedSteps(data.questSteps).map((step, index) => (
                          <div className="step" key={step.description + index}>
                            <div className="stepDescription">
                              {step.description}
                            </div>
                            {index !== data.questSteps.length - 1 && (
                              <div className="arrowDown">
                                <QaIcon name={'arrow-down'} />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <AddScreenshot
                      screenshotsArray={screenshotView}
                      screenshotUrls={screenshotUrls}
                      getCurrentIndex={getCurrentIndex}
                      addScreenshotHandler={addScreenshotHandler}
                    />
                    <RenderSendComment />
                  </div>
                </>
              )}
            />
          </div>
        </>
      )}
      <FooterAction
        scriptId={scriptId}
        screenshotView={screenshotUrls}
        openCommentSection={openCommentSection}
        isShowSendCommentButton={isShowSendCommentButton}
        updateFeedbackText={updateFeedbackText}
        isShowTextArea={isShowTextArea}
        isSendCommentClicked={isSendCommentClicked}
        isScreenshotsAdded={screenshotsCount > 0}
        type={typeOfScript}
        indicatorIndex={2}
        withFeedback
        withComment
      />
    </TestComponent>
  );
};

const TestComponent = styled.div`
  width: 100%;
  height: 100%;
  .scriptContent {
    & > div:last-child {
      margin-top: auto;
      margin-bottom: 30px !important;
    }
    & > div:first-child {
      margin-top: 24px !important;
    }
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 24px;
    height: calc(100% - 128px);
    width: 100%;
    overflow-y: scroll;
    -webkit-box-pack: justify;
    -webkit-overflow-scrolling: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .title {
    text-align: left;
    font-family: Geometria-Bold, sans-serif;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    width: 222px;
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 24px;
  }

  .description {
    position: relative;
    margin-bottom: 16px;
    width: 100%;
    .text {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      width: 206px;
    }
  }

  .stepsWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    .step {
      > * {
        margin-bottom: 8px;
      }
      .stepDescription {
        min-height: 20px !important;
      }
      .arrowDown {
        & > span {
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #f2994a;
        }
        min-height: 10px !important;
      }
      &:last-child {
        > {
          margin-bottom: 0 !important;
        }
      }
    }
  }
`;

export default Test;
