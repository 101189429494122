import * as api from '../internal';
import { Level } from '../../_types';

const path = 'levels';
export const getLevels = api.getList<Level>(path, api.sortLevels);
export const getLevel = api.getEntity<Level>(path);
export const createLevel = api.createEntity<CreateLevelArg, Level>(path);
export const updateLevel = api.updateEntity<UpdateLevelArg, Level>(path);
export const deleteLevel = api.deleteEntity(path);

export type CreateLevelArg = {
  image: string;
  name: string;
  pointsCount: number;
};
export type UpdateLevelArg = Partial<CreateLevelArg>;
