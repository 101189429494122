import { DefaultTheme } from 'styled-components/macro';

const theme: DefaultTheme = {
  borderRadius: 8,
  colors: {
    yellow: '#f2994a',
    black: '#35323b',
    red: '#eb5757',
    lightGrey: '#C2C2C2',
    grey: '#828282',
    greySpan: '#4f4f4f',
    imgBackground: '#e0e0e0',
    blueGrey: '#35323b',
    h2MainPageWhite: '#f2f2f2',
    progressBarBackground: '#4f4f4f',
    green: '#27AE60',
  },
};

export { theme };
