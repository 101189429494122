export const getData = () => [
  'Ты сегодня всех порвал,\nНу, а чтоб не уставал:\nНаш подарок принимай -\nНовый уровень хватай! ',
  'Вот это достижение!\nГромадное значение\nИ наше уважение\nРождают повышение!',
  'Слышишь? Это овации\nГению оптимизации.\nПыльцы скопилось немало,\nНаграда тебя застала!',
  'И это определенно победа!\nЖдешь нашего ты ответа?\nТомить мы не будем тогда -\nОдарим дарами сполна!',
  'На шаг ближе к цели\nНа этой неделе!\nТвой уровень тоже\nПоднялся, похоже!',
  'Готов к глобальному обмену?\nТогда найдем пыльце замену.\nДостойно новой степени\nТвое великолепие!',
  'Перед тобою лестница,\nДвижения предвестница.\nТы встал на новую ступень\nВ этот волшебный день!',
  'Вот это да, приятель!\nВысокий показатель\nЗаслуженно достался\nТому, кто так старался! ',
  'Для тебя хорошая новость:\nНесмотря на нашу суровость,\nТы достойно прошел этот путь\nВ новый уровень время шагнуть! ',
  'Твои старания и труд\nК победе всех нас приведут!\nИ для тебя наш дружный штаб\nГотов назначить level up!',
  'Пора разбить свою копилку\nИ получить в обмен посылку:\nМеняем старую пыльцу\nНа повышение храбрецу!',
  'Вновь дополнительный подъем -\nЛюбая трудность ни по чем\nНесокрушимому бойцу\nИ настоящему спецу!',
  'Слышишь шепот доброты?\nЭто значит, высоты\nТы другой достиг сегодня,\nУровень даем охотно!',
  'Сегодня празднуем с тобой\nНастрой, герой, твой боевой,\nЧто к новой степени привел!\nБольшой, однако, путь прошел! ',
  'Великолепная работа\nТакого непростого рода\nНаграды заслужила - факт!\nПыльцу на уровень, мастак!',
  'Ты готов услышать вердикт?\nЛучший ты для нас поставщик! \nОтличного настроения!\nНаграда имеет значение!',
  'И это был финальный\nРывок твой виртуальный\nК отметке выше прежнего, -\nВсе супер! Без погрешностей!',
  'Уверенными шагами,\nНе без трудностей временами\nК уровню новому ты дошел\nИ опыт бесценный, друг, приобрел!',
];
