import React, { FC, useEffect, useState } from 'react';

type ToastData = { timeoutID?: number; status?: string; message?: string };

type PushToastMessage = (status: string, message?: string) => void;

export const useToast = (): [ToastData, PushToastMessage] => {
  const [toastData, setToastData] = useState<ToastData>({});

  useEffect(() => {
    // toast timeout breaker
    return () =>
      toastData.timeoutID ? clearTimeout(toastData.timeoutID) : undefined;
  }, [toastData]);

  const pushMsg = (status: string, message?: string) => {
    if (toastData.timeoutID) clearTimeout(toastData.timeoutID);

    const timeoutID = setTimeout(() => {
      setToastData({});
    }, 2000);

    setToastData({ timeoutID, status, message });
  };
  return [toastData, pushMsg];
};

const MessageToast: FC<{ data: ToastData }> = ({ data }) => {
  if (data.message)
    return (
      <div style={{ margin: '0.1rem 0' }}>
        <p>{data.message}</p>
      </div>
    );

  switch ('' + data.status) {
    case '400':
      return <p>Что-то пошло не так. Попробуйте позже</p>;
    case '401':
      return <p>Проверьте код-пароль</p>;
    case '409':
      return <p>email уже зарегистрирован</p>;
    case '000':
      return <p>Ошибка соединения с сервером</p>;
    default:
      break;
  }
  return <p>&nbsp;</p>;
};

export default MessageToast;
