import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../_shared/types';
import { ReFetch } from '../../../_shared/Api';
import { getLevel, updateLevel } from '../../../../_api/controller';
import { Level } from '../../../../_types';
import {
  FormInputName,
  FormInputPointsCount,
} from '../../_shared/form/FormInput';
import FormInputImage from '../../_shared/form/FormInputImage';
import createEntityPage from '../../_shared/page/createEntityPage';
import createUpdateForm from '../../_shared/form/createUpdateForm';
import PageEdit from '../../_shared/page/PageEdit';

/** Apply component factory statically (out of render scope) */
const UpdateForm = createUpdateForm(updateLevel);

const EditForm_: StyledFC<{
  entityTypeName: string;
  entity: Level;
  reFetchList: ReFetch;
  children: {};
}> = ({ className, entityTypeName, entity, reFetchList }) => {
  const [name, setName] = useState(entity.name);
  const [pointsCount, setPointsCount] = useState(entity.pointsCount);
  const [image, setImage] = useState(entity.image);

  const isChanged = useCallback(
    () =>
      entity &&
      (name !== entity.name ||
        pointsCount !== entity.pointsCount ||
        image !== entity.image),
    [entity, name, pointsCount, image]
  );
  const validate = useCallback(
    () =>
      isChanged() && name != null && pointsCount != null && image != null
        ? { name, pointsCount, image }
        : undefined,
    [isChanged, name, pointsCount, image]
  );
  return (
    <PageEdit className={className} entityTypeName={entityTypeName}>
      <UpdateForm
        className={className}
        entity={entity}
        validate={validate}
        reFetchList={reFetchList}
      >
        <>
          <FormInputImage image={image} setImage={setImage} />
          <FormInputName state={name} setState={setName} />
          <FormInputPointsCount state={pointsCount} setState={setPointsCount} />
        </>
      </UpdateForm>
    </PageEdit>
  );
};
const EditForm = styled(EditForm_)`
  > .scroll-container > .scroll {
    grid-template-rows: 80px 60px 60px;
  }
`;

/** Apply component factory statically (out of render scope) */
const EditWoChildren = createEntityPage(getLevel, 'уровень', EditForm);

const Edit: StyledFC<{
  reFetchList: ReFetch;
}> = (props) => <EditWoChildren {...props}>{}</EditWoChildren>;

export default Edit;
