export const getData = () => [
  'Какие люди в Голливуде! Сплошные звёзды, а не люди...',
  'Счастлив снова встретиться! Без тебя этот день не был бы так хорош...',
  'Здравия желаю! Салютую твоей отваге.',
  'Бинго-бонго и Джимбо-джамбо, мой друг!',
  'Физкульт-привет! Готов покорять новые вершины? Тогда час в радость, трудяга!',
  'Неужели ты мой подарок на сегодня? С таким напарником, как за каменной стеной!',
  'Доброго времени суток! А ты хорошеешь не по дням, а по часам!',
  'Трям! — значит здравствуйте!',
  'Мы снова встретились, а значит: «День добрый!»',
  'Есть люди, с которыми приятно поздороваться дважды. Привет- привет!',
  'Не верю своим глазам! А ну-ка, протри-ка мне камеру.',
  'Стоп! Стоп! Стоп! Дай я тебя сфотографирую, иначе никто не поверит, что я тебя видел.',
  'Я думаю, это начало прекрасной дружбы!',
  'На связи! Желаю продуктивной работы и наилучших успехов сегодня!',
  'Ты, как вишенка на торте, всегда вызываешь восторг!',
  'Посылаю тебе тысячу приветствий! Какие новости на сегодня?',
  'Да пребудет с тобой сила!',
  'О, здравствуй, мой драгоценный друг! Отличный день для новых открытий, не находишь?',
  'А я-то думаю, что так ярко слепит глаза? А оказывается, это ты заскочил на огонек!',
  'Что-то внутри меня подсказывало, что мы сегодня встретимся! Возможно, материнская плата?',
];
