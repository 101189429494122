import React, { FC, useContext, useEffect, useState } from 'react';
import Feedback from './Feedback';
import Button from './Button';
import SendComment from './SendComment';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { questPass } from '../../../_api/controller';
import { useApiManual } from '../../_shared/Api';
import { SockContext } from '../../App/SockContext';

const FooterAction: FC<{
  screenshotView?: string[];
  isShowSendCommentButton?: boolean;
  isShowTextArea: boolean;
  isSendCommentClicked?: boolean;
  type: string;
  isScreenshotsAdded?: boolean;
  indicatorIndex: number;
  withComment?: boolean;
  withFeedback?: boolean;
  updateFeedbackText: (text: string) => void;
  openCommentSection: () => void;
  scriptId: number;
}> = ({
  screenshotView,
  openCommentSection,
  isShowSendCommentButton,
  updateFeedbackText,
  isShowTextArea,
  isSendCommentClicked,
  type,
  isScreenshotsAdded,
  indicatorIndex,
  withComment,
  withFeedback,
  scriptId,
}) => {
  const scenarioActiveIndicator = { background: '#ffb016' };
  const bugsActiveIndicator = { background: '#27AE60' };
  const defaultIndicator = { background: '#4f4f4f' };

  const [writingComment, setWritingComment] = useState(false);
  const [isAnswerClicked, setIsAnswerClicked] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [feedbackText, setFeedbackText] = useState('');

  const [enabledButton, setEnabledButton] = useState(true);

  const [pass, passState] = useApiManual(questPass);
  const cancel = passState.cancel;
  useEffect(() => {
    return () => {
      cancel();
    };
  }, [cancel]);

  useEffect(() => {
    if (isSendCommentClicked) commentClickHandler();
  });

  const historyPush = useHistory();
  const uploadSuccess =
    passState.error === undefined && passState.countOfCompletions > 0;

  const fromContext = useContext(SockContext);
  useEffect(() => {
    if (uploadSuccess) {
      fromContext.setNotificationIsWork(true);
    }
  }, [uploadSuccess, historyPush, fromContext]);

  const indicatorStyles = (index: number) =>
    index === indicatorIndex
      ? type.toUpperCase() === 'BUGS'
        ? bugsActiveIndicator
        : scenarioActiveIndicator
      : defaultIndicator;

  const commentClickHandler = () => {
    openCommentSection();
    setWritingComment(true);
  };

  const answerClickHandler = (bool: boolean) => {
    openCommentSection();
    setIsAnswerClicked(bool);
    setWritingComment(false);
  };

  const setCurAnswer = (answer: string) => {
    setCurrentAnswer(answer);
  };

  const updateFeedbackTextHandler = (text: string) => {
    updateFeedbackText(text);
    setFeedbackText(text);
  };

  const sendFeedback = () => {
    const feedback = {
      id: +scriptId,
      value: {
        comment: feedbackText,
        images: screenshotView,
        isSuccess: currentAnswer === 'yes',
      },
    };
    if (enabledButton) {
      pass(feedback);
      setEnabledButton(false);
    }
  };

  const RenderCommentButton = () => {
    if (withComment !== true) return null;
    if (type === 'scenario') {
      if (isShowTextArea && (!isAnswerClicked || feedbackText.length === 0))
        return null;
      else if (isShowTextArea && feedbackText.length === 0) return null;
    }
    if (
      (isShowSendCommentButton &&
        (feedbackText.length || isScreenshotsAdded)) ||
      type === 'scenario'
    )
      if (isShowTextArea && feedbackText.length)
        return <Button text="Готово" onClick={sendFeedback} type={type} />;
      else
        return (
          <SendComment
            commentClickHandler={commentClickHandler}
            isInsideFooter
          />
        );
    else return null;
  };

  return (
    <Footer>
      {withFeedback && (
        <Feedback
          isShowTextArea={isShowTextArea}
          writingComment={writingComment}
          type={type}
          answerClicked={answerClickHandler}
          currentAnswer={currentAnswer}
          setCurAnswer={setCurAnswer}
          updateFeedbackText={updateFeedbackTextHandler}
        />
      )}
      <div className="indicatorSection">
        <div className="indicatorWrapper">
          <div className="indicator" style={indicatorStyles(1)}>
            {' '}
          </div>
          <div className="indicator" style={indicatorStyles(2)}>
            {' '}
          </div>
        </div>
        <RenderCommentButton />
      </div>
    </Footer>
  );
};

const Footer = styled.div`
  width: 100%;
  border-radius: 0 0 23px 23px;
  background-color: #35323b;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  min-width: 320px;

  .indicatorSection {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .indicatorWrapper {
      display: flex;

      .indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        background: #4f4f4f;
      }
    }
  }
`;

export default FooterAction;
