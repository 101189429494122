import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { StyledFC } from '../../_shared/types';
import { useMe } from '../../_shared';
import { icons } from '../_shared/bag_icons';
import BagParticles from '../../../_sass/icon/gif_bag_particles.gif';
import Image from '../../_shared/Image';

const Bag_: StyledFC<{ levelName: string }> = ({ className, levelName }) => {
  const me = useMe();
  const bag =
    levelName === '0 %'
      ? me?.team.color === '#EB5757'
        ? icons.bag_red_1
        : icons.bag_blue_1
      : levelName === '25 %'
      ? me?.team.color === '#EB5757'
        ? icons.bag_red_2
        : icons.bag_blue_2
      : levelName === '50 %'
      ? me?.team.color === '#EB5757'
        ? icons.bag_red_3
        : icons.bag_blue_3
      : levelName === '75 %'
      ? me?.team.color === '#EB5757'
        ? icons.bag_red_4
        : icons.bag_blue_4
      : me?.team.color === '#EB5757'
      ? icons.bag_red_5
      : icons.bag_blue_5;
  return (
    <div className={className}>
      <Link to="main/season">
        <Image className="bag" src={bag} />
        <Image className="bag-particles" src={BagParticles} />
      </Link>
    </div>
  );
};

const Bag = styled(Bag_)`
  position: relative;
  display: block;
  align-self: center;
  justify-self: center;
  width: 95%;
  height: 100%;
  margin-left: 5%;
  min-height: 300px;

  & > a {
    display: block;
    width: 100%;
    height: 100%;

    & > .bag {
      /** Back light */
      background-image: radial-gradient(
        circle closest-side at 50% 65%,
        #ffffff59 0%,
        #7777775c 45%,
        #0000 100%
      );
    }

    & > .bag-particles {
      position: absolute;
      top: 20%;
      left: 30%;
      width: 35%;
      height: 35%;

      opacity: 0.9;
    }
  }
`;

export default Bag;
