import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import QaIcon from '../../../_shared/QaIcon';
import styled from 'styled-components/macro';

const WrapComponent = styled.div`
  .wrapper {
    .back {
      span {
        position: absolute;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #333;
        left: -4px;
      }

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 6%;
      cursor: pointer;
      text-decoration: none;
    }

    & > * {
      width: auto;
      margin-bottom: 24px;
    }

    & > :last-child {
      max-height: calc(94% - 24px);
      margin-bottom: inherit;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 32px 7%;
    word-wrap: anywhere;
  }

  display: flex;
  border-radius: 32px;
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  max-width: 360px;
  width: calc(100% - 16px);
  max-height: 737px;
  height: 90%;
  margin: 8px;
  color: #333;
`;

const ScreenWrap: FC<{
  isLoading: boolean;
  children: React.ReactNode;
  redirect: string | { pathname: string; state: { menu: boolean } };
}> = ({ isLoading, children, redirect: dest }) => {
  return (
    <WrapComponent>
      <div className="wrapper">
        <Link
          className="back"
          to={dest}
          onClick={(e) => (isLoading ? e.preventDefault() : null)}
        >
          <QaIcon name={'back'} />
        </Link>
        {children}
      </div>
    </WrapComponent>
  );
};

export default ScreenWrap;
