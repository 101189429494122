import { Id, SeasonGoalLevel } from '../../_types';
import * as api from '../internal';

const path = 'seasons/goal-levels';
export type CreateSeasonGoalLevelArg = {
  name: string;
  pointsCount: number;
  seasonId: Id;
  image: string;
};
export type UpdateSeasonGoalLevelArg = {
  // name?: string;
  pointsCount?: number;
  seasonId?: Id;
  // image: string;
};
export const getSeasonGoalLevel = api.getEntity<SeasonGoalLevel>(path);
export const createSeasonGoalLevel = api.createEntity<
  CreateSeasonGoalLevelArg,
  SeasonGoalLevel
>(path);
export const updateSeasonGoalLevel = api.updateEntity<
  UpdateSeasonGoalLevelArg,
  SeasonGoalLevel
>(path);
export const deleteSeasonGoalLevel = api.deleteEntity(path);
