import * as api from '../internal';
import { Id, RoleName } from '../../_types';
import { User } from '../../_types';
import { CancelToken } from 'axios';
import { UpdateArg } from '../internal';
import API from '../axios';

const path = 'users';
export const getUsers = api.getSearchPage<User>(path);
export const getUser = api.getEntity<User>(path);
export const updateUser = api.updateEntity<UpdateUserArg, User>(path);

export const setUserBlocked = (arg: {
  id: Id;
  value: { isBlocked: boolean };
}) =>
  api.put<{ isBlocked: boolean }, User>(`${path}/${arg.id}/is-block`)({
    isBlocked: arg.value.isBlocked,
  });

export const inviteUsers = (emails: string[]) =>
  api.post<{ emails: string[] }, void>(`${path}/invite`)({ emails });

export type UpdateUserArg = {
  name?: string;
  password?: string;
  email?: string;
  avatar?: string;
  role?: string;
};

export type UpdateUserPermissionsArg = {
  isBlocked: boolean;
  roleName: RoleName;
};
export const updateUserPermissions = (
  arg: UpdateArg<{ isBlocked: boolean; roleName: RoleName }>
) => (token?: CancelToken) =>
  Promise.all([
    setUserBlocked({ id: arg.id, value: { isBlocked: arg.value.isBlocked } })(
      token
    ),
    updateUser({ id: arg.id, value: { role: String(arg.value.roleName) } })(
      token
    ),
  ]).then(([_, user]) => user);

export const updateUserPassword = (data: { id: Id; password: string }) => (
  token?: CancelToken
) =>
  API.post<void>(
    `${path}/${data.id}/change-password`,
    { password: data.password },
    {
      cancelToken: token,
    }
  ).then((res) => res?.data);

export const verifyUserEmail = (data: { userId: Id; password: string }) => (
  token?: CancelToken
) =>
  API.post<{ refreshToken: string; token: string }>(
    `${path}/email/verify`,
    { userId: data.userId, password: data.password }
    /*{
  cancelToken: token,
}*/
  ).then((res) => res?.data);
//
