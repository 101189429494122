import React, { useEffect } from 'react';
import { StyledFC } from '../../_shared/types';
import { ApiStateManual } from '../../_shared/Api';
import ApiManual from '../../_shared/ApiManual';
import styled from 'styled-components/macro';
import { LoadingIcon } from './files';

const ApiManualButton_: StyledFC<{
  required?: boolean;
  state: ApiStateManual<any>;
  onClick: () => void;
  onSuccess?: () => void;
  title: string;
  disabled?: boolean;
  loading?: JSX.Element;
}> = ({ className, title, state, onClick, onSuccess, disabled, loading }) => {
  const done = state.error === undefined && state.countOfCompletions > 0;
  useEffect(() => {
    if (done) {
      onSuccess?.();
    }
  }, [done, onSuccess]);
  return (
    <button
      className={className}
      onClick={() => onClick()}
      disabled={disabled === true || state.isLoading}
    >
      <ApiManual
        result={state}
        renderInit={({ isLoading }) => showTitle(loading, isLoading, title)}
        renderData={(_, { isLoading }) => showTitle(loading, isLoading, title)}
      />
    </button>
  );
};

const showTitle = (
  loading: JSX.Element | undefined,
  isLoading: boolean,
  title: string
): JSX.Element =>
  isLoading ? loading || <LoadingIcon color="white" /> : <>{title}</>;

const ApiManualButton = styled(ApiManualButton_)`
  font-size: 14px;
  font-weight: bold;
  background: none;
  color: white;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-width: 2px;
  padding: 0.46em 1em 0.54em;
  &:disabled {
    color: ${({ theme }) => theme.colors.lightGrey};
    border-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export default ApiManualButton;
