import React, { FC } from 'react';
import styled from 'styled-components/macro';
import ListItemIndicator from './ListItemIndicator';
import ListItemIcon from './ListItemIcon';
import { Link } from 'react-router-dom';

const ListItem: FC<{
  className: string;
  position: number;
  name: string;
  score: number;
  isMyRating: boolean;
  avatar: string;
  color: string;
  levelIcon: string;
}> = ({
  className,
  position,
  name,
  score,
  isMyRating,
  avatar,
  color,
  levelIcon,
}) => {
  const itemText = `${position}. ${name}`;

  return isMyRating ? (
    <Link to="/profile">
      <MyListItemComponent className={className}>
        <ListItemIndicator me color={color} />
        <div className="listItemContent">
          <ListItemIcon
            avatar={avatar}
            className="listItemIcon"
            isMyRating={isMyRating}
            levelIcon={levelIcon}
          />
          <div className="contentText">
            <span className="itemText">{itemText}</span>
            <div className="itemScore">{score}</div>
          </div>
        </div>
      </MyListItemComponent>
    </Link>
  ) : (
    <ListItemComponent className={className}>
      <ListItemIndicator color={color} />
      <div className="listItemContent">
        <ListItemIcon
          avatar={avatar}
          className="listItemIcon"
          isMyRating={isMyRating}
          levelIcon={levelIcon}
        />
        <span className="itemText">{itemText}</span>
        <div className="itemScore">{getSplitScore(score)}</div>
      </div>
    </ListItemComponent>
  );
};

const getSplitScore = (score: number) => {
  const scoreStr = score.toString();
  let result = [];
  for (let i = 0; i < scoreStr.length + 1; i++) {
    result.unshift(scoreStr[scoreStr.length - i]);
    if (i % 3 === 0) result.unshift(' ');
  }
  return result;
};

const ListItemComponent = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  .listItemIcon {
    margin-right: 8px;
  }
  .listItemContent {
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    margin-left: 9px;
    background-color: white;
    box-shadow: 0 6px 16px rgba(40, 43, 47, 0.1);
    .itemText {
      font-family: Geometria, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 140%;
      color: #35323b;
    }
    .itemScore {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 120%;
      color: #35323b;
      margin-left: auto;
    }
  }
`;

const MyListItemComponent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 74px !important;
  .listItemIcon {
    margin-right: 8px;
  }
  .listItemContent {
    padding-left: 100px;
    position: relative;
    height: 74px;
    display: flex;
    background: #35323b;
    border-radius: 16px;
    color: #fff;
    align-items: center;
    width: 100%;
    box-shadow: 0 6px 16px rgba(40, 43, 47, 0.1);
    .contentText {
      display: flex;
      flex-direction: column;
    }
    .itemText {
      font-family: Geometria, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 140%;
    }
    .itemScore {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 120%;
      color: #828282;
      opacity: 0.64;
    }
    .myListItemIndicator {
      position: absolute;
    }
  }
`;

export default ListItem;
