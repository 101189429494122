import { StyledFC } from '../../../_shared/types';
import Page from './Page';
import styled from 'styled-components/macro';
import React from 'react';

const PageNew_: StyledFC<{ entityTypeName: string }> = ({
  className,
  entityTypeName,
  children,
}) => {
  return (
    <Page className={className} title={`Новый ${entityTypeName}`} upLevel={1}>
      {children}
    </Page>
  );
};

const PageNew = styled(PageNew_)`
  grid-template-rows: 80px 60px auto;
  > :last-child {
    height: 100%;
  }
`;
export default PageNew;
