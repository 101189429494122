import React, { FC } from 'react';

import Levels from './Levels';
import Bag from './Bag';
import Bugs from './Bugs';
import Page from '../_shared/page/Page';
import Tabs from '../../_shared/Tabs';

const Balance: FC = () => (
  <Page title="Баланс">
    <Tabs default="levels">
      {{
        levels: { title: 'Уровни', render: (show) => <Levels show={show} /> },
        bag: { title: 'Мешочки', render: (show) => <Bag show={show} /> },
        bugs: { title: 'Баги', render: (show) => <Bugs show={show} /> },
      }}
    </Tabs>
  </Page>
);

export default Balance;
