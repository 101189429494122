import React, { useCallback, useState } from 'react';
import { StyledFC } from '../../../_shared/types';
import { ReFetch } from '../../../_shared/Api';
import { Id, SeasonGoalLevel } from '../../../../_types';
import {
  FormInputName,
  FormInputPointsCount,
} from '../../_shared/form/FormInput';
import createEntityPage from '../../_shared/page/createEntityPage';
import {
  getSeasonGoalLevel,
  updateSeasonGoalLevel,
} from '../../../../_api/controller';
import styled from 'styled-components/macro';
import createUpdateForm from '../../_shared/form/createUpdateForm';
import PageEdit from '../../_shared/page/PageEdit';

/** Apply component factory statically (out of render scope) */
const UpdateForm = createUpdateForm(updateSeasonGoalLevel);

const EditForm_: StyledFC<{
  entityTypeName: string;
  entity: SeasonGoalLevel;
  reFetchList: ReFetch;
  children: { seasonId: Id };
}> = ({
  className,
  entityTypeName,
  children: { seasonId },
  entity,
  reFetchList,
}) => {
  const [name, setName] = useState(entity.name);
  const [pointsCount, setPointsCount] = useState(entity.pointsCount);
  // const [image] = useState(entity.image);

  const isChanged = useCallback(
    () =>
      entity &&
      // name !== entity.name ||
      pointsCount !== entity.pointsCount,
    // || image !== entity.image
    [
      entity,
      // name,
      pointsCount,
      // , image
    ]
  );
  const validate = useCallback(
    () =>
      isChanged() &&
      // && name != null
      pointsCount != null
        ? // && image != null
          {
            seasonId,
            name,
            pointsCount,
            // , image
          }
        : undefined,
    [
      isChanged,
      seasonId,
      name,
      pointsCount,
      // , image
    ]
  );
  return (
    <PageEdit className={className} entityTypeName={entityTypeName}>
      <UpdateForm
        className={className}
        entity={entity}
        validate={validate}
        reFetchList={reFetchList}
      >
        <>
          {/*<FormInputImage image={image} setImage={setImage} />*/}
          <FormInputName disabled state={name} setState={setName} />
          <FormInputPointsCount state={pointsCount} setState={setPointsCount} />
        </>
      </UpdateForm>
    </PageEdit>
  );
};

const EditForm = styled(EditForm_)`
  > .scroll-container > .scroll {
    grid-template-rows: 80px 60px 60px;
  }
`;

/** Apply component factory statically (out of render scope) */
const Edit = createEntityPage<SeasonGoalLevel, { seasonId: Id }>(
  getSeasonGoalLevel,
  'уровень',
  EditForm
);

export default Edit;
