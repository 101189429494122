import React, { FC } from 'react';

const IconComment: FC<{ onClick?: () => void; fill?: string }> = ({ fill }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.6556 2.34478C10.5296 -0.781469 5.47007 -0.781719 2.34378 2.34478C-0.292372 4.98106 -0.683372 8.95313 1.06378 11.9875L0.021878 15.362C-0.0902157 15.725 0.255409 16.0647 0.61669 15.9453L3.88903 14.8643C6.9006 16.6714 10.9533 16.3597 13.6556 13.6573C16.7816 10.5311 16.7819 5.47125 13.6556 2.34478ZM4.25032 5.68878C4.50916 5.68878 4.719 5.89863 4.719 6.15747C4.719 6.41631 4.50916 6.62616 4.25032 6.62616C3.99147 6.62616 3.78163 6.41631 3.78163 6.15747C3.78163 5.89863 3.99147 5.68878 4.25032 5.68878ZM9.87438 10.3445H4.25032C3.99147 10.3445 3.78163 10.1347 3.78163 9.87584C3.78163 9.617 3.99147 9.40716 4.25032 9.40716H9.87438C10.1332 9.40716 10.3431 9.617 10.3431 9.87584C10.343 10.1347 10.1332 10.3445 9.87438 10.3445ZM11.7491 10.3445C11.4902 10.3445 11.2804 10.1347 11.2804 9.87584C11.2804 9.617 11.4903 9.40716 11.7491 9.40716C12.0079 9.40716 12.2178 9.617 12.2178 9.87584C12.2178 10.1347 12.0079 10.3445 11.7491 10.3445ZM11.7491 8.46972H4.25032C3.99147 8.46972 3.78163 8.25988 3.78163 8.00103C3.78163 7.74219 3.99147 7.53234 4.25032 7.53234H11.7491C12.0079 7.53234 12.2178 7.74219 12.2178 8.00103C12.2178 8.25988 12.0079 8.46972 11.7491 8.46972ZM11.7491 6.59494H6.125C5.86616 6.59494 5.65631 6.38509 5.65631 6.12625C5.65631 5.86741 5.86616 5.65756 6.125 5.65756H11.7491C12.0079 5.65756 12.2178 5.86741 12.2178 6.12625C12.2178 6.38509 12.0079 6.59494 11.7491 6.59494Z"
          fill={fill || '#BDBDBD'}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconComment;
