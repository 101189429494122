import React, { useCallback, useState } from 'react';
import { StyledFC } from '../../../_shared/types';
import createEntityPage from '../../_shared/page/createEntityPage';
import { getQuestType, updateQuestType } from '../../../../_api/controller';
import { QuestType } from '../../../../_types';
import { ReFetch } from '../../../_shared/Api';
import FormInputImage from '../../_shared/form/FormInputImage';
import {
  FormInputName,
  FormInputPointsCount,
  FormInputString,
} from '../../_shared/form/FormInput';
import styled from 'styled-components/macro';
import createUpdateForm from '../../_shared/form/createUpdateForm';
import PageEdit from '../../_shared/page/PageEdit';

/** Apply component factory statically (out of render scope) */
const UpdateForm = createUpdateForm(updateQuestType);

const EditForm_: StyledFC<{
  entityTypeName: string;
  entity: QuestType;
  reFetchList: ReFetch;
  children: {};
}> = ({ className, entityTypeName, entity, reFetchList }) => {
  const [image, setImage] = useState(entity.image);
  const [name, setName] = useState(entity.name);
  const [points, setPoints] = useState(entity.points);
  const [pointsApproved, setPointsApproved] = useState(entity.pointsApproved);
  const [color, setColor] = useState(entity?.color);

  const isChanged = useCallback(
    () =>
      entity &&
      (image !== entity.image ||
        name !== entity.name ||
        points !== entity.points ||
        pointsApproved !== entity.pointsApproved ||
        color !== entity.color),
    [entity, image, name, points, pointsApproved, color]
  );
  const validate = useCallback(
    () =>
      isChanged() &&
      image != null &&
      name != null &&
      points != null &&
      pointsApproved != null &&
      color != null
        ? { image, name, points, pointsApproved, color }
        : undefined,
    [isChanged, image, name, points, pointsApproved, color]
  );
  return (
    <PageEdit className={className} entityTypeName={entityTypeName}>
      <UpdateForm
        className={className}
        entity={entity}
        validate={validate}
        reFetchList={reFetchList}
      >
        <>
          <FormInputImage image={image} setImage={setImage} />
          <FormInputName state={name} setState={setName} />
          <FormInputPointsCount
            id="points"
            label="Пыльца за выполнение"
            state={points}
            setState={setPoints}
          />
          <FormInputPointsCount
            id="pointsApproved"
            label="Пыльца после проверки"
            state={pointsApproved}
            setState={setPointsApproved}
          />
          <FormInputString
            label={`Цвет карточки`}
            id="color"
            state={color}
            setState={setColor}
          />
        </>
      </UpdateForm>
    </PageEdit>
  );
};

const EditForm = styled(EditForm_)`
  > .scroll-container > .scroll {
    grid-template-rows: 80px 60px 60px 60px 60px;
  }
`;

/** Apply component factory statically (out of render scope) */
const EditWoChildren = createEntityPage(getQuestType, 'сценарий/баг', EditForm);

const Edit: StyledFC<{
  reFetchList: ReFetch;
}> = (props) => <EditWoChildren {...props}>{}</EditWoChildren>;

export default Edit;
