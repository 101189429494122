import React from 'react';
import styled from 'styled-components/macro';
import { Quest } from '../../../_types';
import { ago, parseMembers } from '../_util';
import { ListState } from '../_shared/EntityList';
import { ReFetch } from '../../_shared/Api';
import { StyledFC } from '../../_shared/types';
import EntityListItem from '../_shared/EntityListItem';
import OverlayButtons from '../_shared/overlay-buttons/OverlayButtons';
import EditButton from '../_shared/overlay-buttons/EditButton';
import DeleteButton from '../_shared/overlay-buttons/DeleteButton';
import ViewButton from '../_shared/overlay-buttons/ViewButton';

const ListItem_: StyledFC<{
  listState: ListState;
  entity: Quest;
  index: number;
  reFetch: ReFetch;
}> = ({ className, listState, entity, index, reFetch }) => {
  const members = parseMembers(entity.userCountQuestPass);

  return (
    <EntityListItem
      className={className}
      listState={listState}
      entityId={entity.id}
      renderButtons={(isSelected, setSelected, size) => {
        const before = () => setSelected(false);
        return (
          <OverlayButtons isVisible={isSelected} size={size}>
            {[
              <ViewButton
                key="view"
                entityId={entity.id}
                size={size}
                before={before}
              />,
              <EditButton
                key="edit"
                entityId={entity.id}
                size={size}
                before={before}
              />,
              <DeleteButton
                key="delete"
                entityId={entity.id}
                size={size}
                before={before}
              />,
            ]}
          </OverlayButtons>
        );
      }}
    >
      <p className="header">{`${entity.name}`}</p>
      <p className="description">{entity.description}</p>
      <p className="information">
        <span>
          {members.count} участника{members.ending}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span>
          {entity.endDate.getTime() > Date.now()
            ? ago(entity.endDate)
            : 'Время тестирования вышло'}
        </span>
      </p>
    </EntityListItem>
  );
};

const ListItem = styled(ListItem_)`
  position: relative;
  > .data {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    font-family: 'Geometria', sans-serif;
    > .header {
      text-transform: uppercase;
      font-weight: bold;
    }
    > .description {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.grey};
    }
    > .information {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export default ListItem;
