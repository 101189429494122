import React, { FC, useEffect, useState } from 'react';
import Button from './Button';
import styled from 'styled-components/macro';

const Feedback: FC<{
  isShowTextArea: boolean;
  updateFeedbackText: (text: string) => void;
  type?: string;
  writingComment: boolean;
  answerClicked: (bool: boolean) => void;
  setCurAnswer: (answer: string) => void;
  currentAnswer: string;
}> = ({
  isShowTextArea,
  updateFeedbackText,
  type,
  writingComment,
  answerClicked,
  setCurAnswer,
  currentAnswer,
}) => {
  const [areaText, setAreaText] = useState('');
  const [textPlaceholder, setTextPlaceholder] = useState(
    'Опиши, что пошло не так'
  );

  useEffect(() => {
    if (writingComment) setTextPlaceholder('Оставьте комментарий');
  }, [writingComment, setTextPlaceholder]);

  const setText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    setAreaText(text);
    updateFeedbackText(text);
  };

  const yesClickHandler = () => {
    answerClicked(true);
    setCurAnswer('yes');
    setTextPlaceholder('Будем благодарны за оставленный фидбек');
  };

  const noClickHandler = () => {
    answerClicked(true);
    setCurAnswer('no');
    setTextPlaceholder('Опишите, что пошло не так');
  };

  const getButtonStyle = (type: string) => {
    if (currentAnswer === null || currentAnswer !== type.toString())
      return undefined;
    return {
      backgroundColor: '#f2994a',
      color: '#35323B',
    };
  };

  return (
    <FeedbackComponent
      style={{ paddingTop: type?.toLowerCase() !== 'bugs' ? 24 : 0 }}
    >
      {type?.toLowerCase() !== 'bugs' && (
        <div className="feedbackAnswer">
          <span className="text">Удалось пройти по сценарию?</span>
          <div className="buttonsWrapper">
            <Button
              onClick={() => yesClickHandler()}
              text="Да"
              type="scenario"
              addStyle={getButtonStyle('yes')}
              insideFeedback
            />
            <Button
              onClick={() => noClickHandler()}
              text="Нет"
              type="scenario"
              addStyle={getButtonStyle('no')}
              insideFeedback
            />
          </div>
        </div>
      )}
      {isShowTextArea ? (
        <textarea
          placeholder={textPlaceholder}
          onChange={setText}
          value={areaText}
          className="feedbackText"
        >
          {' '}
        </textarea>
      ) : null}
    </FeedbackComponent>
  );
};

const FeedbackComponent = styled.div`
  background-color: #35323b;
  color: #f2f2f2;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  .feedbackAnswer {
    padding-bottom: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .text {
    width: 157px;
    font-size: 16px;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #f2f2f2;
  }

  .buttonsWrapper {
    display: flex;

    button:first-child {
      margin-right: 8px;
    }
  }

  .feedbackText {
    margin-top: 24px;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 120px;
    background-color: transparent;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    resize: none;
    color: #e0e0e0;
    padding: 8px 16px;
    outline: none;
    &::-webkit-input-placeholder {
      /* Edge */
      color: #e0e0e0;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e0e0e0;
    }

    &::placeholder {
      color: #e0e0e0;
    }
  }
`;

export default Feedback;
