import { CancelToken } from 'axios';
import API from '../axios';

const path = 'file';

export const fileUpload = (data: FormData) => (token?: CancelToken) =>
  API.post<{ fileName: string }>(`${path}/upload`, data, {
    cancelToken: token,
  }).then((res) => res.data);

export const fileDelete = (fileName: string) => (token?: CancelToken) =>
  API.post<void>(`${path}/delete`, undefined, {
    cancelToken: token,
    params: {
      fileName,
    },
  }).then(() => undefined);

export const fileUrl = (fileName: string) =>
  `${process.env.REACT_APP_BASE_PATH}uploads/${fileName}`;
