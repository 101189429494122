import React from 'react';
import { ApiView } from './types';
import Loader from '../Loader';
import ErrorMessage from './ErrorMessage';
export * from './hooks';
export * from './types';

export const Api: ApiView = ({
  result,
  renderInit,
  renderData,
  renderError,
  // todo: isErrorOverflowData: boolean
}) => {
  const { data, error, isLoading } = result;
  return error ? (
    renderError ? (
      renderError(result)
    ) : (
      <ErrorMessage>{error.message}</ErrorMessage>
    )
  ) : data === undefined && isLoading ? (
    renderInit ? (
      renderInit(result)
    ) : (
      <Loader />
    )
  ) : data ? (
    renderData(data, result) || null
  ) : null;
};

export default Api;
