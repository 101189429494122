import { Entity, Int, Me } from '../../_types';
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GetPage } from '../../_api';
import { useApi } from './Api';
import { ApiState } from './Api';
import { Page } from '../../_api';
import uniqWith from 'lodash/uniqWith';

export const useCancelSource = (): CancelTokenSource => {
  const [cancelSource] = useState<CancelTokenSource>(
    axios.CancelToken.source()
  );
  /** Cancel request on component unmount */
  useEffect(() => {
    return () => cancelSource.cancel();
  }, [cancelSource]); // TODO: Should be in deps?
  return cancelSource;
};

export const MeContext = createContext<
  { me: Me; reFetch: () => void } | undefined
>(undefined);

export const useMe = (): Me => {
  const ctx = useContext(MeContext);
  if (ctx === undefined) throw new Error(`Пожалуйста, войдите в систему`);
  else return ctx.me;
};

export const useReFetchMe = (): (() => void) => {
  const ctx = useContext(MeContext);
  if (ctx === undefined) throw new Error(`Пожалуйста, войдите в систему`);
  else return ctx.reFetch;
};

export const useGoUp = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const pathItems = useMemo(
    () => match.path.split('/').filter((x) => x.length > 0),
    [match]
  );
  const goUp = useCallback(
    (level: Int) => {
      if (level <= 0 || level > pathItems.length)
        throw new Error(
          `Invalid up level: Expected (0, lengthOf(${pathItems.join(
            '/'
          )})], actual ${level}`
        );
      const to = '/' + pathItems.slice(0, -level).join('/');
      history.push(to);
    },
    [pathItems, history]
  );
  return goUp;
};

export const useStateWithSource: <T>(
  sourceState: T | undefined
) => [T | undefined, Dispatch<T>] = (current) => {
  const [state, setState] = useState(current);
  useEffect(() => {
    if (current !== undefined) {
      setState(current);
    }
  }, [current, setState]);
  return [state, setState];
};

export type ScrollState<Entity> = {
  apiState: ApiState<Page<Entity>>;
  list: Entity[];
  onScroll: (e: React.UIEvent<HTMLDivElement>) => void;
  onScrollEnd: () => void;
  reset: () => void;
};
export const useScroll = <E extends Entity>(
  api: GetPage<E>,
  pageSize?: Int
): ScrollState<E> => {
  const [page, setPage] = useState(0);
  const state = useApi(api, { page, size: pageSize });
  const totalPages = state.data?.totalPages;
  const items = state.data?.items;
  const pageLoaded = state.data?.page;
  const [list, setList] = useState<E[]>([]);
  useEffect(() => {
    if (items !== undefined && pageLoaded !== undefined) {
      setList((accList) =>
        uniqWith([...accList, ...items], (a: E, b: E) => a.id === b.id)
      );
    }
  }, [pageLoaded, items]);
  const onScrollEnd = useCallback(() => {
    if (totalPages && page < totalPages - 1) {
      setPage(page + 1);
    }
  }, [page, setPage, totalPages]);
  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      e.preventDefault();
      const el = e.currentTarget;
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        onScrollEnd?.();
      }
    },
    [onScrollEnd]
  );
  const reFetch = state.reFetch;
  const reset = useCallback(() => {
    setList([]);
    setPage(0);
    reFetch();
  }, [setList, setPage, reFetch]);
  return { apiState: state, list, onScroll, onScrollEnd, reset };
};

const cssWindowlistener = () => {
  const wh = window.innerHeight * 0.01;
  const ww = window.innerWidth * 0.01;
  document.documentElement.style.setProperty('--ww', `${ww}px`);
  document.documentElement.style.setProperty('--wh', `${wh}px`);
};

export const useCssWindow = () => {
  useEffect(() => {
    cssWindowlistener();
    window.addEventListener('resize', cssWindowlistener);
    return () => window.removeEventListener('resize', cssWindowlistener);
  }, []);
};
