import { Int } from '../../../_types';

export * from './ago';

export const parseMembers = (num: Int): { count: Int; ending: string } => {
  const str = num.toString().slice(-1);
  switch (str) {
    case '1':
      return { count: num, ending: '' };
    case '2':
    case '3':
    case '4':
      return { count: num, ending: 'а' };
    default:
      return { count: num, ending: 'ов' };
  }
};
