import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import Api, { ReFetch, useApiWoParams } from '../../_shared/Api';
import {
  FormInputEndDate,
  FormInputName,
  FormInputStartDate,
  FormInputString,
} from '../_shared/form/FormInput';
import { getQuestSystems } from '../../../_api/controller/quest-system';
import {
  createQuestWithSteps,
  CreateQuestWithStepsArg,
} from '../../../_api/controller';
import { Quest, QuestSystem, QuestType } from '../../../_types';
import ActionForm from '../_shared/form/ActionForm';
import createPageNew from '../_shared/page/createPageNew';
import FormTextArea from '../_shared/form/FormTextArea';
import SystemEditor from './SystemEditor';
import TypeInput from './TypeInput';
import StepsEditor from './StepsEditor';
import { Route, useRouteMatch } from 'react-router-dom';
import SystemDialog from './SystemDialog';

const CustomForm = styled(ActionForm)`
  > .scroll-container > .scroll {
    grid-template-rows: 60px 120px 60px 60px 65px 65px 80px auto;
  }
`;

/** Apply component factory statically (out of render scope) */
const PageNewQuest = createPageNew<CreateQuestWithStepsArg, Quest>(
  createQuestWithSteps,
  'квест',
  CustomForm
);

const New: StyledFC<{ reFetchList: ReFetch }> = ({
  className,
  reFetchList,
}) => {
  const match = useRouteMatch();

  const systemsState = useApiWoParams(getQuestSystems);

  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [link, setLink] = useState<string>();
  const [questSystem, setQuestSystem] = useState<QuestSystem>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [questType, setQuestType] = useState<QuestType>();
  const [fullDescription, setFullDescription] = useState<string>();
  const [stepDescriptions, setStepDescriptions] = useState<string[]>([]);

  const validate = useCallback(
    (): CreateQuestWithStepsArg | undefined =>
      name != null &&
      description != null &&
      link != null &&
      questSystem != null &&
      startDate != null &&
      endDate != null &&
      questType != null &&
      fullDescription != null
        ? {
            quest: {
              name,
              description,
              fullDescription,
              link,
              questSystemId: questSystem.id,
              startDate,
              endDate,
              questTypeId: questType.id,
            },
            stepDescriptions: questType.withSteps ? stepDescriptions : [],
          }
        : undefined,
    [
      name,
      description,
      link,
      questSystem,
      startDate,
      endDate,
      questType,
      fullDescription,
      stepDescriptions,
    ]
  );
  return (
    <PageNewQuest
      className={className}
      validate={validate}
      reFetch={reFetchList}
    >
      <Api
        result={systemsState}
        renderData={(systems, { reFetch: reFetchSystems }) => (
          <>
            <FormInputName state={name} setState={setName} />
            <FormTextArea
              label={`Краткое описание`}
              id="description"
              state={description}
              setState={setDescription}
            />
            <FormInputString
              label={`Ссылка на приложение`}
              id="link"
              state={link}
              setState={setLink}
            />
            <SystemEditor
              systems={systems}
              state={questSystem}
              setState={setQuestSystem}
            />
            <FormInputStartDate
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
            />
            <FormInputEndDate
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <TypeInput state={questType} setState={setQuestType} />
            <FormTextArea
              label={`Полное описание`}
              id="fullDescription"
              state={fullDescription}
              setState={setFullDescription}
            />
            {questType?.withSteps && (
              <StepsEditor
                steps={stepDescriptions}
                setSteps={setStepDescriptions}
                isAddRemoveEnabled={true}
              />
            )}
            <Route
              path={`${match.path}/newSystem`}
              render={() => (
                <SystemDialog
                  setSelected={setQuestSystem}
                  resetSelected={() => setQuestSystem(questSystem)}
                  reFetchList={reFetchSystems}
                />
              )}
            />
          </>
        )}
      />
    </PageNewQuest>
  );
};
export default New;
