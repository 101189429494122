import React, { useEffect } from 'react';
import { StyledFC } from '../../../_shared/types';
import PageNew from '../../_shared/page/PageNew';
import { useGoUp } from '../../../_shared';
import { ReFetch, useApiManual } from '../../../_shared/Api';
import ActionForm from '../../_shared/form/ActionForm';
import { CreateEntity } from '../../../../_api';

/** Component factory.
 * Disables react-hooks/rules-of-hooks
 * */
const createPageNew: <CreateArg, Entity>(
  createEntity: CreateEntity<CreateArg, Entity>,
  entityTypeName: string,
  CustomForm?: typeof ActionForm
) => StyledFC<{
  reFetch?: ReFetch;
  validate: () => CreateArg | undefined;
}> = (createEntity, entityTypeName, CustomForm = ActionForm) => ({
  className,
  reFetch,
  children,
  validate,
}) => {
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const goUp = useGoUp();
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const [create, createState] = useApiManual(createEntity);
  const createDone =
    createState.error == null && createState.countOfCompletions > 0;
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  useEffect(() => {
    if (createDone) {
      reFetch?.();
      goUp(1);
    }
  }, [createDone, reFetch, goUp]);
  return (
    <PageNew className={className} entityTypeName={entityTypeName}>
      <CustomForm
        type="new"
        actionState={createState}
        enabled={validate() != null}
        onSubmit={() => {
          const arg = validate();
          if (arg) create(arg);
        }}
      >
        {children}
      </CustomForm>
    </PageNew>
  );
};
export default createPageNew;
