import api from '../_api';
import { Dispatch } from 'redux';
import { CancelToken } from 'axios';
import { Me, RoleName, User } from '../../../_types';

export type SuccessAction<T, P = undefined> = { type: T; payload: P };

export type FailureAction<T> = { type: T; payload: Error };

/** Set Sign In Data */
export type SetSignInDataPayload =
  | {
      accessToken: string;
    }
  | {
      refreshToken: string;
    }
  | {
      agreement: boolean;
    }
  | {
      email: string;
    }
  | {
      password: string;
    }
  | {};
export type SetSignInData = SuccessAction<
  'SET_SIGN_IN_DATA',
  SetSignInDataPayload
>;

/** Sign In */
export type SignInPayload = {
  accessToken: string;
  refreshToken: string;
  user: Me;
};
export type SignInAction =
  | SuccessAction<'SIGN_IN_SUCCESS', SignInPayload>
  | FailureAction<'SIGN_IN_FAILURE'>;

export type RestoreAction =
  | SuccessAction<'RESTORE_SUCCESS'>
  | FailureAction<'RESTORE_FAILURE'>;

/** Sign Up */
export type SignUpAction =
  | SuccessAction<'SIGN_UP_SUCCESS'>
  | FailureAction<'SIGN_UP_FAILURE'>;

/** Refresh Token */
export type RefreshTokenPayload = { accessToken: string; refreshToken: string };
export type RefreshTokenAction =
  | SuccessAction<'REFRESH_TOKEN_SUCCESS', RefreshTokenPayload>
  | FailureAction<'REFRESH_TOKEN_FAILURE'>;

/** Sign Out */
export type SignOutAction =
  | SuccessAction<'SIGN_OUT'>
  | FailureAction<'SIGN_OUT_FAILURE'>;

export type AuthAction =
  | SetSignInData
  | SignInAction
  | SignUpAction
  | RefreshTokenAction
  | SignOutAction
  | RestoreAction;

export const isSuperAdmin = (user: User): boolean =>
  user.roles.filter((role) => role.name === RoleName.ROLE_SUPER_ADMIN).length >
  0;

export const isAdmin = (user: User): boolean =>
  user.roles.filter(
    (role) =>
      role.name === RoleName.ROLE_SUPER_ADMIN ||
      role.name === RoleName.ROLE_ADMIN ||
      role.name === RoleName.ROLE_MANAGER
  ).length > 0;

export const signIn = (email: string, password: string, token: CancelToken) => (
  dispatch: Dispatch<SignInAction>
) => {
  return new Promise<SignInPayload>((resolve, reject) =>
    api
      .signIn(email, password, token)
      .then((res: { data: SignInPayload }) => {
        dispatch({
          type: 'SIGN_IN_SUCCESS',
          payload: {
            ...res.data,
            user: { ...res.data.user, isAdmin: isAdmin(res.data.user) },
          },
        });
        resolve(res.data);
      })
      .catch((error) => {
        dispatch({
          type: 'SIGN_IN_FAILURE',
          payload: error,
        });
        reject(error);
      })
  );
};

export const restore = (email: string, token: CancelToken) => (
  dispatch: Dispatch<RestoreAction>
) => {
  return new Promise<undefined>((resolve, reject) =>
    api
      .restore(email, token)
      .then(() => {
        dispatch({
          type: 'RESTORE_SUCCESS',
          payload: undefined,
        });
        resolve();
      })
      .catch((error) => {
        dispatch({
          type: 'RESTORE_FAILURE',
          payload: error,
        });
        reject(error);
      })
  );
};

export const signUp = (email: string, token: CancelToken) => (
  dispatch: Dispatch<SignUpAction>
) => {
  return new Promise<undefined>((resolve, reject) =>
    api
      .signUp(email, token)
      .then(() => {
        dispatch({ type: 'SIGN_UP_SUCCESS', payload: undefined });
        resolve();
      })
      .catch((error) => {
        dispatch({
          type: 'SIGN_UP_FAILURE',
          payload: error,
        });
        reject(error);
      })
  );
};

const refreshToken = (refreshToken: string) => (
  dispatch: Dispatch<RefreshTokenAction>
) => {
  return new Promise<RefreshTokenPayload>((resolve, reject) =>
    api
      .refreshToken(refreshToken)
      .then((res: { data: RefreshTokenPayload }) => {
        dispatch({
          type: 'REFRESH_TOKEN_SUCCESS',
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((error: Error) => {
        dispatch({
          type: 'REFRESH_TOKEN_FAILURE',
          payload: error,
        });
        // dispatch({ type: 'SIGN_OUT', payload: undefined });
        reject(error);
      })
  );
};

export const signOut = (refreshToken: string) => (
  dispatch: Dispatch<SignOutAction>
) => {
  return new Promise<undefined>((resolve, reject) =>
    api
      .signOut(refreshToken)
      .then(() => {
        dispatch({ type: 'SIGN_OUT', payload: undefined });
        resolve();
      })
      .catch((error: Error) => {
        dispatch({
          type: 'SIGN_OUT_FAILURE',
          payload: error,
        });
        reject(error);
      })
  );
};

export const setData = (payload: SetSignInDataPayload) => (
  dispatch: Dispatch<SetSignInData>
) => {
  return dispatch({
    type: 'SET_SIGN_IN_DATA',
    payload,
  });
};

export const authActions = {
  setData,
  signIn,
  signUp,
  refreshToken,
  signOut,
  restore,
};
