import React from 'react';

import Image from '../_shared/congrats-images/Empty Image@2x.png';
import CircleImage from '../_shared/congrats-images/background-target.png';
import ProgressBarBadge from '../_shared/progress-bar-badge';
import CongratsScreen from '../_shared/congrats-screen';
import { getData } from './textrepo';

const Target = () => {
  const textrepo = getData();
  const message = textrepo[Math.floor(Math.random() * textrepo.length)]
    .split('\n')
    .map((m, i) => (
      <React.Fragment key={i}>
        {m}
        <br />
      </React.Fragment>
    ));

  const data = {
    image: Image,
    circleImage: CircleImage,
    header: 'Цель достигнута',
    message,
    action: 'Восторг',
  };

  return (
    <CongratsScreen {...data}>
      <ProgressBarBadge />
    </CongratsScreen>
  );
};

export default Target;
