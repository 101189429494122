import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from './types';

const Image_: StyledFC<{ bg?: string; src: string }> = ({ className }) => (
  <div className={className}>
    <div className="image" />
  </div>
);

const Image = styled(Image_)`
  display: block;
  background-image: ${({ bg }) => (bg ? `url(${bg})` : 'none')};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  & > .image {
    align-self: center;
    justify-self: center;
    background-image: ${({ src }) => `url(${src})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }
`;

export default Image;
