import React, { FC, useEffect } from 'react';
import { useRouteMatch, useHistory, Route } from 'react-router-dom';
import { useApiManual } from '../../../_shared/Api';
import { updateUser } from '../../../../_api/controller';
import { useReFetchMe, useMe } from '../../../_shared';
import { Id } from '../../../../_types';
import ApproveDialog from '../ApproveDialog';

const AskEmailDialog: FC<{
  userId: Id;

  setOldEmail: React.Dispatch<React.SetStateAction<string>>;
  setNewEmail: React.Dispatch<React.SetStateAction<string>>;
  newEmail: string;
}> = ({ userId, setOldEmail, setNewEmail, newEmail }) => {
  const match = useRouteMatch();
  const reFetchMe = useReFetchMe();
  const me = useMe();
  const [updateBlurEmail, updateStateBlurEmail] = useApiManual(updateUser);

  const updateDoneBlurEmail =
    updateStateBlurEmail.error == null &&
    updateStateBlurEmail.countOfCompletions > 0;
  useEffect(() => {
    if (updateDoneBlurEmail) {
      reFetchMe();
    }
  }, [updateDoneBlurEmail, reFetchMe]);

  const history = useHistory();

  return (
    <>
      <div className="wrap-modal">
        <div className="modal-bg">
          <div className="modal-window">
            <div className="modal-content">
              <h3>Изменить адрес корпоративной почты?</h3>
              <p>
                Мы вышлем новый код-пароль <br /> для подтверждения e-mail
              </p>
              <div className="buttons">
                <button
                  className="black"
                  onClick={() => {
                    setNewEmail(me.email);
                    history.go(-1);
                  }}
                >
                  Отменить
                </button>
                <button
                  onClick={() => {
                    updateBlurEmail({
                      id: userId,
                      value: { email: newEmail },
                    });
                    history.push(`${match.url}/approve`);
                  }}
                >
                  Заменить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Route
        path={`${match.url}/approve`}
        render={() => (
          <ApproveDialog
            setOldEmail={setOldEmail}
            setNewEmail={setNewEmail}
            newEmail={newEmail}
          />
        )}
      />
    </>
  );
};

export default AskEmailDialog;
