import React from 'react';
import { StyledFC } from '../../_shared/types';
import styled from 'styled-components/macro';
import { getQuest } from '../../../_api';
import { Quest } from '../../../_types';
import createEntityPage from '../_shared/page/createEntityPage';
import PageView from '../_shared/page/PageView';
import FooterAction from '../_shared/FooterAction';
import { ago, parseMembers } from '../_util';
import ReportInput from './ReportInput';
import { exportReportOfQuest } from '../../../_api/controller/report';
import DownloadButton from '../_shared/files/DownloadButton';
import Scroll from '../_shared/Scroll';

const QuestView_: StyledFC<{
  entity: Quest;
  children: {};
}> = ({ className, entity }) => {
  const members = parseMembers(entity.userCountQuestPass);
  const isTimeout = entity.endDate.getTime() <= Date.now();
  return (
    <PageView className={className} title={entity.name}>
      <Scroll>
        <div className="full-description">{entity.fullDescription}</div>
        <div className="testers-count">
          {members.count} тестер{members.ending} приняли участие
        </div>
        <div className={`quest-time ${isTimeout ? 'timeout' : ''}`}>
          {isTimeout
            ? `Время тестирования вышло`
            : `${ago(entity.endDate)} до окончания`}
        </div>
        <ReportInput />
      </Scroll>
      <FooterAction>
        <DownloadButton
          className="download-button"
          title="Получить отчёт"
          api={exportReportOfQuest(entity.id)}
        />
      </FooterAction>
    </PageView>
  );
};

const QuestView = styled(QuestView_)`
  grid-template-rows: 80px 60px auto 72px;
  .download-button {
    padding: 9px 10px 11px;
  }
  > .scroll-container {
    height: inherit;
    > .scroll {
      grid-template-rows: fit-content() 40px 40px auto;
      font-family: Geometria, sans-serif;
      font-size: 14px;
      grid-gap: 1em;

      > .full-description {
        //margin-bo: 1em 0;
      }
      > .testers-count {
        font-weight: bold;
      }
      > .quest-time {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.yellow};
        &.timeout {
          color: ${({ theme }) => theme.colors.green};
        }
      }
    }
  }
  .action {
    font-size: 14px;
    font-weight: bold;
    background: none;
    &:disabled {
      color: ${({ theme }) => theme.colors.lightGrey};
      border-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`;

/** Apply component factory statically (out of render scope) */
const ViewWoChildren = createEntityPage(getQuest, 'квест', QuestView);

const View: StyledFC = (props) => (
  <ViewWoChildren reFetchList={() => {}} {...props}>
    {}
  </ViewWoChildren>
);

export default View;
