import { StyledFC } from '../../../_shared/types';
import React, { Dispatch } from 'react';

const createRadio: <T>() => StyledFC<{
  label: string;
  id: T;
  group: string;
  state: T | undefined;
  setState: Dispatch<T>;
  disabled?: boolean;
}> = () => ({ label, id, group, state, setState, disabled }) => (
  <div key={String(id)}>
    <input
      type="radio"
      id={String(id)}
      name={group}
      checked={state === id}
      onChange={(e) => {
        if (e.target.checked) setState(id);
      }}
      disabled={disabled}
    />
    <label htmlFor={String(id)}>{label}</label>
  </div>
);

export default createRadio;
