export const dateToString = (date: Date, format: string) => {
  // this polyfill is grabbed from https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
  // eslint-disable-next-line
  String.prototype.padStart = function (targetLength, padString) {
    targetLength = targetLength >> 0;
    padString = String(padString || ' ');
    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length);
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };

  let divider;
  switch (true) {
    case format.includes('.'):
      divider = '.';
      break;
    default:
      throw new TypeError("format doesn't contain divider");
  }

  format = format.split(divider).join('');

  const dd = date.getDate().toString().padStart(2, '0');
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const yyyy = date.getFullYear().toString();

  switch (format) {
    case 'DDMMYYYY':
      return [dd, mm, yyyy].join(divider);
    default:
      throw new TypeError('incorrect date format');
  }
};

export const eventTypes = {
  TRANSITION_NEW_LEVEL: 'Новый уровень!',
  QUEST_REJECTED_ADMIN: 'Квест окончен',
  QUEST_CONFIRMED_ADMIN: 'Новый квест',
  QUEST_COMPLETED_USER: 'Квест завершен',
  RECEIVED_NEW_BADGE: 'Получен новый значек!',
} as {
  [key: string]: string;
};
