import React, { FC } from 'react';
import '../../_sass/_fonts/qa/style.css';
import styled from 'styled-components/macro';

export type QaIconName =
  | 'admin'
  | 'back'
  | 'bag'
  | 'ban'
  | 'calendar'
  | 'close'
  | 'edit'
  | 'home'
  | 'leaderboard'
  | 'menu'
  | 'notification'
  | 'question'
  | 'question-2'
  | 'rejected'
  | 'signout'
  | 'timer'
  | 'trash'
  | 'up'
  | 'add'
  | 'arrow-down'
  | 'points-plus'
  | 'points-circle'
  | 'login-mail'
  | 'login-pass'
  | 'login-restore'
  | 'login-contour'
  | 'eye'
  | 'lightning';

const QaIcon_: FC<{ className?: string; name: QaIconName }> = ({
  className,
  name,
}) => <span className={`${className} qa-icon icon-${name}`} />;

const QaIcon = styled(QaIcon_)`
  //text-decoration: none !important;
`;

export default QaIcon;
