import { Entity } from '../../../../_types';
import { UpdateEntity } from '../../../../_api';
import { StyledFC } from '../../../_shared/types';
import { ReFetch, useApiManual } from '../../../_shared/Api';
import { useGoUp } from '../../../_shared';
import React, { useEffect } from 'react';
import ActionForm from './ActionForm';

const createUpdateForm: <UpdateArg, E extends Entity>(
  updateEntity: UpdateEntity<UpdateArg, E>
) => StyledFC<{
  entity: E;
  reFetchList: ReFetch;
  validate: () => UpdateArg | undefined;
}> = (updateEntity) => ({
  className,
  entity,
  reFetchList,
  children,
  validate,
}) => {
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const goUp = useGoUp();
  const [update, updateState] = useApiManual(updateEntity);
  const updateDone =
    updateState.error == null && updateState.countOfCompletions > 0;
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  useEffect(() => {
    if (updateDone) {
      reFetchList?.();
      goUp(2);
    }
  }, [updateDone, reFetchList, goUp]);
  return (
    <ActionForm
      className={className}
      type="edit"
      actionState={updateState}
      enabled={validate() != null}
      onSubmit={() => {
        const value = validate();
        if (value) update({ id: entity.id, value });
      }}
    >
      {children}
    </ActionForm>
  );
};
export default createUpdateForm;
