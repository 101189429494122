import React from 'react';
import { ApiViewManual } from './Api';
import Loader from './Loader';
import ErrorMessage from './Api/ErrorMessage';
export * from './hooks';
export * from './types';

// todo: Better design Api & ApiManual
export const ApiManual: ApiViewManual = ({
  result,
  renderInit,
  renderData,
  renderError,
  // todo: isErrorOverflowData: boolean
}) => {
  const { data, error } = result;
  return error ? (
    renderError ? (
      renderError(result)
    ) : (
      <ErrorMessage>{error.message}</ErrorMessage>
    )
  ) : data === undefined ? (
    renderInit ? (
      renderInit(result)
    ) : (
      <Loader />
    )
  ) : (
    renderData(data, result)
  );
};

export default ApiManual;
