import { StyledFC } from '../../../_shared/types';
import Page from './Page';
import styled from 'styled-components/macro';
import React from 'react';

const EditPage_: StyledFC<{ entityTypeName: string }> = ({
  className,
  entityTypeName,
  children,
}) => {
  return (
    <Page className={className} title="Редактирование" upLevel={2}>
      {children}
    </Page>
  );
};

const PageEdit = styled(EditPage_)`
  grid-template-rows: 80px 60px auto;
  > :last-child {
    height: 100%;
  }
`;
export default PageEdit;
