import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import { Quest } from '../../../_types';
import { Link } from 'react-router-dom';
import { ago } from '../../admin/_util';
import { ReactComponent as IconPoints } from '../_shared/card_res/Points.svg';
import { ReactComponent as IconTimer } from '../_shared/card_res/icon_timer.svg';
import ScriptImage from '../_shared/card_res/Task_script@2x.png';
import BugImage from '../_shared/card_res/Tsk_bug@2x.png';

const ListItem_: StyledFC<{ entity: Quest }> = ({ className, entity }) => (
  <div
    className={className}
    style={{ backgroundColor: entity.questType.color }}
  >
    <Link to={`/script/${entity.id}/description`}>
      <p className="title">{entity.questType.name}</p>
      <div className="script-info">
        <div className="backplate">
          <img
            src={entity.questType.id === 1 ? ScriptImage : BugImage}
            alt="#"
          />
        </div>
        <p>{entity.name}</p>
      </div>
      <div className="bottom-liner">
        <div className="points">
          <span>{entity.questType.points}</span>
          <IconPoints />
        </div>
        <div className="timer">
          <p>{entity.questSystem.name}</p>&nbsp;
          <IconTimer />
          &nbsp;
          <p>{ago(entity.endDate)}</p>
        </div>
      </div>
    </Link>
  </div>
);

const ListItem = styled(ListItem_)`
  a {
    .title {
      text-transform: uppercase;
      font-family: 'Geometria-Medium';
      font-size: 14px;
    }

    .script-info {
      & > p {
        font-family: 'Geometria-Bold';
        font-size: 24px;
        width: 58%;
        word-break: break-word;
        margin-right: auto;
      }

      .backplate {
        & > img {
          height: auto;
          width: 124px;
          transform: scale(1.1);
          position: absolute;
          left: calc(78% - 62px);
          top: -5%;
        }

        position: absolute;
        height: 124px;
        width: calc(105% + 64px);
        background-image: radial-gradient(
          ellipse closest-side at 78% 82%,
          #0006,
          rgba(0, 0, 0, 0)
        );
      }

      position: relative;
      display: flex;
      justify-content: center;
      min-height: 116px;
    }

    .bottom-liner {
      div svg {
        filter: invert(100%);
        height: 32px;
        width: 32px;
      }

      .points {
        span {
          position: absolute;
          font-size: 12px;
          margin-left: 10%;
        }

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .timer {
        svg {
          height: 16px;
          width: 16px;
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        display: flex;
        align-items: center;
      }

      display: flex;
      align-items: center;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
    }

    & > * {
      width: 100%;
    }

    p {
      display: initial;
      margin: 8px 0;
    }

    display: flex;
    flex-direction: column;
    color: white;
    text-decoration: none;
    text-align: left;
  }

  position: relative;
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.25);
`;

export default ListItem;
