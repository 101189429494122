import React from 'react';
import { useLocation } from 'react-router-dom';

import { Api, useApi, useApiWoParams } from '../../../_shared/Api';
import { fileUrl } from '../../../../_api/controller/file';
import { getLevels, getUser } from '../../../../_api/controller';
import { useMe } from '../../../_shared';
import { Level } from '../../../../_types';
import Particles from '../../../../_sass/icon/gif_bar_particles.gif';
import styled from 'styled-components/macro';

const BadgeComponent = styled.div`
  .wrapper {
    .badge-data {
      & > img {
        width: 42px;
        height: 44px;
        margin: auto 0;
      }

      & > div {
        .header {
          font-family: 'Geometria-ExtraBold';
          color: #fff;
          text-transform: uppercase;
        }

        .points {
          & > span {
            color: #f2994a;
          }

          font-family: 'Geometria-Bold';
          color: #828282;
        }

        & > * {
          margin: 0;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 10px;
        line-height: 1.2;
        margin: 0 12px;
      }

      display: flex;
      flex-grow: 1;
      height: 72%;
    }

    .progress-bar {
      .fill {
        .ghosting {
          position: absolute;
          height: 100%;
          width: 40px;

          background-image: linear-gradient(
            to left,
            #ffffff,
            rgba(242, 153, 74, 0)
          );
        }

        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        background-color: #f2994a;
      }

      img {
        position: absolute;
        height: 24px;
        width: 12px;
      }

      & > div {
        position: absolute;
        width: 25%;
        height: 100%;
        border-radius: 16px;
      }

      display: flex;
      align-items: center;
      position: relative;
      border-radius: 16px;
      height: 4px;
      background-color: #4f4f4f;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 102%;
  background-color: #35323b;
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 3.5% 4.5%;
  padding-top: 1.5%;
`;

type LocationProps = {
  gained: number | undefined;
};

const ProgressBarBadge = () => {
  const { gained }: LocationProps = (useLocation()?.state as {
    gained: number;
  }) || { gained: 0 };

  const userID: number = useMe()?.id;

  const getCurrentLevel = (levels: Level[], points: number): Level =>
    levels.reverse().filter((x: Level) => x.pointsCount <= points)[0];

  const getNextLevel = (levels: Level[], points: number): Level =>
    levels.reverse().filter((x: Level) => x.pointsCount > points)[0];

  const user = useApi(getUser, userID);
  const levels = useApiWoParams(getLevels);

  return (
    <BadgeComponent>
      <Api
        result={user}
        renderData={(userData) => (
          <Api
            result={levels}
            renderData={(levelData) => {
              const points = userData?.points;
              const ld = getCurrentLevel(levelData, points);
              const image = ld?.image;
              const name = ld?.name;
              const start = ld?.pointsCount;

              const end = getNextLevel(levelData, points)?.pointsCount;
              const percent =
                Math.round(((points - start) / (end - start)) * 100) + '%';

              return (
                <div className="wrapper">
                  <div className="badge-data">
                    <img src={image ? fileUrl(image) : '#'} alt="#" />
                    <div>
                      <p className="header">{name}</p>
                      <p className="points">
                        {points.toLocaleString('ru-RU')}
                        {gained ? (
                          <>
                            &nbsp;<span>+{gained}</span>
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="progress-bar">
                    <div className="fill" style={{ width: percent }}>
                      <div className="ghosting" />
                    </div>
                    <img
                      src={Particles}
                      alt="#"
                      style={{ left: `calc(${percent} - 6px)` }}
                    />
                  </div>
                </div>
              );
            }}
          />
        )}
      />
    </BadgeComponent>
  );
};

export default ProgressBarBadge;
