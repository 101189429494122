import { StyledFC } from '../../../_shared/types';
import React, { Dispatch } from 'react';
import styled from 'styled-components/macro';

const Checkbox_: StyledFC<{
  id: string;
  state: boolean | undefined;
  setState: Dispatch<boolean>;
  label: string;
  disabled?: boolean;
}> = ({ className, id, state, label, setState, disabled }) => {
  return (
    <div
      className={className}
      onClick={() => {
        if (!disabled) {
          // console.log('setState', !state);
          setState(!state);
        }
      }}
    >
      <input
        className="custom-checkbox"
        type="checkbox"
        id={id}
        defaultChecked={state}
        disabled={disabled}
        onChange={(e) => {
          console.log(id, e.target.checked);
          setState(e.target.checked);
        }}
      />
      <label htmlFor={id} className={disabled ? 'disabled' : ''}>
        {label}
      </label>
    </div>
  );
};

const Checkbox = styled(Checkbox_)`
  > .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  > .custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }
  > .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  > .custom-checkbox:checked + label::before {
    border-color: ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.yellow};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
  /* стили при наведении курсора на checkbox */
  > .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: #b3d7ff;
  }
  /* стили для активного состояния чекбокса (при нажатии на него) */
  > .custom-checkbox:not(:disabled):active + label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }
  /* стили для чекбокса, находящегося в фокусе */
  > .custom-checkbox:focus + label::before {
    //box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
  }
  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  > .custom-checkbox:focus:not(:checked) + label::before {
    border-color: #80bdff;
  }
  /* стили для чекбокса, находящегося в состоянии disabled */
  > .custom-checkbox:disabled + label::before {
    background-color: #e9ecef;
  }
  > label.disabled {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

export default Checkbox;
