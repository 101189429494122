import React, { FC } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

export const RouteNew: FC<{ children: () => JSX.Element }> = ({ children }) => {
  const match = useRouteMatch();
  return <Route path={`${match.path}/new`} render={children} />;
};

export const RouteView: FC<{ children: () => JSX.Element }> = ({
  children,
}) => {
  const match = useRouteMatch();
  return <Route exact path={`${match.path}/:id/view`} render={children} />;
};

export const RouteEdit: FC<{ children: () => JSX.Element }> = ({
  children,
}) => {
  const match = useRouteMatch();
  return <Route path={`${match.path}/:id/edit`} render={children} />;
};

export const RouteDelete: FC<{ children: () => JSX.Element }> = ({
  children,
}) => {
  const match = useRouteMatch();
  return <Route exact path={`${match.path}/:id/delete`} render={children} />;
};
