import React, { useEffect, useState } from 'react';

import ProgressBarBadge from '../_shared/progress-bar-badge';
import CongratsScreen from '../_shared/congrats-screen';
import { useLocation } from 'react-router-dom';
import { fileUrl } from '../../../_api/controller/file';

type LocationProps = {
  achievementImage: string | undefined;
  achievementDescription: string | undefined;
};

const NewBadge = () => {
  const [achievImage, setAchievementImage] = useState('');
  const [achievDescription, setAchievementDescription] = useState('');

  const data = {
    image: achievImage,
    circleImage: '',
    header: 'Получен новый бейдж',
    message: achievDescription,
    action: 'Восторг',
  };

  const {
    achievementImage,
    achievementDescription,
  }: LocationProps = (useLocation()?.state as {
    achievementImage: string;
    achievementDescription: string;
  }) || {
    achievementImage: 'Badge_2_Easter.png',
    achievementDescription: 'Описание по-умолчанию',
  };

  useEffect(() => {
    setAchievementImage(fileUrl(achievementImage));
    setAchievementDescription(achievementDescription);
  }, [achievementImage, achievementDescription]);

  return (
    <CongratsScreen {...data}>
      <ProgressBarBadge />
    </CongratsScreen>
  );
};

export default NewBadge;
