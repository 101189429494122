import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const minHeight = '32px';

const Tab = styled(NavLink)`
  &.checked {
    background-color: ${({ theme }) => theme.colors.blueGrey};
    color: #fff;
  }
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${minHeight};
  width: 100%;
  height: 100%;
  input {
    display: none;
  }
`;

const Tabs_: FC<{
  className?: string;
  items: { key: string; title: string }[];
}> = ({ className, items }) => (
  <div className={className}>
    {items.map(({ key, title }) => (
      <Tab key={key} to={key} activeClassName="checked">
        {title}
      </Tab>
    ))}
  </div>
);

const TabsHeader = styled(Tabs_)`
  //width: 100%;
  //height: 100%;
  // min-height: ${minHeight};
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  //flex-direction: row;
  text-align: center;
  font-family: Geometria-Medium, sans-serif;
  font-weight: 500;
  line-height: 1.4;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  overflow: hidden;
  box-shadow: 0 6px 16px 0 rgba(40, 43, 47, 0.1);
  user-select: none;
  margin: 8px 16px;
`;

export default TabsHeader;
