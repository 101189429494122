import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import Api from '../../_shared/Api';
import ListItem from './ListItem';
import { useScroll } from '../../_shared';
import { getClientQuests } from '../../../_api/controller';

const Cards_: StyledFC = ({ className }) => {
  const { apiState, list, onScrollEnd } = useScroll(getClientQuests, 3);
  useEffect(() => {
    const listener = (e: Event) => {
      const el = document.scrollingElement;
      if (el && el.scrollTop + el.clientHeight >= el.scrollHeight) {
        onScrollEnd?.();
      }
    };
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [onScrollEnd]);

  return (
    <Api
      result={apiState}
      renderData={() => (
        <div className={className}>
          <div
            className="data"
            onScroll={(e) => {
              console.log('Cards data scroll');
            }}
          >
            {list.map((item, i) => (
              <ListItem key={i} entity={item} />
            ))}
          </div>
        </div>
      )}
    />
  );
};

const Cards = styled(Cards_)`
  //position: absolute;
  //top: calc(var(--wh) * 100 - 1.5rem);
  position: relative;
  width: 100%;
  justify-self: center;

  display: block;

  & > div {
    width: 100%;

    &.data {
      margin-left: auto;
      margin-right: auto;
      width: 96%;
      max-width: 375px;
      justify-self: center;

      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5em;
    }
  }
`;

export default Cards;
