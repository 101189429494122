import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import QaIcon from '../../../_shared/QaIcon';
import './style.scss';

import EmptyAva from '../../_sass/empty_ava.png';
import EmptyStar from '../../_sass/empty_star.png';
import { fileUrl } from '../../../../_api/controller/file';
import { Level } from '../../../../_types';
import { useMe } from '../../../_shared';

/** текущий уровень */
const getCurrentLevel = (levels: Level[], points: number): Level =>
  [...levels]
    .sort((a, b) => b.pointsCount - a.pointsCount)
    .filter((x: Level) => x.pointsCount <= points)[0]; // TODO: Учесть пустой список после фильтра

/** следующий уровень */
const getNextLevel = (levels: Level[], points: number): Level =>
  [...levels]
    .sort((a, b) => a.pointsCount - b.pointsCount)
    .filter((x: Level) => x.pointsCount > points)[0]; // TODO: Учесть пустой список после фильтра

/** прогресс уровня */
const levelProgress = (levels: Level[], points: number): number => {
  const currentLevel = getCurrentLevel(levels, points);
  const nextLevel = getNextLevel(levels, points);

  return nextLevel
    ? ((points - currentLevel.pointsCount) /
        (nextLevel.pointsCount - currentLevel.pointsCount)) *
        100
    : 100;
};

const AvaCard: FC<{ levels: Level[] }> = ({ levels }) => {
  const match = useRouteMatch();
  const me = useMe();
  const points = me.points;

  const level = getCurrentLevel(levels, points);
  return (
    <div className="ava-card">
      <div className="ava">
        <img src={me.avatar ? fileUrl(me.avatar) : EmptyAva} alt={me.avatar} />
      </div>
      <div className="team-block">
        <div className="command-name">{me.team.name}</div>
        <div className="user-name">
          {me.name || 'Angry-Fluffy'}
          <Link className="icon-edit" to={`${match.url}/edit`}>
            <QaIcon name={'edit'} />
          </Link>
        </div>
        <div className="level-block">
          <div className="level-icon">
            <img src={level.image ? fileUrl(level.image) : EmptyStar} alt="" />
          </div>
          <div>
            <div className="level-text">
              {getCurrentLevel(levels, points).name}
            </div>

            <div className="points">{points}</div>
          </div>
        </div>
        <div className="progress">
          <div
            className="current"
            style={{
              width: levelProgress(levels, points) + '%',
            }}
          >
            <div className="ending" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvaCard;
