import React, { createContext, Dispatch, useContext, FC } from 'react';
import { StyledFC } from '../types';
import styled from 'styled-components/macro';
import Dialog from './Dialog';

export type ErrorContext = {
  error: Error | undefined;
  setError: Dispatch<Error | undefined>;
};

export const ErrorContext = createContext<ErrorContext>({
  error: undefined,
  setError: () => {
    throw new Error('ErrorContext not initialized');
  },
});

export const TestErrorButton: FC = () => (
  <ErrorContext.Consumer>
    {(ctx) => (
      <div>
        <button
          style={{ background: 'white', color: 'red' }}
          onClick={() => ctx.setError(new Error('Test'))}
        >
          Error!
        </button>
      </div>
    )}
  </ErrorContext.Consumer>
);

const ErrorDialog_: StyledFC = ({ className }) => {
  const ctx = useContext<ErrorContext>(ErrorContext);
  return ctx.error ? (
    <Dialog
      className={className}
      header="Что-то пошло не так"
      text={
        ctx.error ? `${ctx.error.name}: ${ctx.error.message}` : 'Нет описания'
      }
    >
      <div className="buttons">
        <button className="ok" onClick={() => ctx.setError(undefined)}>
          OK
        </button>
      </div>
    </Dialog>
  ) : null;
};

const ErrorDialog = styled(ErrorDialog_)`
  > .container > .window > .buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-column-gap: 1em;

    > .ok {
      padding: 1em;
      font-family: Geometria-Bold, sans-serif;
      font-size: 16px;
      border-radius: 12px;
      color: white;
      background-color: ${({ theme }) => theme.colors.blueGrey};

      box-shadow: 0 4px 16px 0 rgb(53, 50, 59, 0.5);
    }
  }

  > .container {
    z-index: 5;
  }

  // TODO: Why ::backdrop not working?
  //backdrop-filter: saturate(80%);
  //&::backdrop {
  //  background: red; //rgba(255, 0, 0, 0.25);
  //  //background-color: blue;
  //  position: fixed;
  //  left: 0;
  //  top: 0;
  //  right: 100%;
  //  bottom: 100%;
  //}
`;

export default ErrorDialog;
