import React, { FC } from 'react';
import QaIcon from '../../_shared/QaIcon';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const BackHeader: FC<{
  withCancel?: boolean;
  backUrl: string;
  cancelUrl?: string;
}> = ({ withCancel, backUrl, cancelUrl }) => {
  return (
    <Header>
      <Link to={`/${backUrl}`}>
        <QaIcon name={'back'} />
      </Link>
      {withCancel ? (
        <Link className="cancel-button" to={`/${cancelUrl}`}>
          Отменить
        </Link>
      ) : null}
    </Header>
  );
};

const Header = styled.div`
  margin-top: 28px !important;
  width: 100%;
  display: flex;
  height: 28px;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
    span {
      font-size: 1.1rem;
      line-height: 1.8rem;
    }
  }
  .cancel-button {
    font-family: Geometria, sans-serif;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: right;
    text-decoration: none;
    color: #4f4f4f;
  }
`;

export default BackHeader;
