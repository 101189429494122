import React from 'react';
import { StyledFC } from '../../../_shared/types';
import styled from 'styled-components/macro';
import FooterAction from '../FooterAction';

export type FormActionType = 'new' | 'edit';

const FooterFormAction_: StyledFC<{
  type: FormActionType;
  disabled?: boolean;
}> = ({ className, type, disabled }) => (
  <FooterAction className={className}>
    <input
      className="action"
      type="submit"
      disabled={disabled}
      value={type === 'new' ? 'Создать' : 'Изменить'}
    />
  </FooterAction>
);

const FooterFormAction = styled(FooterFormAction_)`
  > .action {
    background: none;
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px !important;
  }
`;

export default FooterFormAction;
