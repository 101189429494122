import React, { FC, useEffect, useState } from 'react';
import QaIcon from '../../_shared/QaIcon';
import styled from 'styled-components/macro';
import { useApiManual } from '../../_shared/Api';
import { fileUpload, fileUrl } from '../../../_api/controller/file';

const AddScreenshot: FC<{
  screenshotsArray: File[];
  addScreenshotHandler: (screenshots: File[], backUrls: string[]) => void;
  getCurrentIndex: (index: number) => void;
  screenshotUrls: string[];
}> = ({
  getCurrentIndex,
  screenshotsArray,
  addScreenshotHandler,
  screenshotUrls,
}) => {
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  const [upload, uploadState] = useApiManual(fileUpload);
  const [feedbackFilesUrls, setFeedbackFilesUrls] = useState(['']);
  const [currentFiles, setCurrentFiles] = useState([] as File[]);

  useEffect(() => {
    setFeedbackFilesUrls(screenshotUrls);
  }, [screenshotsArray, screenshotUrls]);

  const fileSelectedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    let isNotAllowable = false;
    if (files) {
      for (let file of files) if (file.size > 5242880) isNotAllowable = true;
      if (isNotAllowable) {
        alert('Размер каждого файла не должен превышать 5 мб');
        return;
      }
      files.length + screenshotsArray.length > 4
        ? alert('Общее количество загруженных скриншотов не должно превышать 4')
        : addAndUploadScreenshots([...files]);
    }
  };

  const addAndUploadScreenshots = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const data = new FormData();
      data.append('file', files[i]);
      upload(data);
    }
    setCurrentFiles([...currentFiles, ...files]);
  };

  useEffect(() => {
    if (feedbackFilesUrls[0] === '') feedbackFilesUrls.shift();
  }, [feedbackFilesUrls]);

  useEffect(() => {
    if (uploadState.data !== undefined) {
      const newFiles = feedbackFilesUrls;
      const file = uploadState.data.fileName;
      if (!newFiles.includes(file)) newFiles.push(file);
      setFeedbackFilesUrls(newFiles);
    }
    if (
      currentFiles.length >= feedbackFilesUrls.length &&
      currentFiles.length > 0
    ) {
      addScreenshotHandler(currentFiles, feedbackFilesUrls);
    }
    // eslint-disable-next-line
  }, [uploadState]);

  const inputFileHandler = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <AddScreenshotComponent>
        <div className="addScreenshotSection">
          <div className="addIconWrapper" onClick={inputFileHandler}>
            <QaIcon name={'add'} />
          </div>
          <input
            className="screenshotInput"
            ref={inputRef}
            type="file"
            multiple
            onChange={fileSelectedHandler}
            accept={['image/jpg', 'image/jpeg', 'image/png'].join(',')}
          />
          {screenshotsArray.length === 0 ? (
            <span className="text">
              Если что-то пошло не так, <br /> добавь скриншот
            </span>
          ) : (
            feedbackFilesUrls.map((screenshotUrl: string, index: number) => (
              <div
                key={fileUrl(screenshotUrl)}
                className="screenshot"
                onClick={(e) => {
                  e.stopPropagation();
                  getCurrentIndex(index);
                }}
              >
                <img src={fileUrl(screenshotUrl)} alt="sd" />
              </div>
            ))
          )}
        </div>
      </AddScreenshotComponent>
    </>
  );
};

const AddScreenshotComponent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: auto;
  .addScreenshotSection {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .addIconWrapper {
    border-radius: 8px;
    border: 2px dashed #4f4f4f;
    width: 64px;
    height: 64px;
    position: relative;
    margin-right: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 1.1rem;
    }
  }
  .screenshotInput {
    opacity: 0;
    width: 0;
  }
  .text {
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #828282;
  }
  .screenshot {
    position: relative;
    background: #ffffff;
    width: 40px;
    height: 64px;
    margin-right: 8px;
    img {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #4f4f4f;
      top: 0;
      right: 0;
      display: block;
      object-fit: cover;
      position: absolute;
      border-radius: 4px;
      width: 38px;
      height: 62px;
      cursor: pointer;
    }
  }
`;

export default AddScreenshot;
