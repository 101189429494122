import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import QaIcon from '../../_shared/QaIcon';

const NavButton_: StyledFC<{
  notificationsCount: number;
  isMenuOpen: boolean;
  onClick: () => void;
}> = ({ className, notificationsCount, isMenuOpen, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      {!isMenuOpen ? (
        <>
          <QaIcon name="menu" />
          {notificationsCount > 0 && <div className="alert" />}
        </>
      ) : (
        <QaIcon name="close" />
      )}
    </div>
  );
};

const MenuButton = styled(NavButton_)`
  position: relative;
  display: block;
  cursor: pointer;
  background-color: white;

  text-align: center;
  vertical-align: center;
  & > .qa-icon {
    color: #333333;
  }
  & > .icon-close {
    color: #eb5757;
  }

  & > .alert {
    position: absolute;
    top: -4px;
    right: -4px;
    height: calc(4px * 4);
    width: calc(4px * 4);
    border-radius: 100%;
    border: solid 2px #ffffff;
    background-color: #f2994a;
  }
`;

export default MenuButton;
