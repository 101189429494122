import * as api from '../internal';
import { Id, Quest, QuestStep } from '../../_types';
import { CancelToken } from 'axios';

import {
  createQuestStep,
  getStepsOfQuest,
  updateQuestStep,
} from './quest-step';

import * as rest from '../rest';

const path = 'quests';

export type CreateQuestArg = {
  name: string;
  description: string;
  fullDescription: string;
  link: string;
  startDate: Date;
  endDate: Date;
  questSystemId: Id;
  questTypeId: Id;
};
export type UpdateQuestArg =
  | CreateQuestArg
  | {
      name: string;
      description: string;
      fullDescription: string;
    };

export const getQuests = api.getPage<Quest>(path);
export const getClientQuests = api.getPage<Quest>(`${path}/client`);
export const getQuest = api.getEntity<Quest>(path);
export const createQuest = api.createEntity<CreateQuestArg, Quest>(path);
export const updateQuest = api.updateEntity<UpdateQuestArg, Quest>(path);
export const deleteQuest = api.deleteEntity(path);

export type QuestPassArg = {
  comment: string;
  images: string[] | undefined;
  isSuccess: boolean;
};
export const questPass = (arg: { id: Id; value: QuestPassArg }) =>
  rest.post<QuestPassArg, Quest>(`${path}/${arg.id}/pass`)(arg.value);

export const updateQuestSteps = (questId: Id, stepDescriptions: string[]) => (
  token?: CancelToken
): Promise<QuestStep[]> =>
  getStepsOfQuest(questId)(token).then((steps) => {
    const sortedSteps = [...steps].sort((a, b) => a.number - b.number);
    return Promise.all(
      stepDescriptions.map((d, i) => {
        const step = sortedSteps[i];
        return step === undefined
          ? createQuestStep({ description: d, number: i, questId })(token)
          : updateQuestStep({
              id: step.id,
              value: { description: d, number: i, questId },
            })(token);
      })
    );
  });

export type CreateQuestWithStepsArg = {
  quest: CreateQuestArg;
  stepDescriptions: string[];
};
export const createQuestWithSteps: (
  arg: CreateQuestWithStepsArg
) => (token?: CancelToken) => Promise<Quest> = (value) => (token) =>
  createQuest(value.quest)(token).then((quest) =>
    quest.questType.withSteps
      ? updateQuestSteps(
          quest.id,
          value.stepDescriptions
        )(token).then(() => quest)
      : quest
  );

export type UpdateQuestWithStepsArg = {
  quest: UpdateQuestArg;
  stepDescriptions: string[];
};
export const updateQuestWithSteps: (arg: {
  id: Id;
  value: UpdateQuestWithStepsArg;
}) => (token?: CancelToken) => Promise<Quest> = ({ id, value }) => (token) =>
  updateQuest({ id, value: value.quest })(token).then((quest) =>
    quest.questType.withSteps
      ? updateQuestSteps(
          quest.id,
          value.stepDescriptions
        )(token).then(() => quest)
      : quest
  );

/** Just for debug */
// export const getQuests: (
//   token?: CancelToken | undefined
// ) => Promise<Quest[]> = (token) => {
//   console.log('getQuests mock');
//   return Promise.reject(new Error('getQuests test error'));
// };

/** Just for debug */
// export const deleteQuest: (
//   id: number
// ) => (token?: CancelToken | undefined) => Promise<void> = (id) => (token) => {
//   console.log('deleteQuest mock');
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       // reject(new Error('deleteQuest test error'));
//       resolve();
//     }, 1500);
//   });
// };
