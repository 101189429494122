import React, { FC } from 'react';

const PointsIcon: FC<{ color?: string }> = ({ color = 'fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          fill={color}
          d="M39.152 15.943c.192.35.638.483.998.296.359-.186.495-.621.303-.972-1.734-3.173-4.63-5.058-6.761-5.765-.386-.127-.804.074-.935.45-.04.112-.049.227-.032.338.04.257.222.484.492.574 1.78.589 4.401 2.273 5.935 5.08zM40.342 17.782l-.007.013c-.064.133-.082.276-.062.411.036.23.184.439.416.545.369.168.808.014.98-.346l.007-.013c.173-.36.014-.787-.354-.956-.369-.169-.807-.014-.98.346z"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          d="M9.681 35.25c3.667 4.991 9.707 8.25 16.537 8.25 5.1 0 9.76-1.817 13.32-4.816M9.681 12.75c3.667-4.992 9.707-8.25 16.537-8.25 11.18 0 20.243 8.73 20.243 19.5 0 4.16-1.352 8.016-3.657 11.182"
        />
        <path
          fill={color}
          d="M5.336 29.803c-.804-2.552-2.154-4.058-4.13-4.604-.5-.138-.862-.614-.896-1.18-.034-.563.268-1.088.747-1.297 2.356-1.03 3.748-2.527 4.255-4.577.14-.566.608-.96 1.143-.963h.007c.532 0 1 .388 1.145.95.447 1.732 1.873 3.25 4.359 4.64.42.235.674.72.647 1.236-.027.517-.33.967-.772 1.148-1.976.809-3.377 2.354-4.281 4.725-.186.486-.62.8-1.099.8h-.038c-.494-.017-.926-.367-1.087-.878zM.785 17.595l.018.012c.182.118.388.175.593.175.346 0 .685-.163.892-.465.328-.48.195-1.128-.297-1.448l-.019-.012c-.491-.32-1.156-.19-1.484.29-.329.48-.195 1.128.297 1.448zM11.554 31.095l.018.012c.183.118.389.175.593.175.346 0 .686-.163.892-.465.328-.48.195-1.128-.297-1.448l-.018-.012c-.492-.32-1.157-.19-1.485.29-.328.48-.195 1.128.297 1.448z"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h48v48H0V0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PointsIcon;
