import React from 'react';
import styled from 'styled-components/macro';
import MainBackground from '../../_sass/img/bg_default@3x.png';
import { StyledFC } from '../_shared/types';

const Bg = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  background-image: url(${MainBackground});
`;

const Screen = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  width: calc(100% - 16px);
  max-height: 737px;
  height: 90%;
  margin: 18px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 32px;
  background: none;
  box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.5);

  font-size: 12px;
`;

const Content = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 80px 40px 40px auto;
  grid-row-gap: 0;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
`;

const Page_: StyledFC = ({ className, children }) => (
  <Bg>
    <Screen>
      <Wrapper>
        <Content className={className}>{children}</Content>
      </Wrapper>
    </Screen>
  </Bg>
);

const Page = styled(Page_)``;

export default Page;
