import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../types';

const ButtonComponent = styled.div`
  & div svg {
    path {
      stroke: #333;
    }

    position: absolute;
    left: 18px;
    width: 28.8px;
    height: 28.8px;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 6%;
  width: calc(100% - 7% * 2);
  cursor: pointer;
  background-color: #fff;
  margin: 0 !important;
  padding: 32px 7% 0;
`;

const BackButton: FC<{
  children: React.ReactNode;
}> = ({ children }) => <ButtonComponent>{children}</ButtonComponent>;

const ScreenWoBg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 360px;
  width: calc(100% - 16px);
  max-height: 737px;
  height: 90%;

  & > .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    z-index: 5;
    height: 100%;
    width: 100%;
    border-radius: 32px;
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.5);
  }
`;

const ScreenWithBg = styled(ScreenWoBg)<{ bgColor: string }>`
  & > .wrapper {
    & > div {
      background-color: ${({ bgColor }) => bgColor};
    }
  }
`;

const Screen: StyledFC<{
  background?: string;
  backButton?: React.ReactNode;
  children: React.ReactNode;
}> = ({ className, background, children, backButton = false }) => {
  console.log({ background });
  const Component = background ? ScreenWithBg : ScreenWoBg;

  return (
    <Component className={className}>
      <div className="wrapper">
        {backButton ? <BackButton>{backButton}</BackButton> : <></>}
        {children}
      </div>
    </Component>
  );
};

export default Screen;
