import * as api from '../internal';
import { Int, QuestType } from '../../_types';

const path = 'quest-types';

export type UpdateQuestTypeArg = {
  color?: string;
  image?: string;
  name?: string;
  points?: Int;
  pointsApproved?: Int;
};

export const getQuestTypes = api.getList<QuestType>(path);
export const getQuestType = api.getEntity<QuestType>(path);
export const updateQuestType = api.updateEntity<UpdateQuestTypeArg, QuestType>(
  path
);
export const deleteQuestType = api.deleteEntity(path);
