import * as api from '../internal';
import { Id, Int, QuestStep } from '../../_types';
import { CancelToken } from 'axios';
import API from '../axios';

const path = 'quest-steps';

export type CreateQuestStepArg = {
  questId: Id;
  description: string;
  number: Int;
};
export type UpdateQuestStepArg = {
  questId: Id;
  description: string;
  number: Int;
};

export const getQuestSteps = api.getList<QuestStep>(path);
export const getQuestStep = api.getEntity<QuestStep>(path);
export const createQuestStep = api.createEntity<CreateQuestStepArg, QuestStep>(
  path
);
export const updateQuestStep = api.updateEntity<UpdateQuestStepArg, QuestStep>(
  path
);
export const deleteQuestStep = api.deleteEntity(path);

export const getStepsOfQuest = (id: Id) => (token?: CancelToken) =>
  API.get<QuestStep[]>(`${path}/quest/${id}`, {
    cancelToken: token,
  })
    .then((res) => res?.data)
    .then((steps) => steps.sort((a, b) => a.number - b.number));
