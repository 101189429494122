import { StyledFC } from '../_shared/types';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Int } from '../../_types';

import Hand from './img/hand.png';
import HandBg from './img/hand_bg.png';
import Dust from './img/dust.png';
import DustBg from './img/dust_bg.png';
import Hat from './img/hat.png';
import HatBg from './img/hat_bg.png';
import Image from '../_shared/Image';

const ScreenContent_: StyledFC<{
  screenId: Int;
}> = ({ className, screenId }) => {
  const data: {
    image: string;
    bg: string;
    header: ReactNode;
    description: ReactNode;
  }[] = [
    {
      image: Hand,
      bg: HandBg,
      header: 'Тестируй приложения',
      description: (
        <>
          Добро пожаловать в проект Сила Сбера!
          <br />
          Почувствуй себя ревизором нашего времени - тестируй приложения,
          разоблачая баги, и проходи по сценарию в поисках награды!
        </>
      ),
    },
    {
      image: Dust,
      bg: DustBg,
      header: 'Наполняй «мешочек доброты»',
      description:
        'Складывай «пыльцу доброты» в свой волшебный мешочек. Эту валюту ты сможешь направить на реальную помощь нуждающимся! ',
    },
    {
      image: Hat,
      bg: HatBg,
      header: 'Шляпа определит твою команду',
      description:
        'И помни, твои соперники не дремлют! У нас здесь настоящее командное состязание, уже готов ворваться?',
    },
  ];
  const { image, bg, header, description } = data[screenId];
  return (
    <div className={className}>
      <Image bg={bg} src={image} />
      <div className="data-wrap">
        <div className="header">{header}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

const ScreenContent = styled(ScreenContent_)`
  display: grid;
  grid-template-rows: auto min-content;
  align-items: stretch;
  margin: 0;
  height: 100%;

  .data-wrap {
    background-color: #fff;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 1fr;
    margin: 0;
    width: 100%;

    overflow: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }

    & > * {
      display: initial;
      text-align: center;
      padding: 0 0.5em;
    }

    & > :first-child {
      padding-top: 0.5em;
    }
    & > :last-child {
      padding-bottom: 0.5em;
    }

    & > .header {
      font-family: Geometria-ExtraBold, sans-serif;
      font-size: 14px;
      line-height: 30px;
      //font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
    }

    & > .description {
      position: relative;
      font-size: 12px;
      line-height: 22.4px;
    }
  }
`;

export default ScreenContent;
