import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { StyledFC } from '../../../_shared/types';
import Api, { ReFetch, useApi } from '../../../_shared/Api';
import { GetEntity } from '../../../../_api';

/** Component factory.
 * Disables react-hooks/rules-of-hooks
 * */
const createEntityPage: <Entity, Children extends {} = {}>(
  getEntity: GetEntity<Entity>,
  entityTypeName: string,
  EntityComponent: StyledFC<{
    entityTypeName: string;
    reFetchList: ReFetch;
    entity: Entity;
    children: Children;
  }>
) => StyledFC<{ reFetchList: ReFetch; children: Children }> = (
  getEntity,
  entityTypeName,
  EntityComponent
) => ({ className, reFetchList, children }) => {
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const match = useRouteMatch<{ id: string }>();
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const entityId = parseInt(match.params.id);
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const entityState = useApi(getEntity, entityId);

  return (
    <Api
      result={entityState}
      renderData={(entity) => (
        <EntityComponent
          className={className}
          entityTypeName={entityTypeName}
          entity={entity}
          reFetchList={reFetchList}
        >
          {children}
        </EntityComponent>
      )}
    />
  );
};
export default createEntityPage;
