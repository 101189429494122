import { CancelToken, AxiosResponse } from 'axios';
import API from './axios';
import mapValues from 'lodash/mapValues';
import detect from 'browser-detect';

const parseDate = (s: string): Date => {
  /** Fix for Safari support. Also works with Chrome. */
  const fixed: string = `${s.slice(0, -2)}:${s.slice(-2)}`;
  return new Date(fixed);
};

export const mapDates = <T>(value: T): T =>
  Array.isArray(value)
    ? value.map((v) => mapDates(v))
    : typeof value === 'object' && value !== null
    ? mapValues(value as any, (v, k) => {
        return (k === 'date' || k.endsWith('Date')) && typeof v === 'string'
          ? parseDate(v)
          : mapDates(v);
      })
    : value;

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
const throwRestError = (method: HttpMethod, path: string) => (e: Error) => {
  throw new Error(`${method} ${path} : ${e.message}`);
};

const trace = <Res>(method: HttpMethod, path: string, result: Res): Res => {
  console.log(method, path, result);
  return result;
};

const preprocess = <Res>(
  method: HttpMethod,
  path: string,
  map: ((res: Res) => Res) | undefined
) => (res: AxiosResponse<Res>): Res =>
  trace(method, path, map ? map(res.data) : mapDates(res.data));

const wrap = <Res>(
  method: HttpMethod,
  path: string,
  map: ((res: Res) => Res) | undefined,
  promise: Promise<AxiosResponse<Res>>
) =>
  promise
    .then(preprocess<Res>(method, path, map))
    .catch(throwRestError(method, path));

export const get = <Arg, Res>(path: string, map?: (res: Res) => Res) => (
  arg?: Arg
) => (token?: CancelToken) =>
  wrap<Res>(
    'GET',
    path,
    map,
    API.get<Res>(path, { params: arg, cancelToken: token })
  );

export const post = <Arg, Res = void>(
  path: string,
  map?: (res: Res) => Res
) => (arg: Arg) => (token?: CancelToken) =>
  wrap<Res>(
    'POST',
    path,
    map,
    API.post<Res>(path, arg, { cancelToken: token })
  );

export const put = <Arg, Res = void>(path: string, map?: (res: Res) => Res) => (
  arg: Arg
) => (token?: CancelToken) =>
  wrap<Res>(
    'PUT',
    path,
    map,
    API.put<Res>(path, arg, {
      cancelToken: token,
    })
  );

export const delete_ = <Res = void>(path: string, map?: (res: Res) => Res) => (
  token?: CancelToken
) =>
  wrap<Res>(
    'DELETE',
    path,
    map,
    API.delete<Res>(path, { cancelToken: token })
  );

const SEC = 1000;
const MIN = 60 * SEC;
export const download = (path: string, fileName: string) => (
  token?: CancelToken
): Promise<'done'> =>
  API.get(path, {
    cancelToken: token,
    responseType: 'blob',
    timeout: MIN,
  }).then((response) => {
    const { os, mobile, name } = detect();
    if (os === 'OS X' && mobile === true && name === 'crios') {
      /** iOS Chrome */
      const reader = new FileReader();
      const out = new Blob([response.data], {
        type: 'application/vnd.ms-excel',
      });
      reader.onload = function (e) {
        if (e.target?.result != null)
          window.location.href = String(e.target?.result);
      };
      reader.readAsDataURL(out);
      return 'done';
    } else {
      /** Other */
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      return 'done';
    }
  });
