import React, { FC, ReactNode } from 'react';
import TabsHeader from './TabsHeader';
import map from 'lodash/map';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

export type TabsProps = {
  default: string;
  children: {
    [key: string]: { title: string; render: (show: boolean) => ReactNode };
  };
};

const Tabs: FC<TabsProps> = ({ default: defaultTab, children }) => {
  const match = useRouteMatch();
  const keys = Object.keys(children);
  if (!keys.includes(defaultTab))
    throw new Error(`Invalid default tab: '${defaultTab}'`);
  return (
    <>
      <TabsHeader
        className="tabs"
        items={map(children, ({ title }, key) => ({ key, title }))}
      />
      {map(children, ({ render }, key) => (
        <TabRoute key={key} path={`${match.path}/${key}`}>
          {render}
        </TabRoute>
      ))}
      <Route
        exact
        path={match.path}
        render={() => <Redirect to={`${match.path}/${defaultTab}`} />}
      />
      <Route
        path={`${match.path}/:tab`}
        render={({ match: defaultMatch }) => {
          const tab = defaultMatch.params.tab as string;
          if (tab && !keys.includes(tab))
            return <Redirect to={`${match.path}/${defaultTab}`} />;
          else return null;
        }}
      />
    </>
  );
};

export default Tabs;

const TabRoute: FC<{
  children: (show: boolean) => React.ReactNode;
  path: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}> = ({ path, children, exact, strict, sensitive }) => (
  <Route path={path} exact={exact} strict={strict} sensitive={sensitive}>
    {({ match }) => children(match !== null)}
  </Route>
);
