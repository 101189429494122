import React, { FC, useContext } from 'react';
import { Id, Int } from '../../../../_types';
import OverlayButton from './OverlayButton';
import { ThemeContext } from 'styled-components/macro';
import { useHistory, useRouteMatch } from 'react-router-dom';

const DeleteButton: FC<{
  entityId: Id;
  size: Int;
  before?: () => void;
  after?: () => void;
}> = ({ entityId, size, before }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const theme = useContext(ThemeContext);
  return (
    <>
      <OverlayButton
        size={size}
        icon="trash"
        onClick={() => {
          before?.();
          history.push(`${match.path}/${entityId}/delete`);
        }}
        bgColor={theme.colors.red}
        color="white"
      />
    </>
  );
};

export default DeleteButton;
